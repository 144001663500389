angular.module('services.girofondo').factory('GirofondoUnicoService', 
['$resource', '$filter', 'AppConfs',
		function ($resource, $filter, AppConfs) {

			var requestMilliseconds = new Date().getTime();
			var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
			var nomeServizio = '/rpolGirofondo';

			return $resource(
				AppConfs.general.restServices.baseContext + nomeServizio + '/girofondo/:action',
				{}, {
				verifica: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "verifica"
					}/*
					,
					interceptor: {
						response: function (response) {
							var result = response.resource;
							result.$status = response.status;
							return result;
						}
					}*/
				},
				esegui: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "esegui"
					}/*
					,
					interceptor: {
						response: function (response) {
							var result = response.resource;
							result.$status = response.status;
							return result;
						}
					}*/
				},
				esito: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "esito"
					}/*
					,
					interceptor: {
						response: function (response) {
							var result = response.resource;
							result.$status = response.status;
							return result;
						}
					}*/
				},
				annulla: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "annulla"
					}/*
					,
					interceptor: {
						response: function (response) {
							var result = response.resource;
							result.$status = response.status;
							return result;
						}
					}*/
				}

			});
		}]);
