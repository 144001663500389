/** 
 * ngRepeatOnFinishRender directive
 * 
 * Direttiva nata per eseguire codice jQuery dell'UIKIT al rendering 
 * dell'ultimo template generato da direttiva ngRepeat.
 * Al rendering dell'ultimo template viene lanciato un'evento
 * con nome pari a quello inserito in input all'attributo che 
 * definisce la direttiva (ng-repeat-on-finish-render).
 * Usare nomi di eventi univoci nell'applicazione. 
 *
**/

angular.module('directives.ngRepeatOnFinishRender').directive('ngRepeatOnFinishRender', ['$timeout',function ($timeout) {
return {
    restrict: 'A',
    link: function (scope, element, attr) {
        if (scope.$last === true) {
            $timeout(function () {
                scope.$emit(attr.ngRepeatOnFinishRender);
            },0);
        }
    }
}
}]);