angular.module('directives.maskRenderedValue')
    .directive('maskRenderedValue', ['$log',function ($log) {
        return {
            require: 'ngModel',
            link: function(scope, elm, attrs, ctrl) {
                
        
                function format(value){

                    var returnValue;

                    if(value){
                        if(value.mode == 'unmask'){
                            
                            $log.log('maskRenderedValue input : ', value);
                            $log.log('maskRenderedValue unmasked : ', value.numero);
                           
                            returnValue = 
                                    value.numero.substring(0, value.startIndex).replace(/./g ,'*') + 
                                    value.numero.substring(value.startIndex, value.endIndex) + 
                                    value.numero.substring(value.endIndex, value.length).replace(/./g ,'*');

                            $log.log('maskRenderedValue masked : ', returnValue);

                        }else{



                        }
                    }
                    return returnValue;
                }

                ctrl.$formatters.push(format);
                
                ctrl.$render = function() {
                    elm.html(ctrl.$viewValue);
                };
            }
        };
}]);