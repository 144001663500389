angular.module('filters.appAsteriskAccountNumber').filter('asteriskAccountNumber', function () {
  return function (input) {
	if(input !== undefined){
	  var primeCifreAsteriscate = input.substring(input.length-8, input.length-4).replace(/[0-9]/g, '*') ;
	  var ultimeQuattroCifre = input.substring(input.length-4, input.length) ;
	  return (primeCifreAsteriscate+ultimeQuattroCifre);
	 }else
		return "";
	 
  };
});

						 
								  
					
					 
							  
				  
					   
										 
 

												   
				
					   
 

													
				 
						  
 

											  
							   
							
					   
				 
					   
					 
					   
 

												 
			  
 

																  
			   
 

																		 
											 
			 
			   
					
					
							  
							   
							
						   
					   
				 
			  
 

																				
													
				
				   
				 
				
					 
														  
					 
					   
				   
			   
							 
								 
								  
									 
								
			   
												  
 

																										 
																										   
																			 
							   
 

																						   
															   
					  
					
				   
					 
 

																								 
																	 
							  
 

																							  
																  
				
 

																								   
																	   
				   
 

																								
																	
							  
					 
 

																								  
																	  
				   
						 
					  
					  
 

																									
																		
							   
 

																									   
																		   
					 
 

																										
																			
						 
						
					
				   
 

																										
																			
					 
 

																					
														
				   
 

																										
																			
							  
 

															 
								 
					
				   
							  
						
				 
						   
 

																	 
				   
 

																				  
					  
 

																				  
					   
 

													 
													  
				   
 

															   
								   
									 
								 
						  
 

																	 
																		  
										
											  
					   
 

																		  
											  
																													  
 

																	 
										 
																										
 

																			
												 

																			   
												   
				  
					 
																														   
				  
 

																					  
														  
						
						 
 

																					  
																						
														 
															
			   
 

																					 
														 
					
 

																							   
																   
						 
 

																											  
																				  
					
					 
 

																									   
																		   
				   
 

																					   
														   
				   
						 
					
 

										 
					   
						 
 

					 
				  
						   
 

						
					   
					  
						  
							 
 

							
					
				  
					   
						   
 

																							 
						 
					
 

																   
						
				 
						   
 

																				  
							   
							
						   
					   
				 
									 
							 
						  
					 
			 
 

																						 
											
														  
													   
												  
				
				 
					 
					   
			   
			   
							 
																  
				
		
				  
 

																					 
			   
					   
			   
 

																						   
				   
							  
 

																					   
					  
				   
 

															  
						
 

															 
						 
 

																
					
							  
				   
					  
					  
 

																	  
							  
				   
						
					  
 

													
						 
 

														 
														  
								  
 

														   
					   
 

															  
						
								  
	  
								 
										   
																								  
	  
				   
				
 

																			   
					   
				
			 
 

											
										   
					
 

									   
									  
					   
				 
 

										   
										  
				
 

													  
													 
				 
 

													
												   
						   
				  
					   
 

													  
													 
				   
					  
						
					
					 
						
						   
										 
									  
 

															
														   
				   
						  
 

																   
																  
				   
 

																	 
																	
				   
								 
 


  
												 
												 
											   
												
					   
			  
								  
								 
								
				 
				   
				 
				
				 
 

												 
												
				
																							
 

												 
												
				 
																							 
 
  

															
										 
								 
						  
					   
 

															   
																										 
 

															  
																												 
 

																  
																													 
 

															   
																												   
 

																  
																															  
 

																   
																																		 
 

																			 
																	 
																														  
 

																			
																	
																												 
 

																				
																		
																													 
 

																			 
																	 
																												   
 

																				
																		
																															  
 

																				 
																		 
																																		 
 