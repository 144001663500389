(function() {
    'use strict';

    angular
        .module('services.sessionIdp')
        .factory('SessionIdpService', ['$resource', '$filter', 'AppConfs', SessionIdpService]);

    function SessionIdpService($resource, $filter, AppConfs) {

        var requestMilliseconds = new Date().getTime();
        var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');

        var nomeServizio = '/rpolSessionIdpService';

        
            return  $resource( AppConfs.general.restServices.baseContext + nomeServizio + '/:path/:action', {}, {
                        createAuthRequest: {
                            method: 'POST',
                            headers: {
                                requestID: 'RPOL.WEB.' + requestMilliseconds,
                                requestTimestamp: dt
                            },
                            params: {
                                path: "autenticazioneRpolSca",
                                action: "createAuthRequest"
                            }
                        },
                        createTokenRequest: {
                            method: 'POST',
                            headers: {
                                requestID: 'RPOL.WEB.' + requestMilliseconds,
                                requestTimestamp: dt
                            },
                            params: {
                                path: "autenticazioneRpolSca",
                                action: "createTokenRequestIdp"
                            }
                        },
                        retrieveInformationIdp: {
                            method: 'POST',
                            headers: {
                                requestID: 'RPOL.WEB.' + requestMilliseconds,
                                requestTimestamp: dt
                            },
                            params: {
                                path: "autenticazioneRpolSca",
                                action: "retrieveInformationIdp"
                            }
                        }
                    });
            
        
    }
})();