angular.module('filters.modalitaEmissione').filter('modalitaEmissione', function () {
	return function (items) {
		var filtered = [];
		//here you will have your desired input
	

		angular.forEach(items, function (item) {
			//solo importo massimo valorizzato
			if (item.codiceModalitaEmissione=== '1' || item.codiceModalitaEmissione=== '2' || item.codiceModalitaEmissione=== '4') {
				filtered.push(item);
			}
		});
		return filtered;
	};
});


























































































































































































































































































































































































































































































































































