angular.module('services.posizioneBuoni').factory('PosizioneBuoniService',
	['$resource', '$filter', 'AppConfs',
		function ($resource, $filter, AppConfs) {

			var requestMilliseconds = new Date().getTime();
			var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
			var nomeServizio = '/rpolPosizioneBuoni';

			return $resource(
				AppConfs.general.restServices.baseContext + nomeServizio + '/posizioneBuoni/:action',
				{}, {
					listaBuoni: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							action: "listaBuoni"
						}
					},
					richiediRimborso: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							action: "richiediRimborso"
						}
					},
                    patrimonio: {
                        method: 'GET',
                        headers: {
                            //Authorization: BPOLService.authorizationHeaderVal,
                            //Username: BPOLService.usernameHeaderVal,
                            //Canale: BPOLService.canaleHeaderVal,
                            //requestID : 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
                            requestID: 'RPOL.WEB.' + requestMilliseconds,
                            requestTimestamp: dt
                        },
                        params: {
                            action: "patrimonio"
                        }
                        /*,
                        interceptor: {
                            response: function (response) {
                                var result = response.resource;
                                result.$status = response.status;
                                return result;
                            }
                        }*/
                    },
                    estrazione : {
                    	method: 'GET',
                        headers: {
                            requestID: 'RPOL.WEB.' + requestMilliseconds,
                            requestTimestamp: dt
                        },
                        params: {
                            action: "estrazioni"
                        }
                    },
                    vincita : {
                    	method: 'GET',
                        headers: {
                            requestID: 'RPOL.WEB.' + requestMilliseconds,
                            requestTimestamp: dt
                        },
                        params: {
                            action: "vincita"
                        }
                    },
					messaggiBuono: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							action: "messaggiBuono"
						}
					}
				});
		}]);
