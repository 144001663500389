angular.module('filters.valuta').filter('valutaIntera', function () {
	return function (importo) {

		if (importo) {
			var impStr = importo.toString();
			if (impStr.length > 2) {
				var parteIntera = impStr.substring(0, (impStr.length - 2));

				//return parseInt(parteIntera).toLocaleString();
				return parseInt(parteIntera).toLocaleString("it-IT");
			}
			else
				return 0;
		}
		else {
			return 0;
		}
	};
})

	.filter('valutaDecimale', function () {
		return function (importo) {

			if (importo) {

				var impStr = importo.toString();
				if (impStr.length > 1) {
					var parteDecimale = impStr.substring((impStr.length - 2), impStr.length);
					return parteDecimale;
				}
				else if (impStr.length === 1) {
					return '0' + impStr;
				}
				else {
					return 0;
				}
			}
			else
				return '00';
		}
	}).filter('valutaConPuntiMigliaia', function () {
		return function (importo) {

			if (importo) {
				var impStr = importo.toString();
				var parteDecimale = '';

				if(impStr.indexOf(',') != -1) {
					parteDecimale = impStr.substring(impStr.length - 3, impStr.length);
				}
				return parseInt(importo).toLocaleString("it-IT") + parteDecimale;
				
			} else {
				return '0';
			}
		};
	});

