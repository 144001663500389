angular.module('services.profilePhoto').factory('ProfilePhotoService',
    ['$resource', '$filter', 'AppConfs',
        function ($resource, $filter, AppConfs) {

            var requestMilliseconds = new Date().getTime();
            var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
            var nomeServizio = '/rpolProfilePhotoService';

            return $resource(
                //AppConfs.general.restServices.baseContextProfilePhoto + nomeServizio + '/:action', 
                AppConfs.general.restServices.baseContext + nomeServizio + '/:action', 
                {}, {
                getprofileimage: {
                    method: 'GET',
                    headers: {
                        //Authorization: BPOLService.authorizationHeaderVal,
                        //Username: BPOLService.usernameHeaderVal,
                        //Canale: BPOLService.canaleHeaderVal,
                        //requestID : 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
                        requestID: 'RPOL.WEB.' + requestMilliseconds,
                        requestTimestamp: dt
                    },
                    params: {
                        action: "getprofileimage"
                    }
                    /*,
                    interceptor: {
                        response: function (response) {
                            var result = response.resource;
                            result.$status = response.status;
                            return result;
                        }
                    }*/
                }
            });
        }]);
