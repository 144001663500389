angular.module('services.gestioneIban').factory('GestioneIbanService',
	['$resource', '$filter', 'AppConfs',
		function ($resource, $filter, AppConfs) {

			var requestMilliseconds = new Date().getTime();
			var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
			var nomeServizio = '/rpolGestioneIban';

			return $resource(
				AppConfs.general.restServices.baseContext + nomeServizio + '/:path/:action',
				{}, {
					ibanDaRevocare: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							path: "iban",
							action: "ibanDaRevocare"
						}
					},
					verifica: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							path: "iban",
							action: "verifica"
						}

					},
					associa: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							path: "iban",
							action: "associa"
						}
					},
					revoca: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							path: "iban",
							action: "revoca"
						}
					},
					richiediOtp: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							path: "iban",
							action: "richiediOtp"
						}
					},
					annulla: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							path: "iban",
							action: "annulla"
						}
					},
					associa: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							path: "iban",
							action: "associa"
						}

					}
				});
		}]);