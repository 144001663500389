angular.module('services.cartaceoNonAnagrafato').factory('CartaceoNonAnagrafatoService',
	['$resource', '$filter', 'AppConfs',
		function ($resource, $filter, AppConfs) {

			var requestMilliseconds = new Date().getTime();
			var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
			var nomeServizio = '/rpolCartaceiNonAnagrafati';

			return $resource(
				AppConfs.general.restServices.baseContext + nomeServizio + '/:path/:action',
				{}, {
					listaBuoni: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							path: "inserimento",
							action: "listaTipoBuoni"
						}

					},
					inserimentoVerifica: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							path: "inserimento",
							action: "verifica"
						}

					},
					inserimentoEsegui: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							path: "inserimento",
							action: "esegui"
						}
					},
					inserimentoAnnulla: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							path: "inserimento",
							action: "annulla"
						}
					},
					modificaEsegui: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							path: "modifica",
							action: "esegui"
						}
					},
					cancellaEsegui: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							path: "cancella",
							action: "esegui"
						}
					}
				});
		}]);