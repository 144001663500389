var common_path = '/rpol/public/views/common-templates';
var template = common_path + '/box-upload-dragndrop-new.template.html';
angular.module('boxUploadDragndropNew').component(
		'boxUploadDragndropNew',
		{
			bindings : {
				config : '=',
				errorsFn : '&',
				serviceConfs : '<',
				maxSizeUpload : '@',
				maxPagePdf : '@',
				fileSizeType : '@',
				supportedMimeType : '@',
				uploadDone : '&',
				onRemoveFile : '=',
				docType: '<'
			},
			require : {
				parentCtrl : '^^manager'
			},
			templateUrl : template,
			controller : [ '$timeout', 'BoxUploadDragndropNewService',
					'$document', boxUploadDragndropNewController ]
		});

/**
 * box upload drag 'n' drop controller
 */
function boxUploadDragndropNewController($timeout, service, $document) {

	var _this = this;

	// uploaded files
	_this.filesUploaded = [];
	_this.filesUploaded[0] = [];
	_this.filesUploaded[1] = [];

	var boxUploadDragndrop = angular.element('#boxUploadDragndropNew');
	var countPage = 0;
	var fileUp = [];
	fileUp[0] = angular.element('#fileUp0');
	fileUp[0].change(function(e) {
		if (null != e && null != e.target) {
			fileUpChange(e.target.files, 0);
		}
	});
	fileUp[1] = angular.element('#fileUp1');
	fileUp[1].change(function(e) {
		if (null != e && null != e.target) {
			fileUpChange(e.target.files, 1);
		}
	});
	var fileUpArea0 = angular.element('#fileUpArea0');
	fileUpArea0.on('dragover', function(e) {
		cssSwtichDrop(e, this, true);
	});
	fileUpArea0.on('dragleave', function(e) {
		cssSwtichDrop(e, this);
	});
	fileUpArea0.on('drop', function(e) {
		if (null != e && null != e.originalEvent
				&& null != e.originalEvent.dataTransfer) {
			cssSwtichDrop(e, this);
			fileUpChange(e.originalEvent.dataTransfer.files, 0);
		}
	});

	/**
	 * add or remove CSS class on dragging area
	 */
	function cssSwtichDrop(e, elm, on) {
		e.preventDefault();
		e.stopPropagation();
		if (on) {
			$(elm).addClass('dragging');
		} else {
			$(elm).removeClass('dragging');
		}
	}

	/**
	 * change event after user drop or click action
	 */
	function fileUpChange(files, f) {
		if (files && files.length > 0) {
			fileUpLoad(files, f);
		} else {
			fileUpError(f);
		}
	}

	/**
	 * FILE UPLOAD
	 */
	function fileUpLoad(files, f) {
		var fr = new FileReader();
		var file = files[0];
		fileUp[f].prop("value", "");
		fr.onloadend = function() {
			var item = {
				fileName : file.name,
				result : fr.result,
				size : fileUpSize(file.size, _this.fileSizeType)
			};

			if (_this.errorsFn) {
				_this.errorsFn({
					error : false
				});
			}

			if (typeof _this.uploadDone == 'function') {
				_this.uploadDone({
					success : item
				});
			}

			fileUpLoadDone(item, f);
		}
		fr.readAsDataURL(file);
	}

	/**
	 * file upload error
	 */
	function fileUpError(f) {
		fileUpLoadDone(false, f);

		if (typeof _this.uploadDone == 'function') {
			_this.uploadDone(null, {
				error : true
			});
		}
	}

	/**
	 * return file size in B, KB or MB
	 */
	function fileUpSize(number, type) {
		if (type.toUpperCase() == "B") {
			return number;
		} else if (type.toUpperCase() == "KB") {
			return number / Math.pow(1024, 1);
		} else if (type.toUpperCase() == "MB") {
			return number / Math.pow(1024, 2)
		}
	}

	/**
	 * file too large
	 */
	function fileUpSizeIsOverLimit(files) {
		var countTotSize = 0;
		files.forEach(function(fileUp) {
			if (fileUp.size) {
				countTotSize += fileUp.size;
			}
		});
		return _this.maxSizeUpload ? countTotSize > _this.maxSizeUpload : false;
	}

	/**
	 * file upload done check
	 */
	function fileUpLoadDoneCheck(canAdd, file, pageNumbers, f) {
		$timeout(function() {
			var gen_error_msg = 'Il documento non pu&ograve; avere pi&ugrave; '
					+ 'di 4 pagine al suo interno e la dimensione '
					+ 'non pu&ograve; superare ' + _this.maxSizeUploadToString
					+ ' MB.';
			var maxsize_error_msg = 'Hai superato il limite massimo di pagine.';
			if (canAdd) {
				countPage = countPage + pageNumbers;
				if (countPage <= _this.maxPagePdf) {
					_this.filesUploaded[f].push(file);
					var files = _this.filesUploaded[0]
							.concat(_this.filesUploaded[1]);
					if (fileUpSizeIsOverLimit(files)) {
						_this.filesUploaded[f].pop();
						countPage = countPage - pageNumbers;
						_this.parentCtrl.webtrendsLogError(null, gen_error_msg);
						if (_this.errorsFn) {
							_this.errorsFn({
								error : 'errorMaxSizeLimit'
							});
						}
					}
				} else {
					countPage = countPage - pageNumbers;
					_this.parentCtrl.webtrendsLogError(null, maxsize_error_msg);
					$document.scrollToElement(boxUploadDragndrop, 50, 700);
					if (_this.errorsFn) {
						_this.errorsFn({
							error : 'errorMaxPage'
						});
					}
				}
			} else {
				_this.parentCtrl.webtrendsLogError(null, gen_error_msg);
				$document.scrollToElement(boxUploadDragndrop, 50, 700);
				if (_this.errorsFn) {
					_this.errorsFn({
						error : 'errorMaxLimit'
					});
				}
			}
			var files = _this.filesUploaded[0].concat(_this.filesUploaded[1]);
			_this.config = service.createArrayOfBlobFiles(files);
		});
	}

	/**
	 * upload done
	 */
	function fileUpLoadDone(file, f) {
		if (file) {
			service.checkPagePdfLimit(file, _this.maxPagePdf, f,
					fileUpLoadDoneCheck);
		}
	}

	/**
	 * remove file check
	 */
	function removeFileCheck(canAdd, file, pageNumbers, f, index) {
		$timeout(function() {
			countPage = countPage - pageNumbers;
			_this.filesUploaded[f].splice(index, 1);
			var files = _this.filesUploaded[0].concat(_this.filesUploaded[1]);
			if (files.length == 0) {
				_this.config = undefined;
			}
		});
	}

	/**
	 * remove an uploaded file
	 */
	_this.removeFile = function(fileToRemove, f) {

		if (typeof _this.onRemoveFile == 'function') {
			_this.onRemoveFile(fileToRemove)
		}

		var index = -1;
		_this.filesUploaded[f].forEach(function(file, i) {
			if (file.fileName.indexOf(fileToRemove) != -1) {
				index = i;
			}
		});
		if (index >= 0) {
			var file = _this.filesUploaded[f][index];
			service.checkPagePdfLimit(file, _this.maxPagePdf, f, function(
					canAdd, file, pageNumbers, f) {
				removeFileCheck(canAdd, file, pageNumbers, f, index);
			});
		}
	}

	/**
	 * add a new file
	 */
	_this.addFile = function(f) {
		fileUp[f].click();
	}

	/**
	 * show file preview
	 */
	_this.showPreview = function(f, i) {
		if (null != _this.filesUploaded[f] && null != _this.filesUploaded[f][i]) {
			var file = _this.filesUploaded[f][i];
			if (null != file.result) {
				var type = service.checkFileType(file.result);
				if (type == "pdf") {
					$('#img-preview').hide();
					$('#img-preview').removeAttr("src");
					$('#doc-preview').attr('src', file.result);
					$('#doc-preview').height("500");
					$('#doc-preview').show();
				} else {
					$('#doc-preview').hide();
					$('#doc-preview').removeAttr("src");
					$('#img-preview').attr('src', file.result);
					$('#img-preview').show();
				}
				$("#docPreviewModal").modal(true);
			}
		}
	}

}