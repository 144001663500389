/*
Questa direttiva consente l'inserimento di spazi.
Per evitare questo comportamento utilizzare la direttiva angular: 
        ng-trim="false"
sull'elemento in cui si utilizza only-digits
*/
angular.module('directives.digits').directive('onlyDigits', function () {

    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            
            function validOnlyDigit(text) {
                
                if (text) {
                    
                    /*if(text.length>=1 && text[0] == '0'){
                        ngModel.$setViewValue('0');
                        ngModel.$render();
                        return '0';
                    }else{*/

                        var transformedInput = text.replace(/[^0-9]/g, '');

                        if (transformedInput !== text) {
                            ngModel.$setViewValue(transformedInput);
                            ngModel.$render();
                        }
                        return transformedInput;

                    /*}*/
                }
                return '';
            }    
            
        
            ngModel.$parsers.push(validOnlyDigit);



        }
    };
});