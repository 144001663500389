/*
Massimiliano Franchi:
  Questa direttiva guida l'inserimento della data di scadenza delle carte nal formato mm/aa, fornendo lo slash dopo l'inserimento delle prime 2 cifre.
  Intercettando poi gli eventi "onpaste" e "onkeyup" scatena l'evento change che nel component (step0)
  viene intercettato e lancia a sua volta il checkForm() per la validazione della data secondo le specifiche.
*/

(function() {
  'use strict';

  angular
    .module('directives.dateValidatorInterval')
    .directive('dateValidatorInterval', ['$log', dateValidatorInterval]);

  function dateValidatorInterval($log) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, element, attrs, ctrl) {
      
        console.log(attrs.dateValidatorInterval);

        element.bind("keyup paste", function(event) {
          var v = this.value;
          if (event.which === 8) {
            event.preventDefault();
          } else {
            if (v.match(/^\/*$/) !== null) {
              this.value = '';
            }
            if (v.match(/^\d{2}$/) !== null) {
              this.value = v + '/';
            }
            if (v.match(/^\d{2}\d{2}$/) !== null) {
              this.value = v.substring(0, 2) + '/' + v.slice(-2);
            }
            if (v.match(/^\d{2}\d{2}\d{4}$/) !== null) {
              this.value = v.substring(0, 2) + '/' + v.substring(2, 4) + '/' + v.slice(-4);
            }
            if (v.match(/^\d{2}\/\d{2}$/) !== null) {
              this.value = v + '/';
            }
            if (v.match(/^\d{1}\/$/) !== null) {
              this.value = '0' + v.slice(0, 1) + '/';
            }
            if (v.match(/^\d{2}\/\d{1}\/$/) !== null) {
              this.value = v.substring(0, 3) + '0' + v.slice(-2);
            }
          }
        });

        function validOnlyDigit(text) {
          if (text) {
            var transformedInput = text.replace(/[^0-9\/]/g, '');
            if (transformedInput !== text) {
              ctrl.$setViewValue(transformedInput);
              ctrl.$render();
            }
            return transformedInput;
          }
          return '';
        }
        ctrl.$parsers.push(validOnlyDigit);
      }
    }
  }
})();
