angular.module('tabellaGenericaBodyPatrimonioBuoni').component('tabellaGenericaBodyPatrimonioBuoni', {
    bindings: {
		inputParams: '<',
		dynamicId: '<'
    },
    templateUrl: '/rpol/private/pages/common-templates/tabella-generica-body-patrimonio-buoni.template.html',
    controller: ['$log', 'UikitProxy', 'WebtrendsService', '$timeout','PosizioneBuoniService', 'UtenteService','$scope',
        function TabellaGenericaBodyPatrimonioBuoniController($log, UikitProxy, WebtrendsService, $timeout,PosizioneBuoniService, UtenteService, $scope) {

            var vm = this;
			
			//TabellaGenericaBodyPatrimonioBuoni vars
			vm.confs = {};
			vm.pagination = 10;
            
			//TabellaGenericaBodyPatrimonioBuoni funcs
			vm.changePagination = changePagination;
			vm.$doCheck = doCheck;
            vm.$postLink = postLink;
            vm.richiediMessaggi= richiediMessaggi;
            vm.rimborsoBuoni= rimborsoBuoni;

			
			
				function rimborsoBuoni(tipologiaRapporto, numeroRapporto, numerica) {
					var key = "";
					var path = '/rpol/private/pages/index.html';

					
					if (tipologiaRapporto === 'buonoLibretto') {
						key = "numeroRapporto_rimborsoBuoni";
						storeDatakey(key, tipologiaRapporto, numeroRapporto, numerica, path);
					}
					//recupero path di indirizzamento dalla tipologiaRapporto del conto
					if (tipologiaRapporto === 'buonoConto') {
						key = "numeroRapporto";
						UtenteService.tipologiaConto({
							data: {
								numeroRapporto: numeroRapporto
							}
						},
							function (response) {
								$log.log('ManagerController  UtenteService.tipologiaConto response: ', response);

								if (response.data) {
									path = response.data.link;
								}
							
								//$log.log('ManagerController  UtenteService.tipologiaConto path: ', path);
								//modifica per non portare lo sviluppo in collaudo
								/* 	path = 'https://bancopostaonline.poste.it/BPOL/bancoposta/Servizi/BuoniFruttiferi/BuoniFruttiferi.aspx'; */
								storeDatakey(key, tipologiaRapporto, numeroRapporto, numerica, path);
							},
							function (httpResponse) {
								$log.log('ManagerController UtenteService.tipologiaConto error-response: ', httpResponse);
								//modifica per non portare lo sviluppo in collaudo
								/* 	path = 'https://bancopostaonline.poste.it/BPOL/bancoposta/Servizi/BuoniFruttiferi/BuoniFruttiferi.aspx'; */
								
								//$log.log('ManagerController  UtenteService.tipologiaConto path: ', path);
								storeDatakey(key, tipologiaRapporto, numeroRapporto, numerica, path);
							});


					}

				}
				
				
				function storeDatakey(key, tipologiaRapporto, numeroRapporto, numerica, path) {
				
				if (tipologiaRapporto === 'buonoLibretto') {
					var request = JSON.parse('{ "data": { "mappaVariabiliTemp": { "' + key + '": "' + numeroRapporto + '", "numerica_rimborsoBuoni": "' + numerica + '"} } }');
				} else if (tipologiaRapporto === 'buonoConto') {
					var request = JSON.parse('{ "data": { "mappaVariabiliTemp": { "' + key + '": "' + numeroRapporto + '", "tipologia": "' + tipologiaRapporto + '"} } }');
				}
				$log.log('ManagerController json :', request);


				//Non vado in errore perch� la gestione dell'errore si fa sul recupera nelle landing
				UtenteService.inserisci(request,
					function (response) {
						$log.log('ManagerController  UtenteService.inserisci response: ', response);
						redirectPage(tipologiaRapporto, path);
					},
					function (httpResponse) {
						$log.log('ManagerController UtenteService.inserisci error-response: ', httpResponse);
						redirectPage(tipologiaRapporto, path);
					});
				}

			function redirectPage(tipologiaRapporto, path) {
				if (tipologiaRapporto === 'buonoLibretto') {
					/*cryptNumeroRapportoSlider(numeroRapporto, tipologiaRapporto);*/
					window.location = '/rpol/private/pages/rimborso-buoni.html';
					return;
				} else if (tipologiaRapporto === 'buonoConto') {
					/*	window.location = 'https://bancopostaonline.poste.it/BPOL/bancoposta/Servizi/BuoniFruttiferi/BuoniFruttiferi.aspx' ;*/
					window.location = path;
					return;
				}

			}
			
			
			function richiediMessaggi(input) {
				
				//Dettaglio Buoni
				PosizioneBuoniService.messaggiBuono({
					data: {
						tipoBuono: input.tipoBuono,
						dataEmissione: input.dataEmissione
						}
					},
					function (response) {
						//vm.messaggiBuono.dettaglioBuoni.numeroLibretto = response.data.numeroRapporto;
						input.messaggi = response.data.listaNoteCalcolatoreBfp;
						hide_CustomUIKit_PageLoader();//-2
						
					},
					function (httpResponse) {
						$log.log('ManagerController  PosizioneBuoniService.messaggiBuono error-response:', httpResponse); 
						var risposta = {};
						risposta.httpResponse = httpResponse;
						risposta.tipoErrore = 'exception0';
                        //var errorResponseMessage = vm.getErrorResponseMessage(httpResponse);

                        //webtrendsLogError(null, errorResponseMessage);
						$scope.$emit('validationErrorTable', risposta);
						//vm.showEsito = true;
						forceHide_CustomUIKit_PageLoader();
					}
				);

				return input;
			}
			
			
				function getErrorResponseMessage(errorResponse) {

                    //$log.log("ManagerController getErrorResponseMessage START");

                    var errorResponseMessage;

                    if (errorResponse.data && errorResponse.data.metadata) {

                        if (errorResponse.data.metadata.message)
                            errorResponseMessage = [errorResponse.data.metadata.message];
                        else if (errorResponse.data.metadata.errors)
                            errorResponseMessage = errorResponse.data.metadata.errors;

                    }

                    return errorResponseMessage;

                }
			
			
			function changePagination(input) {
				vm.pagination = input;
				//$log.log('TabellaGenericaBodyPatrimonioBuoniController changePagination vm.pagination:', vm.pagination);
				vm.paginaCorrente = 1;
				$timeout(function () {
					  $('#listaMovPrint').tableExport({type:'init',escape:'false',nomeFileOutput:'RPOL_PatrimonioBuoni'});
				}, 1000);
			}

			function doCheck() {
				//$log.log('TabellaGenericaBodyPatrimonioBuoniController vm.inputParams.confs:', vm.inputParams);
				//$log.log('TabellaGenericaBodyPatrimonioBuoniController vm.dynamicId:', vm.dynamicId.idTabella);
				
				UikitProxy.applyPopover();
				
				if (vm.dynamicId) {
					vm.dynamicID = vm.dynamicId.idTabella;
				}
				
				
				
				if (vm.inputParams && vm.inputParams.entrato == false) {
					//$log.log('TabellaGenericaBodyPatrimonio inputParams', vm.inputParams);
					
					if(vm.inputParams.listaMovimenti && vm.inputParams.listaMovimenti.length>0){
						vm.confs.listaMovimenti = vm.inputParams.listaMovimenti;
						vm.confs.maxNumMov = vm.inputParams.listaMovimenti.length;
						vm.inputParams.entrato =true;
					}
					//$log.log('TabellaGenericaBodyPatrimonioBuoniController doCheck vm.confs CONTROLLO=', vm.confs);
					//$log.log('TabellaGenericaBodyPatrimonioBuoniController doCheck vm.inputParams.invocato=' , vm.inputParams.listaMovimenti);
					//$log.log('TabellaGenericaBodyPatrimonioBuoniController doCheck vm.inputParams.invocato=' , vm.inputParams.maxNumMov);
					
					//vm.inputParams = undefined;


				} else {

					//$log.log('TabellaGenericaBodyPatrimonioBuoniController doCheck ');
					
					//vm.inputParams = undefined;

				}


			};
			
			
			
            function postLink() {
                UikitProxy.applyPopover();
            }


        }]
}
);