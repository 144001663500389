angular.module('interceptors.sessionInterceptor').factory('SessionInterceptor',
	['$q', '$location', '$window', '$rootScope',  function ($q, $location, $window, $rootScope) {

		return {
			request: function (config) {
				//chiamate a idp per l'auth
				if (config.url.indexOf('/jod-idp-retail') === -1 && config.url.indexOf('/rpol/private/rest/rpolSessionIdpService') === -1 
					&& config.url.indexOf('/rpol/private/pages') === -1 && config.url.indexOf('/views') === -1) {
				
					if (!$rootScope.needAuth) {
					//	$rootScope.$broadcast('needAuth');
						//GUARD VARIABLE
					
					}
					//cancello le richieste in 401 successive
					else{
			  	 	var canceler = $q.defer();
				   	config.timeout = canceler.promise;
					canceler.resolve();
					show_CustomUIKit_PageLoader(1, true);
					}
				}
				return config;

			},

			responseError: function (rejection) {
				if (rejection.status === 401) {
					//cancello il cookie per il check click
					eraseCookie('RPOL_PREV_EXEC', 0, -1);
					show_CustomUIKit_PageLoader(1, true);
					$rootScope.$broadcast('needAuth');
					//	$window.location.href = rejection.data.redirect_url;
					return $q.reject(rejection);
				}
				else {
					//$rootScope.cancelRequest = false;
					return $q.reject(rejection);
				}

			}
		}
	}]);
