angular.module('services.emissioneBuoniMinori').factory(
		'EmissioneBuoniMinoriService',
		[ '$resource', '$filter', 'AppConfs',
		  function($resource, $filter,
					AppConfs) {

				var requestMilliseconds = new Date().getTime();
				var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
				var nomeServizio = '/rpolLibrettoMinoriService';

				return $resource(AppConfs.general.restServices.baseContext + nomeServizio
						+ '/emissioneBfpMinori/:action', {}, {
					prodottiEmettibiliMinori : {
						method : 'GET',
						headers : {
							requestID : 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp : dt
						},
						params : {
							action : "prodottiEmettibiliMinori"
						}
					},
					compila : {
						method : 'POST',
						headers : {
							requestID : 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp : dt
						},
						params : {
							action : "compila"
						}
					},
					richiediOtp : {
						method : 'POST',
						headers : {
							requestID : 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp : dt
						},
						params : {
							action : "richiediOtp"
						}
					},
					emissione : {
						method : 'POST',
						headers : {
							requestID : 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp : dt
						},
						params : {
							action : "emissione"
						}
					},
					esito : {
						method : 'POST',
						headers : {
							requestID : 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp : dt
						},
						params : {
							action : "esito"
						}
					},
					annulla : {
						method : 'POST',
						headers : {
							requestID : 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp : dt
						},
						params : {
							action : "annulla"
						}
					}
				});
			} ]);