/**
 * http://usejsdoc.org/
 * 
 */

angular.module("services.webtrends").service("WebtrendsService", [ "$window", "$log", function($window, $log ) {	
	

	var dcsMultiTrackProxy = function()
    	{
                  if(typeof dcsMultiTrack == "function" && typeof dcsMultiTrack !== "undefined")
                                 dcsMultiTrack.apply(this, arguments);
                  else
                                 console.log("WEBTRENDS NON INVOCATO FUNZIONE NON DEFINITA");
    	};
    
	/** Funzioni Trasversali  **/
   
   /** 
    * Funzione trasversale click  su "Richiedi codice" per la certificazione dell"operazione
    */
   	this.WT_richiedi_codice_certificazione = function(dcsuri, dl, si_n, si_x ) {
    		
    		dcsMultiTrackProxy("DCS.dcsuri",dcsuri,"WT.dl", dl,	"WT.si_n", si_n, "WT.si_p","Autorizza via SMS", "WT.si_x", si_x);
   	};	
    	
	this.WT_mess_errore = function(uri_errore, mess_errore) {
		
		//MDP dcsMultiTrackProxy("WT.dl","2016","DCS.dcsuri",window.location,"DCSext.mess_errore",error_text);
		if (uri_errore === null)
			uri_errore = window.location;  //come MDP, window.location.pathname se vogliono solo il path
		
		dcsMultiTrackProxy("DCSext.uri_errore",uri_errore,"DCSext.mess_errore",mess_errore);
	};
	
	/** PRELOGIN */
	//rpol_Importo_Bfp_Calcolo importo nel formato 100,00 per 100
	this.WT_pre_post_login_calcola_valore_bfp = function(rpol_Tipo_Bfp_Calcolo, rpol_Importo_Bfp_Calcolo) {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_rpol_calcola_valore_bfp", "WT.dl","0", "WT.si_n","rpol_calcola_BFP", 
				"WT.si_p","Thankyou page Valore rendimento BPF", "WT.si_cs","1", "WT.si_x","2", "WT.conv","Calcolo rendimento BFP effettutato",
				"DCSext.rpol_Tipo_Bfp_Calcolo",rpol_Tipo_Bfp_Calcolo, "DCSext.rpol_Importo_Bfp_Calcolo",rpol_Importo_Bfp_Calcolo);
	};
	
	/** LIBRETTO ORDINARIO **/
	
	this.WT_librettiOrd_tab_movimenti = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_librettiOrd_movimenti","WT.dl","0");
	};
	
	this.WT_librettiOrd_tab_supersmart = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_librettiOrd_supersmart","WT.dl","0");
	};
	
	this.WT_librettiOrd_tab_impostazioni = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_librettiOrd_impostazioni","WT.dl","0");
	};
	
	this.WT_librettiOrd_buono_adatto_a_me = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_buono_adatto_ame","WT.dl","0");
	};
	
	this.WT_librettiOrd_emissione_buoni_fogli = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_emissione_buoni_fogli","WT.dl","0",
				"WT.si_n","rpol_emissione_buoni", "WT.si_p","Fogli Informativi", "WT.si_x", "2");
	};
	
	this.WT_librettiOrd_emissione_buoni_acquisto = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_emissione_buoni_acquisto","WT.dl","0",
				"WT.si_n","rpol_emissione_buoni", "WT.si_p","Fogli Informativi", "WT.si_x", "2");
	};
	
	this.WT_librettiOrd_emissione_buoni_controlla = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_emissione_buoni_controlla","WT.dl","0",
				"WT.si_n","rpol_emissione_buoni", "WT.si_p","Controlla e Autorizza", "WT.si_x", "3");
	};
	
	this.WT_librettiOrd_emissione_buoni_thankyoupage = function(rpol_importobuono, rpol_tipo_buono ) {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_emissione_buoni_thankyoupage","WT.dl", "0",
				"DCSext.rpol_importobuono", rpol_importobuono, "DCSext.rpol_tipo_buoni", rpol_tipo_buono, 
				"WT.si_n", "rpol_emissione_buoni", "WT.si_cs", "1", "WT.conv", "Buono Acquistato", 
				"WT.si_p", "Thankyoupage_emissione_buono", "WT.si_x", "5");
	};
	
	/** LIBRETTO SMART **/
	this.WT_librettiSmart_tab_movimenti = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_librettiSmart_movimenti", "WT.dl", "0");
	};
	
	this.WT_librettiSmart_tab_supersmart = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_librettiSmart_supersmart", "WT.dl", "0");
	};
	
	this.WT_librettiSmart_simula_accantonamento = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_off_ssmart_accantonamento", "WT.dl", "0", "WT.si_n", "rpol_simula_accantonamento",
				"WT.si_p", "Importo da accantonare", "WT.si_x", "1");
	};
	
	this.WT_librettiSmart_simula_accantonamento_thankyoupage = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_off_ssmart_accantonamento", "WT.dl", "0", "WT.si_n", "rpol_simula_accantonamento",
				"  WT.si_p", "Thankyoupage Simulatore Accantonamento", "WT.si_cs", "1", "WT.si_x", "2", "WT.conv", "Simulazione Accantonamento Effettuata");
	};
	
	this.WT_librettiSmart_accantonamento_attiva_daSimulatore = function() {
		
		dcsMultiTrackProxy("DCSext.rpol_CTA_Attiva_Acc_daSimulatore","1", "WT.dl", "2016");
	};
	
	this.WT_librettiSmart_accantonamento_insdati = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_off_ssmart_accantonamento_dati", "WT.dl","0", "WT.si_n","rpol_attiva_accantonamento",
				"WT.si_p","Inserisci dati", "WT.si_x","1");
	};
	
	this.WT_librettiSmart_accantonamento_autorizza = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_off_ssmart_accantonamento_aut", "WT.dl","0", "WT.si_n","rpol_attiva_accantonamento",
				"WT.si_p","Controlla e Paga","WT.si_x","2");
	};
	//rpol_tipo_accantonamento = durata accantonamento  -- modifica come da segnalazione del 9/10/2017
	this.WT_librettiSmart_accantonamento_thankyoupage = function(rpol_tipo_accantonamento) {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_off_ssmart_accantonamento_Thankyou", "WT.dl","0", "WT.si_n","rpol_attiva_accantonamento",
				"WT.si_p","Accantonamento effettuato", "WT.si_cs","1", "WT.si_x","4", "WT.conv","Accantonamento Effettuato",
				"DCSext.rpol_tipo_accantonamento", rpol_tipo_accantonamento);
	};
	
	this.WT_librettiSmart_tab_servizi = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_librettiSmart_servizi", "WT.dl","0");
	};
	

	this.WT_librettiSmart_tab_impostazioni = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_librettiSmart_impostazioni", "WT.dl","0");
	};
	
	/** Associazione  NUMERO Libretto smart (abilita libretto alle operazioni dispositive)	 */
	this.WT_librettiSmart_associa_libretto_autenticati = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_associa_lib_autenticati", "WT.dl","0", "WT.si_n","rpol_associa_libretto",
				"WT.si_p","Autenticati", "WT.si_x","2");
	};
	
	this.WT_librettiSmart_associa_libretto_pcr = function() {
		
		dcsMultiTrackProxy("DCSext.rpol_CTA_Associa_PCR","1", "WT.dl","2016");
	};
	
	this.WT_librettiSmart_associa_libretto_appbp = function() {
		
		dcsMultiTrackProxy("DCSext.rpol_CTA_Associa_AppBP","1", "WT.dl","2016");
	};
	
	this.WT_librettiSmart_associa_libretto_controlla = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_associa_lib_controlla", "WT.dl","0", "WT.si_n","rpol_associa_libretto",
				"WT.si_p","Controlla e Abilita", "WT.si_x","3");
	};
	
	this.WT_librettiSmart_associa_libretto_thankyoupage = function(rpol_Strumento_Associazione) {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_associa_lib_thankyoupage", "WT.dl","0", "WT.si_n","rpol_associa_libretto",
				"WT.si_p","Thankyoupage_associaLibretto", "WT.si_cs","1", "DCSext.rpol_Strumento_Associazione", rpol_Strumento_Associazione,
				"WT.conv", "Libretto Associato", "WT.si_x","4");
	};
	
	/** Certifica TELEFONO  Libretto Smart */
	this.WT_librettiSmart_certifica_lib_controlla = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_certifica_lib_controlla", "WT.dl","0", "WT.si_n","rpol_certifica_smart",
				"WT.si_p","Controlla e Abilita", "WT.si_x","2");
	};
	
	this.WT_librettiSmart_certifica_lib_thankyoupage = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_certifica_lib_thankyoupage", "WT.dl","0", "WT.si_n","rpol_certifica_smart",
				"WT.si_p","Thankyoupage_associaLibretto", "WT.si_cs","1", "WT.conv","Libretto Certificato", "WT.si_x","4");
	};
	
	/** Associazione IBAN per alimentazione libretto */
	this.WT_librettiSmart_associa_iban_condizioni = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_associa_iban_condizioni", "WT.dl","0", "WT.si_n","rpol_associa_iban",
				"WT.si_p","Accetta Condizioni", "WT.si_x","2");
	};
	
	this.WT_librettiSmart_associa_iban_controlla= function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_associa_Iban_controlla", "WT.dl","0", "WT.si_n","rpol_associa_iban",
				"WT.si_p","Controlla e Associa", "WT.si_x","3");
	};
	
	this.WT_librettiSmart_associa_iban_thankyoupage = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_associa_Iban_thankyoupage", "WT.dl","0", "WT.si_n","rpol_associa_iban",
				"WT.si_p","Thankyoupage_associaIban", "WT.si_cs","1", "WT.conv","Iban Associato", "WT.si_x","4");
	};
	
	/** Rimborso Buoni	 */
	this.WT_librettiSmart_rimborsa_buoni_controlla = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_rimborsa_buoni_controlla", "WT.dl","0", "WT.si_n","rpol_rimborso_buoni",
				"WT.si_p","Controlla e Autorizza", "WT.si_x","2");
	};
	
	this.WT_librettiSmart_rimborsa_buoni_thankyoupage = function(rpol_tipo_rimborso, rpol_importoBuonoRimborsato) {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_rimborsa_buoni_ThankyouPage", "WT.dl","0", "WT.si_n","rpol_rimborso_buoni",
				"WT.si_p","Thankyoupage_BuonoRimborsato", "WT.si_cs","1", "WT.si_x","4", "WT.conv","Buono Rimborsato",
				"DCSext.rpol_tipo_rimborso",rpol_tipo_rimborso, "DCSext.rpol_importoBuonoRimborsato",rpol_importoBuonoRimborsato);
	};
	
	/** Nota informativa */
	this.WT_librettiSmart_nota_informativa_thankyoupage = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_nota_informativa_ThankyouPage", "WT.dl","0", "WT.si_n","rpol_nota_informativa",
				"WT.si_p","Thankyoupage_NotaInformativa", "WT.si_cs","1", "WT.si_x","2", "WT.conv","Nota Informativa Richiesta");
	};
	
	/** Girofondo da Libretto a Conto  - GirofondoLC */
	this.WT_librettiSmart_giro_lib_conto_controlla = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_giro_lib_conto_controlla", "WT.dl","0", "WT.si_n","rpol_giro_lib_conto", 
				"WT.si_p","Controlla e Trasferisci", "WT.si_x","2");
	};
	//rpol_importogiroLibCC fromato 100,00 per importo 100
	this.WT_librettiSmart_giro_lib_conto_thankyoupage = function(rpol_importogiroLibCC) {
	
		dcsMultiTrackProxy("DCS.dcsuri","alias_giro_lib_conto_ThankyouPage", "WT.dl","0", "WT.si_n","rpol_giro_lib_conto",
				"WT.si_p","Thankyoupage_GirofondoEffettuato", "WT.si_cs","1", "WT.conv","Girofondo Lib->CC Effettuato", "WT.si_x","4",
				"DCSext.rpol_importogiroLibCC", rpol_importogiroLibCC);
	};
	
	/** Girofondo da Libretto a Libretto -  GirpfondoLL**/
	this.WT_librettiSmart_giro_lib_lib_controlla = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_giro_lib_conto_controlla", "WT.dl","0", "WT.si_n","rpol_giro_lib_lib", 
				"WT.si_p","Controlla e Trasferisci", "WT.si_x","2");
	};
	
	//rpol_importogiroLibCC fromato 100,00 per importo 100
	this.WT_librettiSmart_giro_lib_lib_thankyoupage = function(rpol_importogiroLibLib) {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_giro_lib_conto_ThankyouPage", "WT.dl","0", "WT.si_n","rpol_giro_lib_lib",
				"WT.si_p","Thankyoupage_GirofondoEffettuato", "WT.si_cs","1", "WT.si_x","4", "WT.conv","Girofondo Lib->Lib Effettuato",
				"DCSext.rpol_importogiroLibLib", rpol_importogiroLibLib);
	};
	
	/** Ricarica PostePay **/
	this.WT_librettiSmart_ricarica_ppay_controlla = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_ricppay_controlla", "WT.dl","0", "WT.si_n","rpol_ric_ppay", 
				"WT.si_p","Controlla e Paga", "WT.si_x","2");
	};
	
	this.WT_librettiSmart_ricarica_ppay_thankyoupage = function(rpol_importogiroRicarica) {
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_ricppay_ThankyouPage", "WT.dl","0", "WT.si_n","rpol_ric_ppay", 
				"WT.si_p","Thankyoupage Ricarica Postepay", "WT.si_cs","1", "WT.si_x","4", "WT.conv","Ricarica Postepay Effettuata",
				"DCSext.rpol_importogiroRicarica",rpol_importogiroRicarica);
	};
	
	/** Calcola buoni Post Login  ? **/
	this.WT_calcolatore_importo_simula_BPF = function(rpol_importo_simula_BPF, rpol_TipoFigura_SimulaBPF, rpol_Tipo_Bfp_Simulazione, conv){
		
		dcsMultiTrackProxy("DCSext.rpol_importo_simula_BPF",rpol_importo_simula_BPF, "DCSext.rpol_TipoFigura_SimulaBPF",rpol_TipoFigura_SimulaBPF,
				"DCSext.rpol_Tipo_Bfp_Simulazione", rpol_Tipo_Bfp_Simulazione, "WT.conv",conv, "WT.dl", "2016");
	}
	
	/** Onboarding libretto ordinario cartaceo **/
	this.WT_librettiOrd_cart_onboarding_atterraggio = function (){
		
		dcsMultiTrackProxy("WT.si_n","rpol_Onboard_Ord_Cart", "WT.si_p","Inserisci Dati ", "WT.si_x","1");
	}

	this.WT_librettiOrd_cart_onboarding_controlla = function (){
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_onboardOrdCart_controlla", "WT.dl","0", "WT.si_n","rpol_Onboard_Ord_Cart", 
				"WT.si_p","Controlla e Autorizza", "WT.si_x","2");
	}
	
	this.WT_librettiOrd_cart_onboarding_thankypupage = function(){
	
		dcsMultiTrackProxy("DCS.dcsuri","alias_onboardOrdCart_thankyoupage", "WT.dl","0", "WT.si_n","rpol_Onboard_Ord_Cart", 
				"WT.si_p","Thankyoupage Onboard Cartaceo Ordinario", "WT.si_cs","1", "WT.conv","Libretto Ordinario Cartaceo Onboardato", "WT.si_x","3");
	}
	
	/** Onboarding libretto ordinario dematerializzato **/
	this.WT_librettiOrd_dem_onboarding_atterraggio = function (){
		
		dcsMultiTrackProxy("WT.si_n","rpol_Onboard_Ord_Demat", "WT.si_p","Inserisci Dati ", "WT.si_x","1");
	}

	this.WT_librettiOrd_dem_onboarding_controlla = function (){
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_onboardOrdDemat_controlla", "WT.dl","0", "WT.si_n","rpol_Onboard_Ord_Demat", 
				"WT.si_p","Controlla e Autorizza", "WT.si_x","2");
	}
	
	this.WT_librettiOrd_dem_onboarding_thankypupage = function(){
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_onboardOrdDemat_thankyoupage", "WT.dl","0", "WT.si_n","rpol_Onboard_Ord_Demat", 
				"WT.si_p","Thankyoupage Onboard Demat Ordinario", "WT.si_cs","1", "WT.conv","Libretto Ordinario Dematerializzato  Onboardato", "WT.si_x","3");
	}
	
	/** Disattiva Accantonamenti Disattivazione effettuata **/
	this.WT_disattiva_accantonamenti_effettuata = function(){
		
		dcsMultiTrackProxy("WT.dl","2016", "WT.si_cs","1", "WT.conv","Accantonamenti Disattivati");
		
	}
	
	/** Revoca IBAN - Revoca effettuata **/
	this.WT_revoca_iban_effettuataa = function(){
		
		dcsMultiTrackProxy("WT.dl","2016", "WT.si_cs","1", "WT.conv","Iban Revocato");
		
	}
	
	/** Cartaceo non anagrafato **/
	this.WT_onb_buono_cartaceo_riepilogo = function(){
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_onb_buono_cartaceo_riepilogo", "WT.dl","0", "WT.si_n","rpol_add_buono_cartaceo_NA", "WT.si_x","2");
	}
	
	this.WT_onb_buono_cartaceo_ThankyouPage = function(tipoBuono, importo){
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_onb_buono_cartaceo_ThankyouPage", "WT.dl","0", "WT.si_n","rpol_add_buono_cartaceo_NA", "WT.si_cs","1",
					"WT.si_x","3", "WT.conv","Libretto Cartaceo Non Anagrafato Censito", "DCSext.Ins_tipo_buono_cartaceo", tipoBuono, "DCSext.Ins_tipo_buono_cartaceo_imp", importo);
	}
	/**Rimuovi buono in Partrimonio Buoni **/
	this.WT_Can_buono_cartaceo_NA = function(){
		
		dcsMultiTrackProxy("DCSext.Can_buono_cartaceo_NA","1");
		
	}
	
	/** OnBoarding Buono cartaceo **/
	this.WT_onb_buoni_cartacei_anagr = function(){
		
		dcsMultiTrackProxy("WT.si_n","rpol_onb_buoni_cartacei_anagr", "WT.si_x","1");
	}
	this.onb_cartaceo_ana_controlla = function(){
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_onb_cartaceo_ana_controlla", "WT.dl","0", "WT.si_n","rpol_onb_buoni_cartacei_anagr", "WT.si_x","2");
	}
	this.WT_onb_cartaceo_ana_controllaThankyouPage = function(importo){
		
		dcsMultiTrackProxy("DCS.dcsuri","alias_onb_cartaceo_ana_controllaThankyouPage", "WT.dl","0", "WT.si_n","rpol_onb_buoni_cartacei_anagr", 
				"WT.si_cs","1", "WT.si_x","3", "WT.conv","Onboarding buoni cartacei anagrafati effettuato", "DCSext.rpol_onbCartaceo_importo", importo);
	}
	
	/** Situazione cliente all"atterraggio in HomePage **/
	this.WT_atterraggioHomePage = function(rpol_seg_flg_SmartDispo, rpol_seg_flg_SmartDispoIban, rpol_seg_flg_SmartInfoNoTelSiCC, 
				rpol_seg_flg_SmartInfoSiTel, rpol_seg_flg_SmartInfoNoTel, rpol_seg_flg_NoProdotti){
		
		dcsMultiTrackProxy("DCSext.rpol_seg_flg_SmartDispo",rpol_seg_flg_SmartDispo.toString(), "DCSext.rpol_seg_flg_SmartDispoIban", rpol_seg_flg_SmartDispoIban.toString(), "DCSext.rpol_seg_flg_SmartInfoNoTelSiCC",rpol_seg_flg_SmartInfoNoTelSiCC.toString(), 
				"DCSext.rpol_seg_flg_SmartInfoSiTel",rpol_seg_flg_SmartInfoSiTel.toString(), "DCSext.rpol_seg_flg_SmartInfoNoTel", rpol_seg_flg_SmartInfoNoTel.toString(), "DCSext.rpol_seg_flg_NoProdotti", rpol_seg_flg_NoProdotti.toString(),
				"WT.dl", "2016");
		
	}

	this.WT_aperturaAccordionSelezioneBuoni = function(tipo_login) {	
		
		dcsMultiTrackProxy("DCS.dcsuri", "alias_rpol_e2e_selezione_buono", "DCSext.E2E_funnel_name","buono_end2end",
			"DCSext.E2E_step_number", "3", "DCSext.E2E_rpol_tipo_login", tipo_login, "WT.dl", "0", "WT.si_n", "rpol_e2e_acquisto",
			"WT.si_x", "1");
	};

	this.WT_aperturaAccordionDatiAnagrafici = function(prodotto_selezionato, importo_inserito) {	
		
		dcsMultiTrackProxy("DCS.dcsuri", "alias_rpol_e2e_dati_anagrafici", "DCSext.E2E_funnel_name","buono_end2end",
			"DCSext.E2E_step_number", "3", "WT.si_x", "2", "DCSext.E2E_rpol_prodotto", prodotto_selezionato,
			"DCSext.E2E_rpol_importo", importo_inserito, "WT.si_n", "rpol_e2e_acquisto", "DCSext.E2E_rpol_flag_priv_commerciale", "1");
	};

	this.WT_aperturaAccordionDocumentiIdentita = function() {	
		
		dcsMultiTrackProxy("DCS.dcsuri", "alias_rpol_e2e_documenti_identita", "DCSext.E2E_funnel_name","buono_end2end",
			"DCSext.E2E_step_number", "3", "WT.dl", "0", "WT.si_n", "rpol_e2e_acquisto",
			"WT.si_x", "3");
	};

	this.WT_clickCaricaDocumenti = function() {	
		
		dcsMultiTrackProxy("DCS.dcsuri", "alias_rpol_e2e_documenti_identita", "DCSext.E2E_funnel_name","buono_end2end",
			"DCSext.E2E_step_number", "3", "WT.dl", "0", "WT.si_n", "rpol_e2e_acquisto",
			"WT.si_x", "3", "DCSext.E2E_rpol_cta_carica_documenti", "1");
	};

	this.WT_aperturaAccordionDatiLibretto = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri", "alias_rpol_e2e_dati_libretto", "DCSext.E2E_funnel_name","buono_end2end",
			"DCSext.E2E_step_number", "3", "WT.dl", "0", "WT.si_n", "rpol_e2e_acquisto",
			"WT.si_x", "4");
	};

	this.WT_clickCambiaUfficioPostale = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri", "alias_rpol_e2e_dati_libretto", "DCSext.E2E_funnel_name","buono_end2end",
			"DCSext.E2E_step_number", "3", "WT.dl", "0", "WT.si_n", "rpol_e2e_acquisto",
			"WT.si_x", "4", "DCSext.E2E_rpol_cta_cambia_up", "1");
	};

	this.WT_aperturaAccordionUlterioriAdempimenti = function() {
		
		dcsMultiTrackProxy("DCS.dcsuri", "alias_rpol_e2e_ulteriori_adempimenti", "DCSext.E2E_funnel_name","buono_end2end",
			"DCSext.E2E_step_number", "3", "WT.dl", "0", "WT.si_n", "rpol_e2e_acquisto",
			"WT.conv", "Inserimento Dati Offerta Effettuato", "WT.si_cs", "1", "WT.si_x", "5");
	};

	this.WT_atterraggioTYP = function(attivita_qav, prodotto_selezionato, importo_inserito) {
		
		dcsMultiTrackProxy("DCSext.E2E_rpol_qav_attività", attivita_qav, "DCSext.E2E_funnel_name", "buono_end2end",
			"DCSext.E2E_step_number", "5", "DCSext.E2E_rpol_prodotto", prodotto_selezionato, "DCSext.E2E_rpol_importo",
			importo_inserito);
	};

	this.WT_clickInviaBonificoTYP = function(attivita_qav, prodotto_selezionato, importo_inserito) {
		
		dcsMultiTrackProxy("DCSext.E2E_rpol_qav_attività", attivita_qav, "DCSext.E2E_funnel_name", "buono_end2end",
			"DCSext.E2E_step_number", "5", "DCSext.E2E_rpol_prodotto", prodotto_selezionato, "DCSext.E2E_rpol_importo",
			importo_inserito, "DCSext.E2E_rpol_cta_invia_bonifico", "1");
	};

	this.WT_clickCopiaIbanTYP = function(attivita_qav, prodotto_selezionato, importo_inserito) {
		
		dcsMultiTrackProxy("DCSext.E2E_rpol_qav_attività", attivita_qav, "DCSext.E2E_funnel_name", "buono_end2end",
			"DCSext.E2E_step_number", "5", "DCSext.E2E_rpol_prodotto", prodotto_selezionato, "DCSext.E2E_rpol_importo",
			importo_inserito, "DCSext.E2E_rpol_cta_copia_iban", "1");
	};
	
	
}]);