angular.module('boxUploadDragndropNew').factory('BoxUploadDragndropNewService', [
	function() {

		var pdfjsLib = window['pdfjs-dist/build/pdf'];
		if (pdfjsLib) {
			pdfjsLib.GlobalWorkerOptions.workerSrc =
				'//mozilla.github.io/pdf.js/build/pdf.worker.js';
		}

		function base64MimeType(encoded) {
			var result = null;
			if (typeof encoded !== 'string') {
				return result;
			}
			var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
			if (mime && mime.length) {
				result = mime[1];
			}
			return result;
		}

		function checkFileType(result) {
			var mimeTypeFile = base64MimeType(result);
			if (mimeTypeFile == "application/pdf") {
				return 'pdf';
			} else {
				return 'img';
			}
		}
		
		function checkPagePdfLimit(file, maxPage, f, callback) {
			if (checkFileType(file.result) == 'pdf') {
				var base64 = file.result.substr(file.result.indexOf(',') + 1);
				var loadingTask = pdfjsLib.getDocument({
					data : atob(base64)
				});
				loadingTask.promise.then(function(pdf) {
					var numPages = pdf.numPages;
					if (numPages > maxPage) {
						callback(false);
					} else {
						callback(true, file, numPages, f);
					}
				});
			} else {
				callback(true, file, 1, f);
			}
		}

		function createArrayOfBlobFiles(files) {
			var filesBlob = [];
			files.forEach(function(file) {
				var base64 = file.result;
				if (base64) {
					var contentType = base64MimeType(base64);
					base64 = base64.substr(base64.indexOf(',') + 1);
					var byteCharacters = atob(base64);
					var byteNumbers = new Array(byteCharacters.length);
					for (var i = 0; i < byteCharacters.length; i++) {
						byteNumbers[i] = byteCharacters.charCodeAt(i);
					}
					var byteArray = new Uint8Array(byteNumbers);
					var blob = new Blob([ byteArray ], {
						type : contentType
					});
					filesBlob.push({
						blobFile : blob,
						fileName : file.fileName
					});
				}
			});
			return filesBlob;
		}

		return {
			checkPagePdfLimit : checkPagePdfLimit,
			createArrayOfBlobFiles : createArrayOfBlobFiles,
			checkFileType : checkFileType
		}
	}
]);