angular.module('services.uikitProxy').factory('UikitProxy',
	['$log', function ($log) {

		var api = {};

		api.applyPopover = applyPopover;
		api.applyDatePicker = applyDatePicker;
		api.applyDatePickerSpecial = applyDatePickerSpecial;
		api.applyDatePickerBPOL = applyDatePickerBPOL;
		api.applyBracketSticky = applyBracketSticky;
		api.applyCustomRadio = applyCustomRadio;
		api.applyCustomCheckbox = applyCustomCheckbox;
		api.applyPariAltezzaFun = applyPariAltezzaFun;
		api.applyCustomSelect = applyCustomSelect;
		

		return api;

		//condivise/javascript/utilita.js
		function applyPariAltezzaFun(childElements) {


			if (childElements.length > 0) {

				$log.log('services.uikitProxy applyPariAltezzaFunction childElements --> ', childElements);

				var heights = childElements.map(function () {
					//return $(this).outerHeight(false);
					return Math.ceil(this.getBoundingClientRect().height);
				}).get();

				$log.log('services.uikitProxy applyPariAltezzaFunction heights', heights);
				var maxHeight = Math.max.apply(null, heights);

				$log.log('services.uikitProxy applyPariAltezzaFunction maxHeight', maxHeight);
				childElements.css("min-height", maxHeight);

				if ((mq_Detect == 'xs') && !(childElements.parents().hasClass('content-overflow'))) {
					childElements.css("min-height", "auto");
				}

				if (childElements.hasClass('equalize-height')) {
					childElements.css("height", maxHeight);
				}

			}

		}


		//condivise/javascript/start-script.js
		function applyPopover() {

			$('[data-toggle="popover"]').popover({
				html: true,
				template: '<div class="popover"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>',
				placement: popoverwheretoplace
			});
		}

		//condivise/javascript/start-script.js
		function applyDatePicker(options) {

			/*************/
			/* Calendari */
			/*************/
			function startDatePicker(options) {
				if ($(".date").length > 0) {
					$('.date input:not([readonly])').datepicker({
						format: 'dd/mm/yyyy',
						onRender: function (date) {
							if (options) {
								if (options.startDate && options.endDate) {
									return (date.valueOf() < moment(options.startDate, 'DD-MM-YYYY').valueOf() || date.valueOf() > moment(options.endDate, 'DD-MM-YYYY').valueOf()) ? 'disabled' : '';
								}
								else if (options.startDate) {
									return date.valueOf() < moment(options.startDate, 'DD-MM-YYYY').valueOf() ? 'disabled' : '';
								}
								else if (options.endDate) {
									return date.valueOf() > moment(options.endDate, 'DD-MM-YYYY').valueOf() ? 'disabled' : '';
								}
							}
							else {

							}
						}
					});
				}
			}

			startDatePicker(options);

			/* Trigger click date */
			if ($(".date").length > 0) {
				$(".date .input-group-addon").click(function () {
					var myInputTgt = $(this).prev();
					$(myInputTgt).focus();
				});
				/* 
								if ($('html.pi-mobile').length > 0) {
									$('.input-group.date input').attr('type', 'date');
								}*/
			}

			//RPOL CUSTOM code (NO UIKIT):
			if ($(".date").length > 0) {
				$(".date input:not([readonly])").datepicker().on('changeDate', function (ev) {
					$(".date input:not([readonly])").datepicker('hide');

					//solleva evento standard 'change' per scatenare ciclo di digest angular
					$(ev.target).trigger('change');


				});
			}

			// Gestione chiusura datepicker dopo aver selezionato data intera con anno/mese/giorno
			// $(window).mouseup(function(event){
			// 	if(event.target.className.slice(0,3) == "day"){
			// 		$(".date."+options.class+" input:not([readonly])").datepicker('hide');
			// 	}
			// });

		}

		// Datepicker Special
		function applyDatePickerSpecial(options) {

			/*************/
			/* Calendari */
			/*************/
			function startDatePicker(options) {
				if ($(".date."+options.class).length > 0) {
					$('.date.'+options.class+' input:not([readonly])').datepicker({
						format: 'dd/mm/yyyy',
						onRender: function (date) {
							if (options) {
								if (options.startDate && options.endDate) {
									return (date.valueOf() < moment(options.startDate, 'DD-MM-YYYY').valueOf() || date.valueOf() > moment(options.endDate, 'DD-MM-YYYY').valueOf()) ? 'disabled' : '';
								}
								else if (options.startDate) {
									return date.valueOf() < moment(options.startDate, 'DD-MM-YYYY').valueOf() ? 'disabled' : '';
								}
								else if (options.endDate) {
									return date.valueOf() > moment(options.endDate, 'DD-MM-YYYY').valueOf() ? 'disabled' : '';
								}
							}
							else {

							}
						}
					});
				}
			}

			startDatePicker(options);

			/* Trigger click date */
			if ($(".date").length > 0) {
				$(".date .input-group-addon").click(function () {
					var myInputTgt = $(this).prev();
					$(myInputTgt).focus();
				});
				/* 
								if ($('html.pi-mobile').length > 0) {
									$('.input-group.date input').attr('type', 'date');
								}*/
			}

			//RPOL CUSTOM code (NO UIKIT):
			if ($(".date."+options.class).length > 0) {
				$(".date."+options.class+" input:not([readonly])").datepicker().on('changeDate', function (ev) {
					// $(".date."+options.class+" input:not([readonly])").datepicker('hide');

					// Gestione chiusura datepicker dopo aver selezionato data intera con anno/mese/giorno
					$(window).mouseup(function(event){
						if(event.target.className.slice(0,3) == "day"){
							$(".date."+options.class+" input:not([readonly])").datepicker('hide');
						}
					});
					//solleva evento standard 'change' per scatenare ciclo di digest angular
					$(ev.target).trigger('change');


				});
			}

		}

		function applyDatePickerBPOL() {
			/******************/
			/* Calendari */
			/******************/
			if ($(".date").length > 0) {
				$('.date input:not([readonly])').datepicker({
					format: 'dd/mm/yyyy',
					onRender: function (date) { }
				}).on('changeDate', function (ev) {
					$(ev.target).trigger('change');
				}).on('change', function (e) {
				});
			}
			/* Trigger click date */
			if ($(".date").length > 0) {
				$(".date .input-group-addon").click(function () {
					var myInputTgt = $(this).prev();
					$(myInputTgt).focus()
				});
			}
		}

		//condivise/javascript/scroll-pi.js
		function applyBracketSticky() {


			/************************/
			/* Stick colonna destra */
			/************************/
			if ($('#bracket').length > 0) {

				if ($('.content-main').length > 0) {
					stLi = '.content-main';
					if ($('.content-page-anchors').length > 0) {
						stickFromTop = ($('.content-page-anchors').outerHeight() * 2) + minifiedHeader_H;
					} else {
						stickFromTop = minifiedHeader_H + 60;
					}
				} else if ($('.content-applicative').length > 0) {
					stLi = '.content-applicative';
					stickFromTop = minifiedHeader_H + 60;
				}

			}

			/*****************************************/
			/* Attiva Bracket sticky */
			/*****************************************/

			startBracketSticky();
		}

		//applicazioni/trasversali/javascript/custom-form-element.js
		function applyCustomRadio() {
			customRadio();
		}

		function applyCustomCheckbox() {
			customCheckbox();
		}

		function applyCustomSelect() {
			customSelect();
		}

	}]
);