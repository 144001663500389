angular.module('services.acquistoBuonoELibretto').factory('AcquistoBuonoELibrettoService',
	['$resource', '$filter', 'AppConfs',
		function ($resource, $filter, AppConfs) {

			var requestMilliseconds = new Date().getTime();
			var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
			var nomeServizio = '/rpolAcquistoBuoni';

			return $resource(
				AppConfs.general.restServices.baseContext + nomeServizio + '/:prefix/:package/:action',
				{}, {
					aggiornaDatiBuono: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "funnelVendita",
							action: "aggiornaDatiBuono"
						}
					},
					// upload: {
					// 	method: 'POST',
					// 	withCredentials: true,
					// 	headers: {
					// 		'Content-Type': undefined,
					// 		requestID: 'RPOL.WEB.' + requestMilliseconds,
					// 		requestTimestamp: dt
					// 	},
					// 	transformRequest: angular.identity,
					// 	params: {
					// 		package: "funnelVendita",
					// 		action: "uploadDocumentiCruscotto"
					// 	}
					// },
					anagrafica: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "funnelVendita",
							action: "anagrafica"
						}
					},
					datiVendita: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "funnelVendita",
							action: "datiVendita"
						}
					},
					inizializza: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "funnelVendita",
							action: "inizializza"
						}
					},
					normalizza: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "normalizzaIndirizzo",
							action: "normalizza"
						}
					},
					inviaDatiLibretto: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "funnelVendita",
							action: "libretto"
						}
					},
					// inviaDatiDocumenti: {
					// 	method: 'POST',
					// 	withCredentials: true,
					// 	headers: {
					// 		'Content-Type': undefined,
					// 		requestID: 'RPOL.WEB.' + requestMilliseconds,
					// 		requestTimestamp: dt
					// 	},
					// 	transformRequest: angular.identity,
					// 	params: {
					// 		package: "funnelVendita",
					// 		action: "documenti"
					// 	}
					// },
					riceviDatiDocumenti: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "funnelVendita",
							action: "entiRilascio"
						}
					},
					prodottiEmettibili: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "emissione",
							action: "prodottiEmettibili"
						}
					},
					listaNazioni: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "listaLuoghi",
							action: "listaNazioni"
						}
					},
					listaComuni: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "listaLuoghi",
							action: "listaComuni"
						}
					},
					listaProvince: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "listaLuoghi",
							action: "listaProvince"
						}
					},
					verifica: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "emissione",
							action: "verifica"
						}
					},
					emissione: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "emissione",
							action: "emissione"
						}
					},
					qavGetAll: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "qav",
							action: "getAll"
						}
					},
					qavInvia: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "qav",
							action: "invia"
						}
					},
					verificaAdesioneFirma: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							prefix: "funnelVendita",
							package: "fea",
							action: "verificaAdesioneFirma"
						}
					},
					concludiAdesione: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							prefix: "funnelVendita",
							package: "fea",
							action: "concludiAdesione"
						}
					},
					concludiFirma: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							prefix: "funnelVendita",
							package: "fea",
							action: "concludiFirma"
						}
					}
				});
		}]);
