/*
    Direttiva che implementa la funzione globalEqualize dell'UIKIT solo per le classi "equalize-group"

    function globalEqualize() {
        pari_altezza('.equalize');
        pari_altezza('.equalize-2');
        pari_altezza('.equalize-3');
        pari_altezza('.carousel-word .item');
        pari_altezza('.content-mosaic .mosaic-element');
        pari_altezza('.panel-cards-bg-full .panel-wrap .panel-body');
        equalizeCycle('.equalize-group', '.panel-cards');
        equalizeCycle('.equalize-group', '.dashboard-subcontent.equalize-height');
        equalizeCycle('.equalize-group', '.box-container');
        equalizeCycle('.equalize-group', '.myservices-area-small');

    }

*/

angular.module('directives.equalizeGroup').directive('equalizeGroup', ['$log','UikitProxy' ,function ($log,UikitProxy) {
    return {
        restrict: 'C',
        link: function (scope, element, attr) {

                UikitProxy.applyPariAltezzaFun( $(element).find('.panel-cards') );
                UikitProxy.applyPariAltezzaFun( $(element).find('.dashboard-subcontent.equalize-height') );
                UikitProxy.applyPariAltezzaFun( $(element).find('.box-container') );
                UikitProxy.applyPariAltezzaFun( $(element).find('.myservices-area-small') );
            
        }
    }
}]);