angular.module('sezioneBuoniInseritiCartacei').component('sezioneBuoniInseritiCartacei', {
	bindings: {
		inputParams: '<'
	},
	require: {
        parentCtrl: '^^manager'
    },
	templateUrl: '/rpol/private/pages/common-templates/sezione-buoni-inseriti-cartacei.template.html',
	controller: ['$log', 'UikitProxy', 'WebtrendsService', 'SatelliteService', '$timeout', 'CartaceoNonAnagrafatoService', 'UtenteService', '$scope',
		function SezioneBuoniInseritiCartaceiController($log, UikitProxy, WebtrendsService, SatelliteService, $timeout, CartaceoNonAnagrafatoService, UtenteService, $scope) {

			var vm = this;

			var entrato = false;
			//SezioneBuoniInseritiCartacei vars
			vm.confs = {};
			vm.visualizzaModificaRow = false;
			vm.pagination = 10;
			vm.confs.maxNumMovCartacei;
			//SezioneBuoniInseritiCartacei funcs
			vm.changePagination = changePagination;
			vm.$doCheck = doCheck;
			vm.$postLink = postLink;
			vm.visualizzaAltriMovimenti = visualizzaAltriMovimenti;
			vm.visualizzaMenoMovimenti = visualizzaMenoMovimenti;

			vm.cancellaIstanza = cancellaIstanza;
			vm.cancellaBuono = cancellaBuono;
			vm.modificaNomeBuono = modificaNomeBuono;
			vm.salvaNomeBuono = salvaNomeBuono;
			vm.inserisciBuono = inserisciBuono;
			vm.abilitaLibretto = abilitaLibretto;
			vm.abilitaBuoni = abilitaBuoni;


			function abilitaBuoni() {
				//$log.log('ConfigurazioniService abilitaBuoni');
				window.location = '/rpol/private/pages/on-boarding-buoni.html';
			}

			function abilitaLibretto(numeroRapporto, formaLibretto) {
				//$log.log('ConfigurazioniService abilitaLibretto');

				var path = "/rpol/private/pages/on-boarding-lib.html";
				var request = JSON.parse('{ "data": { "mappaVariabiliTemp": { "numeroRapporto_onBoarding": "' + numeroRapporto + '","formaLibretto_onBoarding": "' + formaLibretto + '" } } }');
				$log.log('ManagerController toOnBoardingLibApp json :', request);


				UtenteService.inserisci(request,
					function (response) {
						$log.log('ManagerController  UtenteService.inserisci response: ', response);
						window.location = path;
					},
					function (httpResponse) {
						//GESTIONE DELL'ERRORE DELEGATA AL RECUPERA SU ON-BOARDING-LIB
						$log.log('ManagerController UtenteService.inserisci error-response: ', httpResponse);
						window.location = path;
					}
				);




				window.location = '/rpol/private/pages/on-boarding-lib.html';
			}

			function inserisciBuono() {
				//$log.log('ConfigurazioniService inserisciBuono');
				window.location = '/rpol/private/pages/cartaceo-non-anagrafato.html';
			}

			function cancellaIstanza(item, movimento) {
				vm.myItemModalMovimento = null;
				vm.myItemModalMovimento = movimento;
				//$log.log('ConfigurazioniService cancellazione item:', item, movimento);
			}

			function modificaNomeBuono(indice, item) {
				vm.visualizzaModificaRow = true;
				vm.descrizioneAggiuntiva = item.descrizioneAggiuntiva;
				//$log.log('ConfigurazioniService modifica nome item:', item);
			}

			function salvaNomeBuono(nuovaDescrizione, movimento) {
				vm.visualizzaModificaRow = false;
				show_CustomUIKit_PageLoader(1);
				CartaceoNonAnagrafatoService.modificaEsegui({
					data: {

						bfpDatiCartaceoId: movimento.bfpDatiCartaceoId,
						bfpCartaceoId: movimento.bfpCartaceoId,
						descrizioneAggiuntiva: nuovaDescrizione
					}
				},
					function (response) {
						$log.log('ManagerController SimulatoreService.modificaEsegui response:', response);
						vm.confs.maxNumMovCartacei = vm.inputParams.maxNumMovCartacei;
						vm.confs.buoniPosseduti = response.data.buoniInseriti.numeroBfp;
						vm.confs.valoreNominale = response.data.buoniInseriti.valoreNominaleTotale;
						vm.confs.valoreRimborsoLordo = response.data.buoniInseriti.valoreRimborsoLordo;
						vm.confs.lunghezzaListaBuoniScaduti = response.data.buoniInseriti.buoniScaduti;
						vm.confs.listaBfpCartacei = response.data.buoniInseriti.buonoCartaceo;

						hide_CustomUIKit_PageLoader();
					},
					function (httpResponse) {
						$log.log('ManagerController UtenteService.modificaEsegui error-response:', httpResponse);

						var risposta = {};
						risposta.httpResponse = httpResponse;
						risposta.tipoErrore = 'exception0';
						//var errorResponseMessage = vm.getErrorResponseMessage(httpResponse);

						//webtrendsLogError(null, errorResponseMessage);
						$scope.$emit('validationErrorTable', risposta);

						//$log.log('ManagerController UtenteService.modificaEsegui vm.esitoPage_Confs :', vm.esitoPage_Confs);

						forceHide_CustomUIKit_PageLoader();
					}
				);

				//$log.log('ConfigurazioniService salva nome nuovaDescrizione:', nuovaDescrizione);
			}

			function cancellaBuono(indice, item) {
				//$log.log('ConfigurazioniService invoco il servizio di cancellazione request:', item);

				$('#myModalAttiviF-' + indice).modal('hide');
				//hide the modal

				$('body').removeClass('modal-open');
				//modal-open class is added on body so it has to be removed

				$('.modal-backdrop').remove();
				//need to remove div with modal-backdrop class
				show_CustomUIKit_PageLoader(1);
				CartaceoNonAnagrafatoService.cancellaEsegui({
					data: {
						bfpCartaceoId: item.bfpCartaceoId
					}
				},
					function (response) {
						$log.log('ManagerController SimulatoreService.modificaEsegui response:', response);
						vm.confs.maxNumMovCartacei = vm.inputParams.maxNumMovCartacei;
						vm.confs.buoniPosseduti = response.data.buoniInseriti.numeroBfp;
						vm.confs.valoreNominale = response.data.buoniInseriti.valoreNominaleTotale;
						vm.confs.valoreRimborsoLordo = response.data.buoniInseriti.valoreRimborsoLordo;
						vm.confs.lunghezzaListaBuoniScaduti = response.data.buoniInseriti.buoniScaduti;
						vm.confs.listaBfpCartacei = response.data.buoniInseriti.buonoCartaceo;
						
						//Webtrends
		                WebtrendsService.WT_Can_buono_cartaceo_NA();
		                
		                // satellite track
		                var more = {
	                		'tipologia_buono' : '' + item.bfpCartaceoId
		                };
                		SatelliteService.track('Inserimento e Cancellazione Buoni Cartacei', null,
                				'libretto_smart-inserimento_cancellazione_buoni_cartacei', '3', more);
		                
						
						hide_CustomUIKit_PageLoader();
					},
					function (httpResponse) {
						$log.log('ManagerController UtenteService.modificaEsegui error-response:', httpResponse);

						var risposta = {};
						risposta.httpResponse = httpResponse;
						risposta.tipoErrore = 'exception0';
						//var errorResponseMessage = vm.getErrorResponseMessage(httpResponse);

						//webtrendsLogError(null, errorResponseMessage);
						$scope.$emit('validationErrorTable', risposta);

						//$log.log('ManagerController UtenteService.modificaEsegui vm.esitoPage_Confs :', vm.esitoPage_Confs);

						forceHide_CustomUIKit_PageLoader();
					}
				);

				$('.collapse').each(function () {
					$('.collapse').collapse('hide');
				});

				vm.myItemModalMovimento = null;

			}

			function changePagination(input) {
				vm.pagination = input;
				//$log.log('MovimentiController changePagination vm.pagination:', vm.pagination);
				vm.paginaCorrente = 1;
				$timeout(function () {
					$('#listaMovPrint').tableExport({ type: 'init', escape: 'false', nomeFileOutput: 'RPOL_PatrimonioBuoni' });
				}, 1000);
			}

			function doCheck() {
				//$log.log('vm.inputParams.confs:', vm.inputParams);
				//$log.log('vm.inputParams.confs:', vm.inputParams.confs);

				UikitProxy.applyPopover();

				if (vm.inputParams && vm.inputParams.numeroBfp && vm.inputParams.listaBfpCartacei && vm.inputParams.maxNumMovCartacei && vm.inputParams.lunghezzaListaBuoniScaduti && vm.inputParams.valoreNominaleTotale && vm.inputParams.valoreRimborsoLordo && entrato !== true) {
					vm.confs = vm.inputParams;
					vm.confs.maxNumMovCartacei = vm.inputParams.maxNumMovCartacei;
					vm.confs.buoniPosseduti = vm.inputParams.numeroBfp;
					vm.confs.listaBfpCartacei = vm.inputParams.listaBfpCartacei;
					vm.confs.valoreNominale = vm.inputParams.valoreNominaleTotale;
					vm.confs.valoreRimborsoLordo = vm.inputParams.valoreRimborsoLordo;
					vm.confs.lunghezzaListaBuoniScaduti = vm.inputParams.lunghezzaListaBuoniScaduti;
					entrato = true;
					//vm.inputParams = undefined;

				} else {
					//vm.confs = vm.inputParams;
					//$log.log('SezioneBuoniInseritiCartaceiController doCheck ');
					//vm.inputParams = undefined;
				}


			};


			function visualizzaAltriMovimenti(tipologiaBuoni) {

				if (tipologiaBuoni === "visualizzaListaBuoniCartacei") {
					vm.confs.visualizzaListaBuoniCartacei = true;
					vm.confs.maxNumMovCartacei = vm.confs.listaBfpCartacei.length; //MovimentiCartacei
				}

			}

			function visualizzaMenoMovimenti(tipologiaBuoni) {
				if (tipologiaBuoni === "visualizzaListaBuoniCartacei") {
					vm.confs.visualizzaListaBuoniCartacei = false;
					vm.confs.maxNumMovCartacei = 0; //MovimentiCartacei
				}

				//vm.confs.maxNumMov = 0; //VALORE LETTO IN CONFIGURAZIONE                
			}



			function postLink() {
				UikitProxy.applyPopover();
			}


		}]
}
);