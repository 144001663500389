angular.module('services.emissioneBuoni').factory('EmissioneBuoniService', 
['$resource', '$filter', 'AppConfs',
		function ($resource, $filter, AppConfs) {

			var requestMilliseconds = new Date().getTime();
			var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
			var nomeServizio = '/rpolEmissioneBuoni';

			return $resource(
				AppConfs.general.restServices.baseContext + nomeServizio + '/emissione/:action',
				{}, {
				prodottiEmettibili: {
					method: 'GET',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "prodottiEmettibili"
					}
				},
				verifica: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "verifica"
					}
				},
				richiediOtp: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "richiediOtp"
					}
				},
				emissione: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "emissione"
					}
				},
				aggiorna: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "aggiorna"
					}
				},
				comunica: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "comunica"
					}
				},
				esito: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "esito"
					}
				},
				annulla: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "annulla"
					}
				},
				infoLiquiditaAccumulata: {
					method: 'GET',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "infoLiquiditaAccumulata"
					}
				},
				listaNazioni: {
					method: 'GET',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "listaNazioni"
					}
				},
				emissioneBfp : {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "emissioneBfp"
					}
				},
				calcolaRata : {
					method : 'POST',
					headers : {
						requestID : 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp : dt
					},
					params : {
						action : "calcolaRata"
					}
				},
				downloadPDF : {
					method : 'POST',
					headers : {
						requestID : 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp : dt
					},
					params : {
						action : "getDocument"
					}
				}
			});
		}]);
