(function () {

  'use strict';

  angular
    .module('securizzazioneSca')

    .component('securizzazioneSca', {
      templateUrl: '/rpol/private/pages/common-templates/securizzazione-sca.template.html',
      controller: ['$log', '$sce', '$timeout', '$rootScope', 'UtenteService', SecurizzazioneScaController],
      bindings: {
        confs: '<',
        onError: '&',
        onSuccess: '&',
        onBack: '&'

      }
    });

  function SecurizzazioneScaController($log, $sce, $timeout, $rootScope, UtenteService) {

    var vm = this;

    var devicePrintCheck = 0;

    //AuthBox var
    vm.submit = submit;
    vm.indietro = indietro;

    vm.$doCheck = function () {
      if (vm.confs !== undefined && vm.confs.hasOwnProperty('devicePrint')) {
        devicePrintCheck = 1;
        $log.log('SCA COMPONENT vm.confs.devicePrint: ', vm.confs.devicePrint);
      }
    };

    function submit() {
      $log.log('vm.confs: ', vm.confs);

      show_CustomUIKit_PageLoader();

      vm.confs.scaService.prepareData(undefined, {
        data: {
          movementId: vm.confs.movementId,
          idFe: vm.confs.scaApp,
          devicePrint: devicePrintCheck ? vm.confs.devicePrint : null
        }
      },
        function (response) {
          if (response && response.data) {
            saveFunnelData(function () {
              console.log("ok");
              vm.confs.formdata = response.data;
              vm.confs.formdata.action = $sce.trustAsResourceUrl(vm.confs.formdata.urlIdp)

              $timeout(function () {
                document.getElementById('siaSubmit').click();
              }, 0);
              hide_CustomUIKit_PageLoader();

            });

          }

        },
        function (httpResponse) {
          var params = {};
          params.error = {}
          params.error.httpResponse = httpResponse;

          $rootScope.$broadcast('errorScaEvent', params);
          hide_CustomUIKit_PageLoader();

        });

    }

    function saveFunnelData(callback) {
      if (vm.confs.numeroRapporto) {

        UtenteService.inserisci(
          {
            data: {
              mappaVariabiliTemp: {
                numeroRapporto_libretti: vm.confs.numeroRapporto,
                tipologia: 'libretto'
              }
            }
          },
          function (response) {
            
            callback();
          },
          function (httpResponse) {
          
            callback();
          });
      }
      else {
        callback();
      }
    }

    function indietro() {
      $(".waiting").show();
      vm.onBack({
      });
      $(".waiting").hide();


    }


  }
})();
