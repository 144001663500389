angular.module('services.documentUploadNew').factory('DocumentUploadNewService', ['$resource', '$filter', 'AppConfs',
    function ($resource, $filter, AppConfs) {

        var requestMilliseconds = new Date().getTime();
        var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
        return {
            Call: function (baseUrl) {

                var nomeServizio = !baseUrl ? '/rpolFunnelAcquistoLibrettoBfpOss' : '/rpolFunnelAcquistoLibrettoBfpOssBE';
                var baseContext = !baseUrl ? AppConfs.general.restServices.baseContext : baseUrl;

                /** SET PARAMS */
                var uploadService = {
                    method: 'POST',
                    headers: {
                        'Content-Type': undefined,
                        requestID: 'RPOL.WEB.' + requestMilliseconds,
                        requestTimestamp: dt
                    },
                    transformRequest: angular.identity,
                    params: {
                        package: "funnelAcquistoE2E",
                        action: "uploadDocumentiCruscotto"
                    }
                };

                var inviDatiDocumentiService = {
                    method: 'POST',
                    headers: {
                        'Content-Type': undefined,
                        requestID: 'RPOL.WEB.' + requestMilliseconds,
                        requestTimestamp: dt
                    },
                    transformRequest: angular.identity,
                    params: {
                        package: "funnelAcquistoE2E",
                        action: "stepSezioneDocumenti"
                    }
                };

                var uploadSelfieCruscottoService = {
                    method: 'POST',
                    headers: {
                        'Content-Type': undefined,
                        requestID: 'RPOL.WEB.' + requestMilliseconds,
                        requestTimestamp: dt
                    },
                    transformRequest: angular.identity,
                    params: {
                        package: "funnelAcquistoE2E",
                        action: "uploadSelfieCruscotto"
                    }
                };

                var uploadVideoCruscottoService = {
                    method: 'POST',
                    headers: {
                        'Content-Type': undefined,
                        requestID: 'RPOL.WEB.' + requestMilliseconds,
                        requestTimestamp: dt
                    },
                    transformRequest: angular.identity,
                    params: {
                        package: "funnelAcquistoE2E",
                        action: "uploadVideoCruscotto"
                    }
                };


                if (baseUrl) {
                    //Upload Service (cruscotto)
                    uploadService.withCredentials = true;
                    uploadService.params.prefix = 'v1';
                    //Invia documenti Service (acquistobuono)
                    inviDatiDocumentiService.withCredentials = true;
                    inviDatiDocumentiService.params.prefix = 'v1';
                    //UploadSelfie Service (cruscotto)
                    uploadSelfieCruscottoService.withCredentials = true;
                    uploadSelfieCruscottoService.params.prefix = 'v1';
                    // //UploadVideo Service (cruscotto)
                    uploadVideoCruscottoService.withCredentials = true;
                    uploadVideoCruscottoService.params.prefix = 'v1';
                }
                /**/

                return $resource(
                    baseContext + nomeServizio + '/:prefix/:package/:action', {}, {
                        upload: uploadService,
                        inviaDatiDocumenti: inviDatiDocumentiService,
                        uploadSelfieCruscotto: uploadSelfieCruscottoService,
                        uploadVideoCruscotto: uploadVideoCruscottoService
                    });
            }
        }
    }
]);