angular.module('filters.mappaTipologiaLibretto').filter('MappaTipologiaLibretto', function () {
  return function (tipologia) {
  
		var DescrizioneLibretto= "";
		var DescrizioneCompleta= "";
		
	  	switch (tipologia) {
			case "S":
				DescrizioneLibretto= "Smart";
				break;
			case "N":
				DescrizioneLibretto= "Ordinario";
				break;
			case "M":
				DescrizioneLibretto= "Minori";
				break;
			default:
				DescrizioneLibretto= "";
				break;
		} 
		return DescrizioneLibretto;
  };
});