
angular.module('services.wcsJson').factory('wcsJsonService', ['$resource', '$filter', 'AppConfs',
  function($resource, $filter, AppConfs) {

    var requestMilliseconds = new Date().getTime();
    var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');

    return $resource(
      AppConfs.general.restServices.jsonBaseContext , {}, {
        getjson: {
          method: 'GET',
          headers: {
            requestID: 'RPOL.WEB.' + requestMilliseconds,
            requestTimestamp: dt
          }
        }
      });
  }
]);
