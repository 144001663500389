/*
    Direttiva per validare il model del campo 
    d'input a cui è associata; controlla 
    che il valore immesso sia:
        1) formattato nel seguente modo: DD/MM/YYYY
        2) una data valida
*/
angular.module('directives.dateValidator').directive('dateValidator', ['moment','$log', function (moment,$log) {
    
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ctrl) {

            var DATE_REGEXP = /^\d{2}\/\d{2}\/\d{4}$/;
            ctrl.$validators.dateValidator = function(modelValue, viewValue){
                $log.log('ctrl',ctrl);
                $log.log('directives.dateValidator viewValue', viewValue);

                if (ctrl.$isEmpty(modelValue)) {
                    // consider empty models to be valid
                    return true;
                }

                if (DATE_REGEXP.test(viewValue) && moment(viewValue, "DD/MM/YYYY").isValid()) {
                    // it is valid
                    return true;
                }

                // it is invalid
                return false;
            };
        }
    }

}]);