angular.module('services.libretto').factory('IbanService',
	['$resource', '$filter', 'AppConfs',
		function ($resource, $filter, AppConfs) {

			var requestMilliseconds = new Date().getTime();
			var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
			var nomeServizio = '/rpolGestioneLibretto';

			return $resource(
				AppConfs.general.restServices.baseContext + nomeServizio + '/gestioneIban/:action',
				{}, {
					ibanAssociati: {
						method: 'POST',
						headers: {
							//Authorization: BPOLService.authorizationHeaderVal,
							//Username: BPOLService.usernameHeaderVal,
							//Canale: BPOLService.canaleHeaderVal,
							//requestID : 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							action: "ibanAssociati"
						}/*,
                    interceptor: {
                        response: function (response) {
                            var result = response.resource;
                            result.$status = response.status;
                            return result;
                        }
                    }*/
					},
					revocaIbanRapporto: {
						method: 'POST',
						headers: {
							//Authorization: BPOLService.authorizationHeaderVal,
							//Username: BPOLService.usernameHeaderVal,
							//Canale: BPOLService.canaleHeaderVal,
							//requestID : 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							action: "revocaIbanRapporto"
						}/*,
                    interceptor: {
                        response: function (response) {
                            var result = response.resource;
                            result.$status = response.status;
                            return result;
                        }
                    }*/
					},
					inviaIbanMail: {
						method: 'POST',
						headers: {
							//Authorization: BPOLService.authorizationHeaderVal,
							//Username: BPOLService.usernameHeaderVal,
							//Canale: BPOLService.canaleHeaderVal,
							//requestID : 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							action: "inviaIbanMail"
						}/*,
                    interceptor: {
                        response: function (response) {
                            var result = response.resource;
                            result.$status = response.status;
                            return result;
                        }
                    }*/
					},
					richiediEstrattoContoLibretto:{
						method: 'POST',
						headers: {
							//Authorization: BPOLService.authorizationHeaderVal,
							//Username: BPOLService.usernameHeaderVal,
							//Canale: BPOLService.canaleHeaderVal,
							//requestID : 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							action: "richiediEstrattoContoLibretto"
						}
					}


				}

			);
		}]);
