angular.module('slickSlider').component('slickSlider',
	{
		bindings: {
			inputParams: '<',
			hasMinori: '='
		},
		/*
			inputParams = {
				invocato : "T" se occorre invocare REST "rapporti", 
						   "F" se non occorre invocare REST "rapporti" 
							   e occorre raccogliere i dati rapporti da 
							   input binding (da inputParams.confs)
				tipologia : valorizzato da REST "recupera" in componente chiamante 
				numeroRapporto : valorizzato da REST "recupera" in componente chiamante
				confs : {  valorizzato da REST "rapporti" in componente chiamante quando invocato="F"
					rapporti: 
					listaBuoniSuConto: 
				}
			}

		*/
		templateUrl: '/rpol/private/pages/common-templates/slick-slider.template.html',
		controller: ['$scope', '$log', 'UtenteService', '$timeout', function SlickSliderController($scope, $log, UtenteService, $timeout) {

			var vm = this;

			//SlickSlider Var
			vm.confs = {};
			vm.confs.rapporti;
			vm.confs.listaBuoniSuConto;
			vm.confs.rapportiPresenzaBfp;
			vm.lastRapporto = false;

			vm.showSlickSlider = false;
			vm.labelHomePage;
			vm.slickConfig;

			//SlickSlider Funs
			vm.initSlider = initSlider;
			vm.newPage = newPage;
			vm.cryptNumeroRapporto = cryptNumeroRapporto;
			vm.$doCheck = doCheck;
			vm.attivaSelectedSlick = attivaSelectedSlick;

			var toOnBoardingLibApp = toOnBoardingLibApp;
			vm.manageClickOnBoxLib = manageClickOnBoxLib;


			function initSlider() {
				//INIZIO GESTIONE SLIDER

				vm.labelHomePage = "Home Risparmio Postale";

				vm.slickConfig = {
					dots: false,
					infinite: false,
					centerMode: false,
					//slidesToShow: 2,
					//slidesToScroll: 1,
					touchThreshold: 30,
					variableWidth: true,
					slidesToShow: 3,
					slidesToScroll: 3,
					responsive: [
						{
							breakpoint: 992,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 2
							}
						},
						{
							breakpoint: 768,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1
							}
						}
						// You can unslick at a given breakpoint now by adding:
						// settings: "unslick"
						// instead of a settings object
					],
					method: {}
				};

				//FINE GESTIONE SLIDER
			};


			function newPage(tipologia, numeroRapporto) {

				vm.inputParams.tipologia = tipologia;
				vm.inputParams.numeroRapporto = numeroRapporto;

				$log.log('SlickSliderController newPage tipologia :', tipologia);
				$log.log('SlickSliderController newPage numeroRapporto :', numeroRapporto);

				var key = "";
				var path = '/rpol/private/pages/index.html';
				var postDataInserisci = "";

				if (tipologia === 'libretto') {
					key = "numeroRapporto_libretti";
					storeDatakey(key, numeroRapporto, tipologia, path);
				}
				else if (tipologia === 'buonoLibretto') {
					key = "numeroRapporto_posizioneBuoni";
					storeDatakey(key, numeroRapporto, tipologia, path);
				}
				else if (tipologia === 'home') {
					key = "numeroRapporto_home";
					storeDatakey(key, numeroRapporto, tipologia, path);
				}
				else if (tipologia === 'buonoConto') {
					key = "numeroRapporto_conto";
					UtenteService.tipologiaConto({
						data: {
							numeroRapporto: numeroRapporto
						}
					},
						function (response) {
							$log.log('ManagerController  UtenteService.tipologiaConto response: ', response);

							if (response.data) {
								path = response.data.link;
							}

							$log.log('ManagerController  UtenteService.tipologiaConto path: ', path);
							/* redirectPage(tipologia, path); */
							/* path= 'https://bancopostaonline.poste.it/BPOL/bancoposta/Servizi/BuoniFruttiferi/BuoniFruttiferi.aspx'; */

							storeDatakey(key, numeroRapporto, tipologia, path);
						},
						function (httpResponse) {
							$log.log('ManagerController UtenteService.tipologiaConto error-response: ', httpResponse);
							/* redirectPage(tipologia, path); */
							/* path= 'https://bancopostaonline.poste.it/BPOL/bancoposta/Servizi/BuoniFruttiferi/BuoniFruttiferi.aspx'; */

							storeDatakey(key, numeroRapporto, tipologia, path);
						});
				} else if (tipologia === 'patrimonioBuoni') {
					key = "numeroRapporto_patrimonioBuoni";
					storeDatakey(key, numeroRapporto, tipologia, path);
				}else if (tipologia === 'librettoMinori') {
					
					redirectPage(tipologia, null, null);
				}


			};

			function storeDatakey(key, numeroRapporto, tipologia, path) {
				if (key) {
					postDataInserisci = JSON.parse('{ "data": { "mappaVariabiliTemp": { "' + key + '": "' + numeroRapporto + '", "tipologia": "' + tipologia + '"} } }');
				}

				$log.log('SlickSliderController newPage postDataInserisci :', postDataInserisci);


				UtenteService.inserisci(postDataInserisci,
					function (response) {
						$log.log('SlickSliderController newPage UtenteService.inserisci response: ', response);
						redirectPage(tipologia, numeroRapporto, path);
					},
					function (httpResponse) {
						$log.log('SlickSliderController newPage UtenteService.inserisci error-response: ', httpResponse);
						redirectPage(tipologia, numeroRapporto, path);
					});
			}

			function redirectPage(tipologia, numeroRapporto, path) {

				$log.log('SlickSliderController redirectPage tipologia :', tipologia);
				$log.log('SlickSliderController redirectPage numeroRapporto :', numeroRapporto);


				if (tipologia === 'home') {

					window.location = '/rpol/private/pages/index.html';
					document.getElementById("home").className += " active-element";
					return;
				} else if (tipologia === 'libretto') {

					window.location = '/rpol/private/pages/libretto.html';
					document.getElementById("LIBRETTO-" + numeroRapporto).className += " active-element";
					return;
				} else if (tipologia === 'buonoLibretto') {

					window.location = '/rpol/private/pages/posizione-buoni.html';
					document.getElementById("patrimonioBuoni").className += " active-element";
					/*document.getElementById("BUONO-" + numeroRapporto).className += " active-element";*/
					return;
				} else if (tipologia === 'buonoConto') {

					document.getElementById("BUONO-CONTO-" + numeroRapporto).className += " active-element";
					window.location = path;

					return;
				} else if (tipologia === 'patrimonioBuoni') {

					document.getElementById("patrimonioBuoni").className += " active-element";
					window.location = '/rpol/private/pages/patrimonio-buoni.html';

					return;
				}
				else if (tipologia === 'librettoMinori') {
					window.location = '/rpol/private/pages/libretto-minori.html';
					return;
				}
			};

			function attivaSelectedSlick(controllCondition, indice) {

				//$log.log('SlickSliderController attivaSelectedSlick indice',indice);

				if (controllCondition && vm.lastRapporto) {
					/*if(window.screen.width == vm.slickConfig.responsive.breakpoint)
						vm.slickConfig.method.slickGoTo(indice);
					else*/
					$log.log("SlickSlider index", indice);
					/*	if (vm.slickConfig)*/
					vm.slickConfig.method.slickGoTo(indice);
					
					/*	else {
							initSlider();
							vm.slickConfig.method.slickGoTo(indice);
						}*/
				}
				return controllCondition;

			};


			function doCheck() {


				if (vm.inputParams && vm.inputParams.invocato == "T") {

					$log.log('SlickSliderController doCheck vm.inputParams.invocato=' + vm.inputParams.invocato);

					UtenteService.rapporti(null,
						function (response) {

							$log.log('SlickSliderController UtenteService.rapporti response:', response);

							vm.confs.rapporti = response.data.listaRapporti;

							if (vm.confs.rapporti) {

								vm.confs.rapportiPresenzaBfp = [];
								for (var i = 0; i < vm.confs.rapporti.length; i++) {
									// Iterate over rapporti with presenzaBfp
									if (vm.confs.rapporti[i].presenzaBfp === "S")
										vm.confs.rapportiPresenzaBfp.push(vm.confs.rapporti[i]);

									vm.confs.rapporti[i].onBoardingFlag =
										(
											vm.confs.rapporti[i].statoInquiryRPOL != 'A' &&
											vm.confs.rapporti[i].tipoLibretto == 'N' &&
											(vm.confs.rapporti[i].formaLibretto == 'C' || vm.confs.rapporti[i].formaLibretto == 'D')
										) ? true : false;

								}
							}

							vm.confs.listaBuoniSuConto = response.data.listaBuoniSuConto;

							$log.log('SlickSliderController UtenteService.rapporti vm.confs', vm.confs);

							//INIZIO GESTIONE SLIDER
							initSlider();
							//FINE GESTIONE SLIDER

							vm.showSlickSlider = true;

						},
						function (httpResponseError) {
							$log.log('SlickSliderController UtenteService.rapporti httpResponseError:', httpResponseError);
							initSlider();
							vm.showSlickSlider = true;
						}
					);

					vm.inputParams.invocato = "";


				} else if (vm.inputParams && vm.inputParams.invocato == "F") {

					$log.log('SlickSliderController doCheck vm.inputParams.invocato=' + vm.inputParams.invocato);

					vm.confs.rapporti = vm.inputParams.confs.rapporti;

					vm.confs.rapportiPresenzaBfp = [];
					for (var i = 0; i < vm.confs.rapporti.length; i++) {
						// Iterate over rapporti with presenzaBfp
						if (vm.confs.rapporti[i].presenzaBfp === "S")
							vm.confs.rapportiPresenzaBfp.push(vm.confs.rapporti[i]);
					}

					vm.confs.listaBuoniSuConto = vm.inputParams.confs.listaBuoniSuConto;

					//INIZIO GESTIONE SLIDER
					initSlider();
					//FINE GESTIONE SLIDER

					vm.showSlickSlider = true;

					vm.inputParams.invocato = "";

					//gestione caso noprodotti
				} else if(vm.inputParams && vm.inputParams.invocato == "NP"){
						//INIZIO GESTIONE SLIDER
					initSlider();
					//FINE GESTIONE SLIDER

					vm.showSlickSlider = true;

					vm.inputParams.invocato = "";
				}


			};

			function toOnBoardingLibApp(numeroRapporto, formaLibretto) {

				var path = "/rpol/private/pages/on-boarding-lib.html";
				var request = JSON.parse('{ "data": { "mappaVariabiliTemp": { "numeroRapporto_onBoarding": "' + numeroRapporto + '","formaLibretto_onBoarding": "' + formaLibretto + '" } } }');
				$log.log('ManagerController toOnBoardingLibApp json :', request);


				UtenteService.inserisci(request,
					function (response) {
						$log.log('ManagerController  UtenteService.inserisci response: ', response);
						window.location = path;
					},
					function (httpResponse) {
						//GESTIONE DELL'ERRORE DELEGATA AL RECUPERA SU ON-BOARDING-LIB
						$log.log('ManagerController UtenteService.inserisci error-response: ', httpResponse);
						window.location = path;
					}
				);

			}

			function manageClickOnBoxLib(rapporto) {

				$log.log('ManagerController manageClickOnBoxLib rapporto', rapporto);

				if (rapporto.onBoardingFlag == true)
					toOnBoardingLibApp(rapporto.numeroRapporto, rapporto.formaLibretto);
				else
					vm.newPage('libretto', rapporto.numeroRapporto);

			}

			$scope.$on('lastRapportoRendered', function (event) {
				$log.log('Generato evento lastBuonoRendered');
				vm.lastRapporto = true;
			});


		}]
	}

);