angular.module('services.utente').factory(
		'UtenteService',
		[
				'$resource',
				'$filter',
				'AppConfs',
				function($resource, $filter, AppConfs) {

					var requestMilliseconds = new Date().getTime();
					var dt = $filter('date')(new Date(),
							'dd/MM/yyyy - HH:mm:ss');
					var nomeServizio = '/rpolUtenteService';

					return $resource(AppConfs.general.restServices.baseContext
							+ nomeServizio + '/:path/:action', {}, {
						conti : {
							method : 'GET',
							headers : {
								// Authorization:
								// BPOLService.authorizationHeaderVal,
								// Username: BPOLService.usernameHeaderVal,
								// Canale: BPOLService.canaleHeaderVal,
								// requestID :
								// 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								action : "conti"
							}
						/*
						 * , interceptor: { response: function (response) { var
						 * result = response.resource; result.$status =
						 * response.status; return result; } }
						 */
						},
						contiAttivi : {
							method : 'GET',
							headers : {
								// Authorization:
								// BPOLService.authorizationHeaderVal,
								// Username: BPOLService.usernameHeaderVal,
								// Canale: BPOLService.canaleHeaderVal,
								// requestID :
								// 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								action : "contiAttivi"
							}
						/*
						 * , interceptor: { response: function (response) { var
						 * result = response.resource; result.$status =
						 * response.status; return result; } }
						 */
						},
						datiUtente : {
							method : 'GET',
							headers : {
								// Authorization:
								// BPOLService.authorizationHeaderVal,
								// Username: BPOLService.usernameHeaderVal,
								// Canale: BPOLService.canaleHeaderVal,
								// requestID :
								// 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								action : "datiUtente"
							}
						/*
						 * , interceptor: { response: function (response) { var
						 * result = response.resource; result.$status =
						 * response.status; return result; } }
						 */
						},
						librettiSmart : {
							method : 'GET',
							headers : {
								// Authorization:
								// BPOLService.authorizationHeaderVal,
								// Username: BPOLService.usernameHeaderVal,
								// Canale: BPOLService.canaleHeaderVal,
								// requestID :
								// 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								action : "librettiSmart"
							}
						/*
						 * , interceptor: { response: function (response) { var
						 * result = response.resource; result.$status =
						 * response.status; return result; } }
						 */
						},
						librettiEstesa : {
							method : 'GET',
							headers : {
								// Authorization:
								// BPOLService.authorizationHeaderVal,
								// Username: BPOLService.usernameHeaderVal,
								// Canale: BPOLService.canaleHeaderVal,
								// requestID :
								// 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								action : "librettiEstesa"
							}
						/*
						 * , interceptor: { response: function (response) { var
						 * result = response.resource; result.$status =
						 * response.status; return result; } }
						 */
						},
						rapporti : {
							method : 'GET',
							headers : {
								// Authorization:
								// BPOLService.authorizationHeaderVal,
								// Username: BPOLService.usernameHeaderVal,
								// Canale: BPOLService.canaleHeaderVal,
								// requestID :
								// 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								action : "rapporti"
							}
						/*
						 * , interceptor: { response: function (response) { var
						 * result = response.resource; result.$status =
						 * response.status; return result; } }
						 */
						},
						listaPratiche : {
							method : 'GET',
							headers : {
								// Authorization:
								// BPOLService.authorizationHeaderVal,
								// Username: BPOLService.usernameHeaderVal,
								// Canale: BPOLService.canaleHeaderVal,
								// requestID :
								// 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								path : 'gestionePratiche',
								action : "listaPratiche"
							}
						/*
						 * , interceptor: { response: function (response) { var
						 * result = response.resource; result.$status =
						 * response.status; return result; } }
						 */
						},
						praticheSecurizzazioneLibretto: {
							method: 'GET',
							headers: {
								// Authorization:
								// BPOLService.authorizationHeaderVal,
								// Username: BPOLService.usernameHeaderVal,
								// Canale: BPOLService.canaleHeaderVal,
								// requestID :
								// 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
								requestID: 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp: dt
							},
							params: {
								path: 'gestionePratiche',
								action: "praticheSecurizzazioneLibretto"
							}
							/*
							 * , interceptor: { response: function (response) { var
							 * result = response.resource; result.$status =
							 * response.status; return result; } }
							 */
						},
						annullaPratica : {
							method : 'POST',
							headers : {
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								path : 'gestionePratiche',
								action : "annullaPratica"
							}
						},
						dettaglioPratica : {
							method : 'POST',
							headers : {
								// Authorization:
								// BPOLService.authorizationHeaderVal,
								// Username: BPOLService.usernameHeaderVal,
								// Canale: BPOLService.canaleHeaderVal,
								// requestID :
								// 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								path : 'gestionePratiche',
								action : "dettaglioPratica"
							}
						/*
						 * , interceptor: { response: function (response) { var
						 * result = response.resource; result.$status =
						 * response.status; return result; } }
						 */
						},
						getPdfContent : {
							method : 'GET',
							headers : {
								// Authorization:
								// BPOLService.authorizationHeaderVal,
								// Username: BPOLService.usernameHeaderVal,
								// Canale: BPOLService.canaleHeaderVal,
								// requestID :
								// 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								path : 'gestionePratiche',
								action : "getPdfContent"
							}
						},
						invioMail : {
							method : 'POST',
							headers : {
								// Authorization:
								// BPOLService.authorizationHeaderVal,
								// Username: BPOLService.usernameHeaderVal,
								// Canale: BPOLService.canaleHeaderVal,
								// requestID :
								// 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								path : 'gestionePratiche',
								action : "invioMail"
							}
						},
						recupera : {
							method : 'GET',
							headers : {
								// Authorization:
								// BPOLService.authorizationHeaderVal,
								// Username: BPOLService.usernameHeaderVal,
								// Canale: BPOLService.canaleHeaderVal,
								// requestID :
								// 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								path : 'variabiliTemp',
								action : "recupera"
							}
						/*
						 * , interceptor: { response: function (response) { var
						 * result = response.resource; result.$status =
						 * response.status; return result; } }
						 */
						},
						inserisci : {
							method : 'POST',
							headers : {
								// Authorization:
								// BPOLService.authorizationHeaderVal,
								// Username: BPOLService.usernameHeaderVal,
								// Canale: BPOLService.canaleHeaderVal,
								// requestID :
								// 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								path : 'variabiliTemp',
								action : "inserisci"
							}
						/*
						 * , interceptor: { response: function (response) { var
						 * result = response.resource; result.$status =
						 * response.status; return result; } }
						 */
						},
						postepay : {
							method : 'GET',
							headers : {
								// Authorization:
								// BPOLService.authorizationHeaderVal,
								// Username: BPOLService.usernameHeaderVal,
								// Canale: BPOLService.canaleHeaderVal,
								// requestID :
								// 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								action : "postepay"
							}
						/*
						 * , interceptor: { response: function (response) { var
						 * result = response.resource; result.$status =
						 * response.status; return result; } }
						 */
						},
						librettiIntestatario : {
							method : 'GET',
							headers : {
								// Authorization:
								// BPOLService.authorizationHeaderVal,
								// Username: BPOLService.usernameHeaderVal,
								// Canale: BPOLService.canaleHeaderVal,
								// requestID :
								// 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								action : "librettiIntestatario"
							}
						/*
						 * , interceptor: { response: function (response) { var
						 * result = response.resource; result.$status =
						 * response.status; return result; } }
						 */
						},
						librettiIntestatarioSaldo : {
							method : 'GET',
							headers : {
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								action : "librettiIntestatarioSaldo"
							}
						},
						librettiIntestatarioSaldoIntegrato : {
							method : 'GET',
							headers : {
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								path: "rapportiintegrati",
								action : "librettiIntestatarioSaldoIntegrato"
							}
						},
						contiintegrati : {
							method : 'GET',
							headers : {
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								path: "rapportiintegrati",
								action : "contiintegrati"
							}
						},
						tipologiaConto : {
							method : 'POST',
							headers : {
								// Authorization:
								// BPOLService.authorizationHeaderVal,
								// Username: BPOLService.usernameHeaderVal,
								// Canale: BPOLService.canaleHeaderVal,
								// requestID :
								// 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								path : 'contiAttivi',
								action : "tipologiaConto"
							}
						/*
						 * , interceptor: { response: function (response) { var
						 * result = response.resource; result.$status =
						 * response.status; return result; } }
						 */
						},
						titolareEffettivo : {
							method : 'POST',
							headers : {
								// Authorization:
								// BPOLService.authorizationHeaderVal,
								// Username: BPOLService.usernameHeaderVal,
								// Canale: BPOLService.canaleHeaderVal,
								// requestID :
								// 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								action : "titolareEffettivo"
							}
						/*
						 * , interceptor: { response: function (response) { var
						 * result = response.resource; result.$status =
						 * response.status; return result; } }
						 */
						},
						contiIntegrati : {
							method : 'GET',
							headers : {
								// Authorization:
								// BPOLService.authorizationHeaderVal,
								// Username: BPOLService.usernameHeaderVal,
								// Canale: BPOLService.canaleHeaderVal,
								// requestID :
								// 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								path : "rapportiintegrati",
								action : "contiintegrati"
							}
						/*
						 * , interceptor: { response: function (response) { var
						 * result = response.resource; result.$status =
						 * response.status; return result; } }
						 */
						},
						estesaIntegrati : {
							method : 'GET',
							headers : {
								// Authorization:
								// BPOLService.authorizationHeaderVal,
								// Username: BPOLService.usernameHeaderVal,
								// Canale: BPOLService.canaleHeaderVal,
								// requestID :
								// 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								path : "rapportiintegrati",
								action : "estesaintegrati"
							}
						/*
						 * , interceptor: { response: function (response) { var
						 * result = response.resource; result.$status =
						 * response.status; return result; } }
						 */
						},
						smartIntegrati : {
							method : 'GET',
							headers : {
								// Authorization:
								// BPOLService.authorizationHeaderVal,
								// Username: BPOLService.usernameHeaderVal,
								// Canale: BPOLService.canaleHeaderVal,
								// requestID :
								// 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
								requestID : 'RPOL.WEB.' + requestMilliseconds,
								requestTimestamp : dt
							},
							params : {
								path : "rapportiintegrati",
								action : "smartintegrati"
							}
						/*
						 * , interceptor: { response: function (response) { var
						 * result = response.resource; result.$status =
						 * response.status; return result; } }
						 */
						}
					});
				} ]);