angular.module('filters.mascheraCellulare', [])
.filter('mascheracellulare',function(){
    return function(input,numDigit){
      var out='';
      
      
      if(input){
        var d = numDigit ? numDigit : 3;
      var numAsterischi = input.length - d;
        for(z=0;z<numAsterischi;z++)out=out+'*';
        out=out+input.slice(-d);
      }
      return out;
    }
  });