angular.module('filters.unique').filter('unique', function () {
    return function (inputList, parameters) {
        if (!parameters) {
            var result = [];

            if (inputList !== undefined && inputList !== '' && inputList.length !== 0) {
                angular.forEach(inputList, function (input) {
                    if (result.indexOf(input.descrizione) === -1) {
                        result.push(input.descrizione);
                    }
                });

            }
            return result;
        }
        else {
            //restituisce una lista di oggetti univoci per caratteristiche
            //l'oggetto corrente viene confrontato con quelli presenti nella lista temp
            //non appena uno dei parametri di confronto è diverso, l'oggetto è inserito nella lista di risultati
            var result = [];
            if (inputList !== undefined && inputList !== '' && inputList.length !== 0) {
                //per ogni oggetto della lista
          
               
               angular.forEach(inputList, function (input) {
                    if (!isPresent(input, parameters, result)) {
                        result.push(input);
                    }
              
                });
                return result;

            }

        }
    }
});

//lista di oggetti da restituire, verifico che già non esista l'oggetto con quelle caratteristiche
//obj: oggetto da inserire nella lista
//parameters: array dei campi degli oggetti da confrontare
//list: lista di oggetti univoci su cui effettuare il confronto
function isPresent(obj, parameters, list) {
    var present = false;
    for (listObj in list) {
        var tempObj = list[listObj];
        for (parameter in parameters) {
            var tempPar = parameters[parameter];
            if (obj[tempPar] !== tempObj[tempPar]) {
                present = false;
                break;
            }
            else {
                present = true;
            }
        }
        if (present) {
            break;
        }
    }
return present;

}
