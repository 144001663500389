angular.module('services.snodo').factory('SnodoService',
	['$resource', '$filter', 'AppConfs',
		function ($resource, $filter, AppConfs) {

			var requestMilliseconds = new Date().getTime();
			var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
			var nomeServizio = '/rpolFunnelAcquistoLibrettoBfpOss';

			return $resource(
				AppConfs.general.restServices.baseContext + nomeServizio + '/:prefix/:package/:action'+'/:id', {id:'@id'}, {
					aggiornaDatiBuono: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "funnelAcquistoE2E",
							action: "aggiornaDatiBuono"
						}
					},
					getInfoSnodo: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: 'inizializzazioneFunnel',
							action: "getInfoSnodo"
						}
					},
					askForSca: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: 'utenteE2E',
							action: "askForSca"
						}
					},
					inserisci: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: 'variabiliTempE2E',
							action: "inserisci"
						}
					},
					recupera: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "variabiliTempE2E",
							action: "recupera"
						}
					},
					richiediOtp: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: 'otpE2E',
							action: "send"
						}
					},
					otpVerify: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: 'otpE2E',
							action: "verify"
						}
					},
					invioMail: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: 'gestionePraticheE2E',
							invioMail: "invioMail"
						}
					},
					stepContoBancario: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: 'funnelAcquistoE2E',
							action: "stepContoBancario"
						}
					},
					stepSelezioneProdotto: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: 'funnelAcquistoE2E',
							action: "stepSelezioneProdotto"
						}
					},
					stepSelezioneDocumento: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: 'funnelAcquistoE2E',
							action: "stepSelezioneDocumento"
						}
					},
					stepSezioneArricchimento: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: 'funnelAcquistoE2E',
							action: "stepSezioneArricchimento"
						}
					},
					stepDatiAnagrafici: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "funnelAcquistoE2E",
							action: "stepDatiAnagrafici"
						}
					},
					getData: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "funnelAcquistoE2E",
							action: "getData"
						}
					},
					accessoFunnel: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "inizializzazioneFunnel",
							action: "accessoFunnel"
						}
					},
					normalizza: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "normalizzaIndirizzo",
							action: "normalizza"
						}
					},
					inviaDatiLibretto: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "funnelAcquistoE2E",
							action: "stepDatiLibretto"
						}
					},
					calcolaBfpSenior: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "funnelAcquistoE2E",
							action: "calcolaBfpSenior"
						}
					},
					listaComuni: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "listaLuoghi",
							action: "listaComuni"
						}
					},
					listaProvince: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "listaLuoghi",
							action: "listaProvince"
						}
					},
					listaNazioni: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "listaLuoghi",
							action: "listaNazioni"
						}
					},
					getComuniProvince: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "listaLuoghi",
							action: "getComuniProvince"
						}
					},
					riceviDatiDocumenti: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "funnelAcquistoE2E",
							action: "entiRilascio"
						}
					},
					prodottiEmettibili: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "emissione",
							action: "prodottiEmettibiliBfpFunnel"
						}
					},
					offerteSmart: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "emissione",
							action: "offerteSmartEmettibili"
						}
					},
					datiArricchimento: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "emissione",
							action: "getDatiArricchimento"
						}
					},
					verifica: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "emissione",
							action: "verifica"
						}
					},
					emissione: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "emissione",
							action: "emissione"
						}
					},
					qavGetAll: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "qav",
							action: "getAll"
						}
					},
					uploadSelfie: {
						method: 'POST',
						headers: {
							'Content-Type': undefined,
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "funnelAcquistoE2E",
							action: "uploadSelfie"
						}
					},
					uploadVideo: {
						method: 'POST',
						headers: {
							'Content-Type': undefined,
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "funnelAcquistoE2E",
							action: "uploadVideo"
						}
					},
					qavInvia: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "qav",
							action: "invia"
						}
					},
					verificaAdesioneFirma: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							prefix: "funnelAcquistoE2E",
							package: "fea",
							action: "verificaAdesioneFirma"
						}
					},
					concludiAdesione: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							prefix: "funnelAcquistoE2E",
							package: "fea",
							action: "concludiAdesione"
						}
					},
					concludiFirma: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							prefix: "funnelAcquistoE2E",
							package: "fea",
							action: "concludiFirma"
						}
					},
					downloadPDF: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "emissione",
							action: "getDocumentE2E"
						}
					}
				});
		}]);
