angular.module('tabellaListaprintPatrimonioBuoni').component('tabellaListaprintPatrimonioBuoni', {
    bindings: {
		inputParams: '<'
    },
    templateUrl: '/rpol/private/pages/common-templates/tabella-listaprint-patrimonio-buoni.template.html',
    controller: ['$log', 'UikitProxy', 'WebtrendsService', '$timeout',
        function TabellaListaprintPatrimonioBuoniController($log, UikitProxy, WebtrendsService, $timeout) {

            var vm = this;
            var entrato = false;
			//TabellaGenericaBodyPatrimonioBuoni vars
			vm.confs = {};
            
			//TabellaGenericaBodyPatrimonioBuoni funcs
			vm.$doCheck = doCheck;

			function doCheck() {
				//$log.log('TabellaListaprintPatrimonioBuoniController vm.inputParams.confs:', vm.inputParams);

				
				if (vm.inputParams && entrato == false) {
					//$log.log('TabellaListaprintPatrimonioBuoniController inputParams', vm.inputParams);
					
					if(vm.inputParams.listaCompletaBuoni && vm.inputParams.listaCompletaBuoni.length>0){
						vm.confs.listaMovimenti = vm.inputParams.listaCompletaBuoni;
						entrato = true;
						
						// $timeout(function () {
						//   $('#listaMovPrint').tableExport({type:'init',escape:'false',nomeFileOutput:'RPOL_PortafoglioBuoni'});
						// });
				
						//$log.log('TabellaListaprintPatrimonioBuoniController doCheck vm.confs CONTROLLO=', vm.confs);
						//$log.log('TabellaListaprintPatrimonioBuoniController doCheck vm.inputParams.invocato=' , vm.inputParams.listaCompletaBuoni);
						
						//vm.inputParams = undefined;
					}

				} else {

					//$log.log('TabellaListaprintPatrimonioBuoniController doCheck ');

				}


			};
			
			

        }]
}
);