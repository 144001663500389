angular.module('services.onBoardingLib').factory('OnBoardingLibService',
	['$resource', '$filter', 'AppConfs',
		function ($resource, $filter, AppConfs) {

			var requestMilliseconds = new Date().getTime();
			var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
			var nomeServizio = '/rpolOnBoardingLibretti';

			return $resource(
				AppConfs.general.restServices.baseContext + nomeServizio + '/:formaLibretto/:action',
				{}, {
					verificaCartaceo: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
                            action: "verifica",
                            formaLibretto : "cartaceo"
						}
					},
					eseguiCartaceo: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							action: "esegui",
                            formaLibretto : "cartaceo"
						}
                    },
                    annullaCartaceo: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							action: "annulla",
                            formaLibretto : "cartaceo"
						}
                    },
                    verificaDemat: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
                            action: "verifica",
                            formaLibretto : "demat"
						}
					},
					eseguiDemat: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							action: "esegui",
                            formaLibretto : "demat"
						}
                    },
                    annullaDemat: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							action: "annulla",
                            formaLibretto : "demat"
						}
					}
				});
		}]);
