angular.module('services.associaLibretto').factory('AssociaLibrettoService', 
['$resource', '$filter', 'AppConfs',
		function ($resource, $filter, AppConfs) {

			var requestMilliseconds = new Date().getTime();
			var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
			var nomeServizio = '/rpolCertificaAssociaNumero';

			return $resource(
				AppConfs.general.restServices.baseContext + nomeServizio + '/:path/:action',
				{}, {
				verifica: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
                        path: "associa",
						action: "verifica"
					}

				},
				getStrumenti: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						path: "securizzazioneConto",
						action: "getStrumenti"
					}
				},
				richiedi: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						path: "securizzazioneConto",
						action: "richiedi"
					}
				},
				richiediOtp: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						path: "associa",
						action: "richiediOtp"
					}
				},
				esegui: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						path: "associa",
						action: "esegui"
					}
				},
				certifica: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						path: "associa",
						action: "certifica"
					}
				},
				annulla: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						path: "associa",
						action: "annulla"
					}
				}
			});
		}]);