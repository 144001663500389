angular.module('services.offertaDistanza').factory('offertaDistanzaService',
	['$resource', '$filter', 'AppConfs',
		function ($resource, $filter, AppConfs) {

			var requestMilliseconds = new Date().getTime();
			var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
			var nomeServizio = '/rpolGestioneOADService';

			return $resource(
				AppConfs.general.restServices.baseContext + nomeServizio + '/:prefix/:action',
				{}, {
					infoOAD: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {							
							action: "info"
						}
					},
					proposteRicevute: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							prefix: "proposte",
							action: "ricevute"
						}
					},
					proposteArchiviate: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							prefix: "proposte",
							action: "archiviate"
						}
					},
					rifiutaProposta: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							prefix: "proposte",
							action: "rifiuta"
						}
					},
					recuperaConsensi: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							prefix: "consensi",
							action: "all"
						}
					},
					salvaConsensi: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							prefix: "consensi",
							action: "modifica"
						}
					}
				});
		}]);
