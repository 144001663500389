var common_path = '/rpol/public/views/common-templates';
var template = common_path + '/foto-riconoscimento.template.html';
angular.module('fotoRiconoscimento').component('fotoRiconoscimento', {
	bindings : {
		errorsFn : '&',
		captureDone : '&',
		startCapture : '&'
	},
	require : {
		parentCtrl : '^^manager'
	},
	templateUrl : template,
	controller : [ FotoRiconoscimentoController ]
});

/**
 * CONTROLLER
 */
function FotoRiconoscimentoController() {

	var _this = this;

	/* camera & canvas */
	_this.photoGUM = null;
	_this.photoCanvas = null;
	_this.photoCanvasCtx = null;
	_this.photoCanvasPreview = null;
	_this.photoCanvasPreviewMob = null;

	/**
	 * on INIT
	 */
	_this.$onInit = function() {
		/* get DOM elements and reset */
		_this.photoGUM = document.getElementById('photoGUM');
		_this.photoCanvas = document.getElementById('photoCanvas');
		_this.photoCanvasCtx = _this.photoCanvas.getContext("2d");
		_this.photoCanvasPreview = document.getElementById('photoCanvasPreview');
		_this.photoCanvasPreviewMob = document.getElementById('photoCanvasPreviewMob');
		_this.reset();
	};

	/**
	 * RESET all form buttons and canvas/placeholder
	 */
	_this.reset = function() {
		/* SHOW/HIDE ELEMENTS */
		_this.show_placeholder = true;
		_this.show_gum = false;
		_this.show_canvas = false;
		_this.show_start_camera = true;
		_this.show_take_picture = false;
		_this.show_upload_photo = false;
		_this.show_take_again = false;
		_this.photoCanvasCtx.clearRect(0, 0, _this.photoCanvas.width,
				_this.photoCanvas.height);
	};

	/**
	 * START CAMERA
	 */
	_this.startCamera = function() {

		/* SHOW/HIDE ELEMENTS */
		_this.show_placeholder = false;
		_this.show_gum = true;
		_this.show_start_camera = false;
		_this.show_take_picture = true;

		/* start the camera */
		navigator.mediaDevices.getUserMedia({
			audio : {
				echoCancellation : {
					exact : true
				}
			},
			video : {
				width : 1280,
				height : 720
			}
		}).then(function(stream) {
			/* set stream and canvas width & height */
			window.stream = stream;
			_this.photoGUM.srcObject = stream;
			_this.photoGUM.addEventListener("canplay", function() {
				_this.photoCanvas.width = _this.photoGUM.videoWidth;
				_this.photoCanvas.height = _this.photoGUM.videoHeight;
			});
		}).catch(function(e) {
			/* reset and send error back (-1: camera error) */
			_this.stopCamera();
			_this.reset();
			if (_this.errorsFn) {
				_this.errorsFn({
					error : -1
				});
			}
		});

	};

	/**
	 * TAKE A PICTURE!
	 */
	_this.takePicture = function() {

		/* SHOW/HIDE ELEMENTS */
		_this.show_gum = false;
		_this.show_canvas = true;
		_this.show_upload_photo = true;
		_this.show_take_again = true;
		_this.show_take_picture = false;

		/* get a picture from camera and put it into canvas */
		try {
			_this.photoCanvasCtx.drawImage(_this.photoGUM, 0, 0,
					_this.photoCanvas.width, _this.photoCanvas.height);
			_this.photoCanvasPreview.src = _this.photoCanvas.toDataURL();
			_this.photoCanvasPreviewMob.src = _this.photoCanvas.toDataURL();
			
			if (_this.startCapture) {
				_this.startCapture();
			}
			
		} catch (e) {
			/* reset and send error back (-2: picture not taken) */
			_this.reset();
			if (_this.errorsFn) {
				_this.errorsFn({
					error : -2
				});
			}
		}

		_this.stopCamera();
		
	};

	/**
	 * STOP THE CAMERA
	 */
	_this.stopCamera = function() {
		/* stop the camera */
		if (window.stream) {
			window.stream.getTracks().forEach(function(track) {
				track.stop();
			});
			window.stream = null;
			_this.photoGUM.srcObject = null;
		}
	}
	
	/**
	 * UPLOAD PICTURE
	 */
	_this.uploadPhoto = function() {

		/* SHOW/HIDE ELEMENTS */
		_this.show_take_picture = false;
		_this.show_take_again = false;
		_this.show_upload_photo = false;

		// upload
		try {
			
			if (!HTMLCanvasElement.prototype.toBlob) {
				Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
					value: function (callback, type, quality) {
						var canvas = this;
						setTimeout(function() {
							var binStr = atob(canvas.toDataURL(type, quality).split(',')[1]),
							len = binStr.length,
							arr = new Uint8Array(len);
							for (var i = 0; i < len; i++ ) {
								arr[i] = binStr.charCodeAt(i);
							}
							callback( new Blob( [arr], {type: type || 'image/jpeg'} ) );
						});
					}
			   });
			}
			
			_this.photoCanvas.toBlob(function(file) {
				/* callback on done */
				if (_this.captureDone) {
					_this.captureDone({
						success : file
					});
				}
			}, "image/jpeg");
			
		} catch (e) {
			/* reset and send error back (-3: conversion error) */
			_this.stopCamera();
			_this.reset();
			if (_this.errorsFn) {
				_this.errorsFn({
					error : -3
				});
			}
		}

	};

	/**
	 * TAKE AGAIN...
	 */
	_this.takeAgain = function() {
		/* reset and re-start camera */
		_this.reset();
		_this.startCamera();
	};

}