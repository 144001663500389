angular.module('filters.mappaFormaEmissione').filter('MappaFormaEmissione', function () {
  return function (tipologia) {
  
		var FormaEmissione= "";
		
		
	  	switch (tipologia) {
			case "D":
				FormaEmissione= "Dematerializzato";
				break;
			case "C":
				FormaEmissione= "Cartaceo";
				break;
			default:
				FormaEmissione= "";
				break;
		} 
		return FormaEmissione;
  };
});