angular.module('services.simulatore').factory('SimulatoreService',
	['$resource', '$filter', 'AppConfs',
		function ($resource, $filter, AppConfs) {

			var requestMilliseconds = new Date().getTime();
			var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
			var nomeServizio = '/rpolSimulatore';
			
			return $resource(
				AppConfs.general.restServices.baseContext + nomeServizio + '/:path/:subpath/:action',
				{}, {
					listaProdotti: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							path: 'calcolatore',
							subpath: 'bfp',
							action: "listaProdotti"
						}
					},
					calcola: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							path: 'calcolatore',
							subpath: 'bfp',
							action: "calcola"
						}
					},
					simula: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							path: 'simulatore',
							subpath: 'accantonamento',
							action: "simula"
						}
					}
				});
		}]);
