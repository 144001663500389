var common_path = '/rpol/public/views/common-templates';
var template = common_path + '/video-riconoscimento.template.html';
angular.module('videoRiconoscimento').component('videoRiconoscimento', {
	bindings : {
		errorsFn : '&',
		captureDone : '&',
		startCapture : '&',
		usePrediction : '<',
		length: "<",
		width: "<",
		height: "<"
	},
	require : {
		parentCtrl : '^^manager'
	},
	templateUrl : template,
	controller : [ '$timeout', VideoRiconoscimentoController ]
});

/**
 * video riconoscimento controller
 */
function VideoRiconoscimentoController($timeout) {

	var _this = this;
	var predictionServiceURL = "http://localhost:8081/jod-webrtc/prediction";

	/* web-cam & canvas */
	_this.videoGUM = null;
	_this.videoFrame = null;
	_this.videoGrab = null;

	/* recordedBlobs, media recorder, media source */
	var stop = false;
	var recordedBlobs = [];
	var recordedFile = null;
	var mediaRecorder = null;
	var sourceBuffer = null;
	var mediaSource = new MediaSource();
	mediaSource.addEventListener('sourceopen', function() {
		sourceBuffer = mediaSource.addSourceBuffer('video/webm; codecs="vp8"')
	}, false);

	/**
	 * on INIT
	 */
	_this.$onInit = function() {
		/* get DOM elements and reset */
		_this.videoGUM = document.getElementById('videoGUM');
		_this.videoFrame = document.getElementById('videoFrame');
		_this.videoGrab = document.getElementById('videoGrab');
		_this.videoGrabCtx = _this.videoGrab.getContext("2d");
		_this.rectangles = document.getElementById('rectangles');
		_this.rectanglesCtx = rectangles.getContext("2d");
		_this.reset();
	};

	/**
	 * RESET all form buttons and canvas/placeholder
	 */
	_this.reset = function() {
		/* SHOW/HIDE ELEMENTS */
		_this.show_placeholder = true;
		_this.show_gum = false;
		_this.show_frame = false;
		_this.show_canvas = false;
		_this.show_start_video = true;
		_this.show_stop_video = false;
		_this.show_record_video = false;
		_this.show_upload_video = false;
		_this.show_take_again = false;
	};

	/**
	 * START CAMERA
	 */
	_this.startCamera = function() {

		/* SHOW/HIDE ELEMENTS */
		_this.show_placeholder = false;
		_this.show_gum = true;
		_this.show_start_video = false;
		_this.show_record_video = true;
		_this.larghezzaVideo = _this.width ? Number(_this.width) : 800;
		_this.altezzaVideo = _this.height ? Number(_this.height) : 600;
		
		/* start the camera */
		navigator.mediaDevices.getUserMedia({
			audio : {
				echoCancellation : {
					exact : true
				}
			},
			video : {
				width : _this.larghezzaVideo,
				height : _this.altezzaVideo,
				// facingMod: "user",
				// frameRate: { ideal: 60, min: 25 }
			}
		}).then(function(stream) {
			/* set stream and canvas width & height */
			window.stream = stream;
			_this.videoGUM.srcObject = stream;
			_this.videoGUM.addEventListener("canplay", function() {
				_this.videoGrab.width = _this.videoGUM.videoWidth;
				_this.videoGrab.height = _this.videoGUM.videoHeight;
			});
		}).catch(function(e) {
			/* reset and send error back (-1: camera error) */
			_this.stopVideo();
			_this.reset();
			if (_this.errorsFn) {
				_this.errorsFn({
					error : -1
				});
			}
		});

	};

	/**
	 * RECORD VIDEO
	 */
	_this.recordVideo = function() {

		/* SHOW/HIDE ELEMENTS */
		_this.show_record_video = false;
		_this.show_stop_video = true;

		/* start recording */
		try {
			recordedBlobs = [];
			var options = {
				mimeType : 'video/webm;codecs=vp9'
			};
			if (!MediaRecorder.isTypeSupported(options.mimeType)) {
				options = {
					mimeType : 'video/webm'
				};
				if (!MediaRecorder.isTypeSupported(options.mimeType)) {
					options = {
						mimeType : ''
					};
				}
			}
			mediaRecorder = new MediaRecorder(window.stream, options);
			mediaRecorder.ondataavailable = function(event) {
				if (event.data && event.data.size > 0) {
					recordedBlobs.push(event.data);
					_this.createPreview();
				}
			};
			mediaRecorder.start();
			stop = false;
			$timeout(_this.stopVideo, _this.durataVideo ? Number(_this.length) : 6000);
			if (_this.usePrediction) {
				$timeout(_this.grabFrame);
			}
			
			if (_this.startCapture) {
				_this.startCapture();
			}
			
		} catch (e) {
			/* reset and send error back (-2: record error) */
			_this.stopVideo();
			_this.reset();
			if (_this.errorsFn) {
				_this.errorsFn({
					error : -2
				});
			}
		}

	};

	/**
	 * GRAB FRAME
	 */
	_this.grabFrame = function() {
		
		if (!HTMLCanvasElement.prototype.toBlob) {
			Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
				value: function (callback, type, quality) {
					var canvas = this;
					setTimeout(function() {
						var binStr = atob(canvas.toDataURL(type, quality).split(',')[1]),
						len = binStr.length,
						arr = new Uint8Array(len);
						for (var i = 0; i < len; i++ ) {
							arr[i] = binStr.charCodeAt(i);
						}
						callback( new Blob( [arr], {type: type || 'image/jpeg'} ) );
					});
				}
		   });
		}
		
		_this.videoGrabCtx.drawImage(_this.videoGUM, 0, 0,
				_this.videoGrab.width, _this.videoGrab.height);
		_this.videoGrab.toBlob(function(imageData) {
			var postImageReq = new XMLHttpRequest();
			postImageReq.open("POST", predictionServiceURL, true);
			postImageReq.responseType = "json";

			postImageReq.onload = function(event) {
				var faces = postImageReq.response;

				_this.rectanglesCtx.clearRect(0, 0, _this.rectangles.width,
						_this.rectangles.height);

			     for (var i=0; i < faces.length; i++) {
			    	 _this.rectanglesCtx.strokeStyle = "white";
			    	 _this.rectanglesCtx.lineWidth = 2;
			    	 _this.rectanglesCtx.strokeRect(faces[i].x, faces[i].y,
			    			 faces[i].width, faces[i].height);
			     }
         
				if (!stop){
					$timeout(_this.grabFrame);
				}
			};

			postImageReq.send(imageData);
		}, "image/jpeg", 70);

	};


	/**
	 * STOP VIDEO
	 */
	_this.stopVideo = function() {

		/* stop recording */
		if (!stop && mediaRecorder) {

			mediaRecorder.stop();
			stop = true;
			if (window.stream) {
				window.stream.getTracks().forEach(function(track) {
					track.stop();
				});
				window.stream = null;
				_this.videoGUM.srcObject = null;
			}

			/* SHOW/HIDE ELEMENTS */
			_this.show_stop_video = false;
			_this.show_upload_video = true;
			_this.show_take_again = true;
			_this.show_gum = false;
			_this.show_frame = true;

		}
		
	};
	
	/**
	 * CREATE PREVIEW
	 */
	_this.createPreview = function() {

		/* video preview */
		try {
			/* create blob and show the preview */
			recordedFile = new Blob(recordedBlobs, {type: 'video/webm'});
			_this.videoFrame.srcObject = null;
			_this.videoFrame.src = URL.createObjectURL(recordedFile);
			_this.videoFrame.controls = true;
			_this.videoFrame.load();
		} catch (e) {
			/* reset and send error back (-3: conversion error) */
			_this.stopVideo();
			_this.reset();
			if (_this.errorsFn) {
				_this.errorsFn({
					error : -3
				});
			}
		}

	};

	/**
	 * UPLOAD!
	 */
	_this.uploadVideo = function() {

		/* SHOW/HIDE ELEMENTS */
		_this.show_take_again = false;
		_this.show_upload_video = false;

		/* callback on done */
		if (_this.captureDone) {
			_this.captureDone({
				success : recordedFile
			});
		}

	};

	/**
	 * TAKE AGAIN...
	 */
	_this.takeAgain = function() {
		/* reset and re-start camera */
		_this.reset()
		_this.startCamera();
	};

}