(function() {
    'use strict';

    angular
        .module('services.securizzazioneSca')
        .factory('SecurizzazioneScaService', ['$resource', '$filter', 'AppConfs', SecurizzazioneScaService]);

    function SecurizzazioneScaService($resource, $filter, AppConfs) {

        var requestMilliseconds = new Date().getTime();
        var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');

        var nomeServizio = '/rpolSecurizzazioneSCAService';

        return $resource(
            AppConfs.general.restServices.baseContext + nomeServizio + '/:path/:action', {}, {
                prepareData: {
                    method: 'POST',
                    headers: {
                        requestID: 'RPOL.WEB.' + requestMilliseconds,
                        requestTimestamp: dt
                    },
                    params: {
                        path:'securizzazioneRpolSca',
                        action: "prepareData"
                    }
                },
                validateData: {
                    method: 'POST',
                    headers: {
                        requestID: 'RPOL.WEB.' + requestMilliseconds,
                        requestTimestamp: dt
                    },
                    params: {
                        action: "validateData"
                    }
                }
            });
    }
})();