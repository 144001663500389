(function () {

  'use strict';

  angular
    .module('authenticationIdp')

    .component('authenticationIdp', {
      templateUrl: '/rpol/private/pages/common-templates/authentication-idp.template.html',
      controller: ['$location', '$log', '$sce', '$timeout', '$window', '$rootScope', '$http', '$scope', 'AppConfs', 'SessionIdpService', 'AuthenticationIdpService', 'ScaUtilsService', 
        AuthenticationIdpController],
      bindings: {
        confs: '<',
        onError: '&',
        onSuccess: '&',
        onBack: '&'

      }
    });

  function AuthenticationIdpController($location, $log, $sce, $timeout, $window, $rootScope, $http, $scope, AppConfs, SessionIdpService, AuthenticationIdpService, ScaUtilsService) {

    var vm = this;
    vm.confs = {};
    vm.confs.showError = false;
    vm.error_Confs = {};

    //intercetto l'evento lanciato dall'interceptor per la gestione dell'autenticazione utente
    // in questo blocco faccio la request verso il BE per la createrequest IDP e faccio la submit del form
    $rootScope.$on('needAuth', function (params) {
      show_CustomUIKit_PageLoader();
      if (!$rootScope.needAuth) {
        $rootScope.needAuth = true;



        // gestione mantenimento parametro areaPremium
        //1- verifica parametro in qeuery string
        var qs = decodeURIComponent(window.location.search.substr(1));
        qs = (qs && qs.length>0) ? qs.replace(/ /g,'+') : "";


        //estrazione eventuali coppie parametro/valore
        var parmsArr = qs.split("&");

        var fltParArr = parmsArr.filter(function(pp) {
          
          //creo array parametro,valore splittando "="
          var pA = pp.split("=");
          
          
          // se la posizione 2 dell'array ha una lunghezza maggiore di zero
          // allora è un valore di un parametro nominale(nome=valore) quindi lo escludo
          if (pA[1] && pA[1].length>0)
            return false;
          
          // gestione di eventuali parametri mandati vuoti
          // se arriva parametro= senza valore, potrebbe essere confuso con un b64
          // verifico in primis se il nome può essere decodificato (mi esclude ad esempio i nomi tipo "wt.ac" che contiene il punto e non è valido b64)
          // se passa il check di decodifica, controllo che sia lungo almeno 90 (il json, encodato e codificato, dovrebbe essere di almeno 128 caratteri: caso limite json con solo prop senza valori)
          try {           
            return window.atob(pA[0]) &&  window.atob(pA[0]).length>90;
          }
          catch(e) {                
            return false;
          }                   
        });

        var multiParam = (fltParArr) ? fltParArr.length : 0;        


        var apTokenValue = "";         
        if (qs && qs!="") {

          // ho solo il token Area Premium
          if (multiParam==1) {
            apTokenValue = fltParArr[0];          
          }
          //ho anche altri parametri
          // //altriimenti non faccio niente e scrivo errore in console.
          // else {
          //  console.log("AREA PREMIUM: errore parametri in Query String");
          // }
        }

        SessionIdpService.createAuthRequest(
          // SessionIdpService.createAuthRequest(
          {
            data:
            {
              idFe: AppConfs.scaConfs.scaApp
            }
          },
          //popolo l'hidden form e faccio la submit verso IDP
          function (response) {
            vm.confs.action = $sce.trustAsResourceUrl(response.data.endpoint);
            vm.confs.request = response.data.payload;
            vm.confs.response_type = "code";
            vm.confs.state = AppConfs.scaConfs.scaApp;
            /*  vm.confs.binding = "http-post"; */
            /*   vm.confs.code_challenge_method = response.data.codeChallengeMethod;
              vm.confs.code_challenge = response.data.codeChallenge; */


            /*Se ho il parametro di AREA PREMIUM, FACCIO PRIMA UN INSERISCI*/


            if (apTokenValue && apTokenValue!="") {

            var sessionStorageRequest = { "data": { "mappaVariabiliTemp": { "apToken": apTokenValue } } };            

          

            sessionStorage.setItem("apVarTmp",  btoa(JSON.stringify(sessionStorageRequest)));
            $timeout(function () {
                    document.getElementById('idpForm').submit();
                  }, 0);

          }
          else
          {
            $timeout(function () {
              document.getElementById('idpForm').submit();
            }, 0);
          }


          },
          function (httpResponse) {
            $window.location.href = "/rpol/private/pages/errore.html";
            forceHide_CustomUIKit_PageLoader(true);
            //   show_CustomUIKit_PageLoader();
            /* var params = {};
            params.error = {}
            params.error.httpResponse = httpResponse;
            $rootScope.$broadcast('errorAuthEvent', params);
            hide_CustomUIKit_PageLoader(); */


            /*  $rootScope.$broadcast('needAuth', httpResponse);
             $rootScope.showError = true;
             vm.confs.showError=true;
             var errorResponseMessage = getErrorResponseMessage(httpResponse);
             configureEsitoPage('autenticazione', 'exception0', errorResponseMessage); */
            //   hide_CustomUIKit_PageLoader();
          }
        )
      }
    });




    //flusso di chiamate per completare l'autenticazione verso IDP
    /*  1) chiamo createTokenRequest per la generazione della request verso openid-token
        2) chiamo openid-token per i claims utente
        3) chiamo retrieveInformationIdP con i claims utente
      */
    // $rootScope.$on('startAuth', function (event, params) {

    vm.$onInit = function () {

      var url = window.location.search.substring(1);
      var json = ScaUtilsService.checkAuthFlow(url);
      //sono nel flusso di autenticazione idp

      if (json && json.error && json.error === 'wrongAuth') {
        $window.location.href = "/rpol/private/pages/errore.html";
        forceHide_CustomUIKit_PageLoader(true);
      }
      else if (json && json.movementId) {
        show_CustomUIKit_PageLoader();
        $rootScope.needAuth = true;
        $rootScope.showGenericErrorPage = false;
        // 1) chiamo createTokenRequest per la generazione della request verso openid-token
        var createTokenRequest = new CreateTokenRequestModel(json.movementId)

        SessionIdpService.createTokenRequest(createTokenRequest,

          function (response) {
            var tokenRequest = new OpenIdTokenRequestModel(response.data.clientAssertionType, response.data.clientAssertion, response.data.code);

            $http({
              method: 'POST',
              url: response.data.endpoint,
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              data: composeQueryString(tokenRequest),
              withCredentials: true
            }).then(function (response) {
              if (response.data && response.data.error) {
                /*  var params = {};
                 params.error = {}
                 params.error.httpResponse = {}
                 params.error.httpResponse.metadata = {}
                 params.error.httpResponse.metadata.message = AppConfs.esitoPage['autenticazione']['exception0'].bodyMsg; */

                $window.location.href = "/rpol/private/pages/errore.html";
                forceHide_CustomUIKit_PageLoader(true);

              }
              else {
                /*calcolo l'expire in base al token */
                var config = {
                  secure: true
                };
                var expire = new Date();
                expire.setSeconds(expire.getSeconds() + response.data.expires_in);
                setCookie('RPOL_EXPIRES_IN', Math.round(expire.getTime() / 1000));
                /*                 $cookies.put('RPOL_EXPIRES_IN', Math.round(expire.getTime() / 1000), config); */
                var retriveInfoRequest = new RetrieveInformationIdpRequestModel(response);

                SessionIdpService.retrieveInformationIdp(retriveInfoRequest,

                  function (response) {
                  //  $location.replace();
                    window.location.href = window.location.href.split('?')[0];
                    hide_CustomUIKit_PageLoader();
                  },
                  function (httpResponse) {
                    show_CustomUIKit_PageLoader();
                    /*  var params = {};
                     params.error = {}
                     params.error.httpResponse = httpResponse;
 
                     $rootScope.$broadcast('errorAuthEvent', params); */
                    $window.location.href = "/rpol/private/pages/errore.html";
                    forceHide_CustomUIKit_PageLoader(true);
                  })
              }
            }).catch(function (httpResponse) {
              show_CustomUIKit_PageLoader();
              /*  var params = {};
               params.error = {}
               params.error.httpResponse = httpResponse;
 
               $rootScope.$broadcast('errorAuthEvent', params); */
              $window.location.href = "/rpol/private/pages/errore.html";
              forceHide_CustomUIKit_PageLoader(true);
            });



            /*   AuthenticationIdpService.call(response.data.endpoint).openidToken(composeQueryString(tokenRequest),
                function (response) {
                  var retriveInfoRequest = new RetrieveInformationIdpRequestModel(response.id_token);
    
                  SessionIdpService.retrieveInformationIdp({
              data:
              {
                movementId: params.movementId
              }
            },
    
                    function (response) {
                      window.location.href = window.location.href.split('?')[0];
                    },
                    function (httpResponse) {
    
                    })
                },
                function (httpResponse) {
    
                }
              ); */

          },
          function (httpResponse) {
            show_CustomUIKit_PageLoader();
            var params = {};
            params.error = {}
            params.error.httpResponse = httpResponse;

            $rootScope.$broadcast('errorAuthEvent', params);
            hide_CustomUIKit_PageLoader();

          }
        )
      }

    }








    //   });


    /**
 * --------------- PROTOTYPES -> [RequestModel, Configurations] ---------------
 */


    function CreateTokenRequestModel(movementId) {
      this.data = {};
      this.data.movementId = movementId;
    }

    function OpenIdTokenRequestModel(caType, ca, code) {
      this.client_assertion_type = caType ? caType : "urn:ietf:params:oauth:client-assertion-type:jwt-bearer";
      this.client_assertion = ca;
      this.code = code;
    }

    function RetrieveInformationIdpRequestModel(response) {
      this.data = {};
      this.data.id_token = response.data.id_token;
      this.data.access_token = response.data.access_token;
    }

    function composeQueryString(params) {
      var ps = [];
      Object.keys(params).forEach(function (key) {
        if (typeof params[key] != 'undefined' || params[key] != null)
          ps.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
      });
      return ps.join('&');
    }



  }
})();






