angular.module('filters.importiRange').filter('importiRange', function () {
	return function (items, fromValue, toValue, tipologiaOperazione) {
		var filtered = [];
		//here you will have your desired input
	
		var minRange = '';
		var maxRange = '';
		if (fromValue && fromValue !== null && fromValue !== '') {
			minRange = fromValue + "00";
		}
		if (toValue && toValue !== null && toValue !== '') {
			maxRange = toValue + "00";
		}
		
		angular.forEach(items, function (item) {
			//solo importo massimo valorizzato
			if (minRange == '' && maxRange !== '') {
				if (tipologiaOperazione === "A") {
					if (item.importo <= maxRange && item.segno === "A") {
					
						filtered.push(item);
					}
				} else if (tipologiaOperazione === "D") {
					if (item.importo <= maxRange && item.segno === "D") {
					
						filtered.push(item);
					}
				} else {
					if (item.importo <= maxRange) {
					
						filtered.push(item);
					}
				}

			}
			//solo importo minimo valorizzato
			else if (minRange !== '' && maxRange === '') {
				if (tipologiaOperazione === "A") {
					if (item.importo >= minRange && item.segno === "A") {
					
						filtered.push(item);
					}
				} else if (tipologiaOperazione === "D") {
					if (item.importo >= minRange && item.segno === "D") {
					
						filtered.push(item);
					}
				} else {
					if (item.importo >= minRange) {
						
						filtered.push(item);
					}
				}
			}
			//entrambi importi valorizzati
			else if (minRange !== '' && maxRange !== '') {
				if (tipologiaOperazione === "A") {
					if (item.importo >= minRange && item.importo <= maxRange && item.segno === "A") {
				
						filtered.push(item);
					}
				} else if (tipologiaOperazione === "D") {
					if (item.importo >= minRange && item.importo <= maxRange && item.segno === "D") {
					
						filtered.push(item);
					}
				} else {
					if (item.importo >= minRange && item.importo <= maxRange) {
						
						filtered.push(item);
					}
				}
			}
			//solo filtro segno
			else {
				if (tipologiaOperazione === "A") {
					if (item.segno === "A") {
						filtered.push(item);
					}
				} else if (tipologiaOperazione === "D") {
					if (item.segno === "D") {
						filtered.push(item);
					}
				} else {
					filtered.push(item);
				}
			}
			
		});
		return filtered;
	};
});


























































































































































































































































































































































































































































































































































