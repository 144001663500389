/*
    Direttiva per validare il model del campo 
    d'input a cui è associata; controlla 
    che il valore immesso sia:
        1) formattato nel seguente modo: DD/MM/YYYY
        2) una data valida
*/
angular.module('directives.scriptInsert').directive('scriptInsert', ['$log', function ($log) {
    
    return {
        restrict: 'AE',
        link: function (scope, element, attrs) {
           
            scope.$on(attrs.event, function(event) {  
                $log.log('script event '+attrs.event);
                //var head = document.getElementsByTagName('head')[0];
                var head=$('head');
                var head = document.getElementsByTagName('head')[0];
                var script = document.createElement('script'); 
                script.src = attrs.script; 
                script.type = 'text/javascript';
                $log.log("Script-src "+attrs.script);
                if(attrs.script != "null" &&  attrs.script !="" && attrs.script != "undefined"){
                    $log.log("Script-inside "+script);
                    head.appendChild(script); 
                }
                
            });
        }
    }

}]);