angular.module('filters.buoniImportiRange').filter('buoniImportiRange', function () {
	return function (items, fromValue, toValue) {
		var filtered = [];
		//here you will have your desired input
	
		var minRange = '';
		var maxRange = '';
		if (fromValue && fromValue !== null && fromValue !== '') {
			minRange = fromValue + "00";
		}
		if (toValue && toValue !== null && toValue !== '') {
			maxRange = toValue + "00";
		}
	
		angular.forEach(items, function (item) {
			//solo importo massimo valorizzato
			if (minRange == '' && maxRange !== '') {

				if (parseInt(item.valoreRimborsoLordo) <= maxRange) {
			
					filtered.push(item);
				}
			}
			//solo importo minimo valorizzato
			else if (minRange !== '' && maxRange === '') {
			
					if (parseInt(item.valoreRimborsoLordo) >= minRange) {
				
						filtered.push(item);
					}
			
			}
			//entrambi importi valorizzati
			else if (minRange !== '' && maxRange !== '') {
				
					if (parseInt(item.valoreRimborsoLordo) >= minRange && parseInt(item.valoreRimborsoLordo) <= maxRange) {
				
						filtered.push(item);
					}
				}
				else{
					filtered.push(item);
				}
			//solo filtro segno
			
		});
		return filtered;
	};
});


























































































































































































































































































































































































































































































































































