angular.module('services.onBoardingBuoni').factory('onBoardingBuoniService',
	['$resource', '$filter', 'AppConfs',
		function ($resource, $filter, AppConfs) {

			var requestMilliseconds = new Date().getTime();
			var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
			var nomeServizio = '/rpolOnBoardingBuoni/cartacei';

			return $resource(
				AppConfs.general.restServices.baseContext + nomeServizio + '/:action',
				{}, {
					verifica: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
                            action: "verifica"
						}
					},
					esegui: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							action: "esegui"
						}
                    },
                    annulla: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							action: "annulla"
						}
                    }
				});
		}]);
