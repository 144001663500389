angular.module('filters.mappaLibrettoMinori').filter('MappaLibrettoMinori', function () {
	return function (tipologia) {

		var LibrettoMinori = "";


		switch (tipologia) {
			case '0':
				LibrettoMinori = "IO CRESCO (dai 0 ai 12 anni)";
				break;
			case '1':
				LibrettoMinori = "IO CONOSCO (dai 12 ai 14 anni)";
				break;
			case '2':
				LibrettoMinori = "IO CAPISCO (dai 14 ai 18 anni)";
				break;
			default:
				LibrettoMinori = "";
				break;
		}
		return LibrettoMinori;
	};
});