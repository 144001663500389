angular.module('inputResidenzaNif').component('inputResidenzaNif', {
    bindings: {
        listaNazioni: '<',
        idInputResidenza: '@',
        idInputNif: '@',
        labelResidenza: '@',
        labelNif: '@',
        inputModelResidenza: '=',
        inputModelNif: '=',
        checkboxNif: '='
    },
    templateUrl: '/rpol/public/views/common-templates/input-residenza-nif.template.html',
    controller: ['$scope', '$log', '$timeout', 'UikitProxy', '$window', 'WebtrendsService',
        function InputResidenzaNifController($scope, $log, $timeout, UikitProxy, $window, WebtrendsService) {

            var _this = this;
            _this.showNif = true;

            _this.$onInit = function () {
                _this.onSelectChange();
            }

            _this.onSelectChange = function () {
                if (_this.inputModelResidenza == 'IT') {
                    _this.showNif = false;
                } else {
                    _this.showNif = true;
                }
            };

            _this.cleanNifInput = function() {
                _this.inputModelNif = "";
            };

            _this.$postLink = function () {
                $timeout(function(){
                    UikitProxy.applyCustomCheckbox();
                    UikitProxy.applyCustomSelect();
                })
            };
        }]
});