angular.module('services.rompiSalvadanaio').factory('RompiSalvadanaioService', 
['$resource', '$filter', 'AppConfs',
		function ($resource, $filter, AppConfs) {

			var requestMilliseconds = new Date().getTime();
			var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
			var nomeServizio = '/rpolGestioneLibretto';

			return $resource(
				AppConfs.general.restServices.baseContext + nomeServizio + '/gestioneSalvadanaio/:action',
				{}, {
				eseguiChiusura: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "eseguiChiusura"
					}
				},
				annulla: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "annulla"
					}
				},
				verifica: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "verifica"
					}
				},
				richiediOtp: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "richiediOtp"
					}
				}
			});
		}]);
