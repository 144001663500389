angular.module('uploadFile').component('uploadFile', {
    bindings: {
        uploadDone: '&',
        typeSupported: '<',
        configBtn: '<',
        fileSizeType: '@'
    },
    templateUrl: '/rpol/public/views/common-templates/upload-file.template.html',
    controller: ['$log', '$timeout',
        function UplodFileCtrl($log, $timeout) {

            var _this = this;
            _this.btnUpload = {};

            function ModelConfigButton(btnLabel, btnClass, btnDisabled) {
                this.btnLabel = btnLabel || _this.btnUpload.btnLabel || "Carica";
                this.btnDisabled = btnDisabled || false;
            }

            var uploadElement = angular.element('#fileUp');

            function getMimeTypeModel(supported) {
                var listMimeType = {
                    pdf: "application/pdf",
                    png: "image/png",
                    jpg: "image/jpeg"
                }

                var returnMimeType = [];

                if (supported.length > 0) {
                    supported.forEach(function (mimeSup) {
                        var mimeSelected = listMimeType[mimeSup];
                        if (mimeSelected)
                            returnMimeType.push(mimeSelected);
                    });
                }

                returnMimeType = returnMimeType.length > 0 ? returnMimeType.join(',') : "";

                return returnMimeType
            }

            function returnFileSize(number, type) {
                if (type.toUpperCase() == "B") {
                    return number;
                } else if (type.toUpperCase() == "KB") {
                    return number / Math.pow(1024,1);
                } else if (type.toUpperCase() == "MB") {
                    return number / Math.pow(1024,2)
                }
            }

            function loadFile(files) {

                var r = new FileReader();
                var file = files[0];
                uploadElement.prop("value", "");

                r.onloadend = function () {
                    var item = {
                        success: {
                            fileName: file.name,
                            result: r.result,
                            size: returnFileSize(file.size, _this.fileSizeType)
                        }
                    }
                    
                    _this.uploadDone(item)
                }
                r.readAsDataURL(file);
            }

            function errorFile() {
                var error = {
                    error: true
                };
                _this.uploadDone(null, error);
            }

            uploadElement.change(function (e) {
                var file = e.target.files;

                if (file && file.length > 0)
                    loadFile(file);
                else
                    errorFile();
            });

            _this.uploadFile = function () {
                uploadElement.click();
            }


            //HOOKS
            this.$onChanges = function () {

                if (_this.typeSupported) {
                    var mimeTypes = getMimeTypeModel(_this.typeSupported);
                    uploadElement[0].setAttribute('accept', mimeTypes);
                }

                if (_this.configBtn) {
                    var config = _this.configBtn;
                    _this.btnUpload = new ModelConfigButton(config.btnLabel, config.btnClass, config.btnDisabled);
                }
            }
        }
    ]
});