angular.module('services.pinDematerializzato').factory('PinDematerializzatoService',['$resource', '$filter', 'AppConfs',
	function ($resource, $filter, AppConfs) {

		var requestMilliseconds = new Date().getTime();
		var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
		return {
            Call: function (baseUrl) {

                var nomeServizio = !baseUrl ? '/gestionePinDematerializzato' : '';
                var baseContext = !baseUrl ? AppConfs.general.restServices.baseContext : baseUrl;

                /** SET PARAMS */
              
                var verifyOTP = {
                    method: 'GET',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "verifyOTP"
					}
				};
				
				var confirmPIN = {
                    method: 'GET',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "confirmPIN"
					},
					responseType: 'arraybuffer'
                };

                return $resource(
                    baseContext + nomeServizio + '/:prefix/:package/:action', {}, {
						verifyOTP: verifyOTP,
						confirmPIN: confirmPIN
                    });
            }
        }

	}]);
