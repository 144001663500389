angular.module('services.configurazioni').factory('ConfigurazioniService', 
['$resource', '$filter', 'AppConfs',
		function ($resource, $filter, AppConfs) {

			var requestMilliseconds = new Date().getTime();
			var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
			var nomeServizio = '/rpolConfigurazioneService';

			return $resource(
				AppConfs.general.restServices.baseContext + nomeServizio + '/:path/:action',
				{}, {
				configurazioni: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "configurazioni"
					}/*
					,
					interceptor: {
						response: function (response) {
							var result = response.resource;
							result.$status = response.status;
							return result;
						}
					}*/
				},
				semaforoDR: {
					method: 'GET',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						path:"configurazioniSemaforo",
						action: "semaforoDr"
					}/*
					,
					interceptor: {
						response: function (response) {
							var result = response.resource;
							result.$status = response.status;
							return result;
						}
					}*/
				},
				semaforoBFP: {
					method: 'GET',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						path:"configurazioniSemaforo",
						action: "semaforoBfp"
					}/*
					,
					interceptor: {
						response: function (response) {
							var result = response.resource;
							result.$status = response.status;
							return result;
						}
					}*/
				}
			});
		}]);
