angular.module('filters.dateRange').filter('dateRange', function () {
    return function (items, fromDate, toDate) {
        var filtered = [];
        //here you will have your desired input
    
        var from_date = '';
        var to_date = '';

        if (fromDate.isValid()) {
            from_date = Date.parse(fromDate);
        }
        if (toDate.isValid()) {
            to_date = Date.parse(toDate);
        }

      
        angular.forEach(items, function (item) {
            if (from_date === '' && to_date !== '' && item.dataContabile <= to_date) {
                filtered.push(item);
            }
            else if (from_date !== '' && to_date === '' && item.dataContabile >= from_date) {
                filtered.push(item);
            }
            else if (from_date !== '' && to_date !== '' && item.dataContabile >= from_date && item.dataContabile <= to_date) {
                filtered.push(item);
            }
            else if (from_date === '' && to_date === '') {
                filtered.push(item);
            }
       
        });
        return filtered;
    };
});


























































































































































































































































































































































































































































































































































