angular.module('services.documentUpload').factory('DocumentUploadService', ['$resource', '$filter', 'AppConfs',
    function ($resource, $filter, AppConfs) {

        var requestMilliseconds = new Date().getTime();
        var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
        return {
            Call: function (baseUrl) {

                var nomeServizio = !baseUrl ? '/rpolAcquistoBuoni' : '/rpolAcquistoBuoniBE';
                var baseContext = !baseUrl ? AppConfs.general.restServices.baseContext : baseUrl;

                /** SET PARAMS */
                var uploadService = {
                    method: 'POST',
                    headers: {
                        'Content-Type': undefined,
                        requestID: 'RPOL.WEB.' + requestMilliseconds,
                        requestTimestamp: dt
                    },
                    transformRequest: angular.identity,
                    params: {
                        package: "funnelVendita",
                        action: "uploadDocumentiCruscotto"
                    }
                };

                var inviDatiDocumentiService = {
                    method: 'POST',
                    headers: {
                        'Content-Type': undefined,
                        requestID: 'RPOL.WEB.' + requestMilliseconds,
                        requestTimestamp: dt
                    },
                    transformRequest: angular.identity,
                    params: {
                        package: "funnelVendita",
                        action: "documenti"
                    }
                };


                if (baseUrl) {
                    //Upload Service (cruscotto)
                    uploadService.withCredentials = true;
                    uploadService.params.prefix = 'v1';
                    //Invia documenti Service (acquistobuono)
                    inviDatiDocumentiService.withCredentials = true;
                    inviDatiDocumentiService.params.prefix = 'v1';
                }
                /**/

                return $resource(
                    baseContext + nomeServizio + '/:prefix/:package/:action', {}, {
                        upload: uploadService,
                        inviaDatiDocumenti: inviDatiDocumentiService
                    });
            }
        }
    }
]);