angular.module('services.ricaricaPostePay').factory('RicaricaPostePayService', 
['$resource', '$filter', 'AppConfs',
		function ($resource, $filter, AppConfs) {

			var requestMilliseconds = new Date().getTime();
			var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
			var nomeServizio = '/rpolRicaricaPostePay';

			return $resource(
				AppConfs.general.restServices.baseContext + nomeServizio + '/ricarica/:action',
				{}, {
				verifica: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "verifica"
					}
				},
				richiediOtp: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "richiediOtp"
					}
				},
				esegui: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "esegui"
					}
				},
				esito: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "esito"
					}
				},
				annulla: {
					method: 'POST',
					headers: {
						requestID: 'RPOL.WEB.' + requestMilliseconds,
						requestTimestamp: dt
					},
					params: {
						action: "annulla"
					}
				}

			});
		}]);
