angular.module('filters.valutaConfigurabile').filter('valutaConfigurabileIntera', function () {
	return function (importo, posizione) {

		if (importo && posizione) {
			var impStr = importo.toString();
			if(impStr.length>2){
				var parteIntera = impStr.substring(0, (impStr.length - posizione));
				
				//return parseInt(parteIntera).toLocaleString();
				return parseInt(parteIntera).toLocaleString("it-IT");
			}
			else
				return 0;
		}
		else
				return 0;
	};
})

	.filter('valutaConfigurabileDecimale', function () {
		return function (importo, posizione) {

			if (importo && posizione) {
			
				var impStr = importo.toString();
				var parteDecimale = "";
				
				if(posizione>2){
					parteDecimale = impStr.substring((impStr.length - posizione), ((impStr.length - posizione)+2));
				}else{
					parteDecimale = impStr.substring((impStr.length - posizione), impStr.length);
				}
		
				return parteDecimale;
			}
			else
				return '00';
		};
	});

