angular.module('simulatore').component('simulatore',
	{
		bindings: {
			confs: '<', 
			supersmartErrorHandler: '&'
		},
		templateUrl: '/rpol/private/pages/common-templates/simulatore.template.html',
		controller: ['$scope', '$log', '$timeout', '$window', 'UtenteService', 'WebtrendsService', 'SatelliteService', '$sce',
			function SimulatoreController($scope, $log, $timeout, $window, UtenteService, WebtrendsService, SatelliteService, $sce) {

				var vm = this;

				//Supersmart Vars
				vm.showSimula = false;
				vm.simulazioneAccantonamento = {};
				vm.interessiSimulati = {};
				vm.semaforoDR;
				//Supersmart Funs
				vm.simulaAccantonamenti = simulaAccantonamenti;
				vm.checkAmount = checkAmount;
				vm.$doCheck = doCheck;
				vm.calcolaSimulazione = calcolaSimulazione;
				vm.attivaAccantonamento = attivaAccantonamento;
				vm.formattaValutaInput = formattaValutaInput;
				/* vm.disattivaAccantonamento = disattivaAccantonamento; */

				//vm.output: utilizzato per passare al componente padre gli errori di validazione
				vm.output = {};
				vm.output.errori = {};
				vm.output.errori.zeroError;
				vm.output.errori.minError;
				vm.output.errori.maxError
				vm.output.errori.noValidError;
				vm.output.parteIntera;

				//vm.importo: utilizzato per gestire l'importo inserito dall'utente
				vm.importo = {};

				$log.log('Inizializzazione', vm);

				function formattaValutaInput(data) {
					//$scope.$broadcast('formattaValutaEvent', data);
				}

				function checkAmount(form, campoImporto) {

					vm.output.validationError = false;

					vm.output.errori.zeroError = false;
					vm.output.errori.minError = false;
					vm.output.errori.maxError = false;
					vm.output.errori.noValidError = false;

					var formImporto = form[campoImporto];

					//Controllo se l'importo inserito è maggiore di 0
					var parteInteraInt = parseInt(vm.importo.parteIntera);
					var sogliaMinima = parseInt(vm.simulazioneAccantonamento.importoMinimoDurata) / 1000;
					var sogliaMassima = parseInt(vm.simulazioneAccantonamento.importoMassimoDurata) / 1000;
					var taglioStep = parseInt(vm.simulazioneAccantonamento.taglioMinimo) / 1000;
					//Importo uguale a zero
					if (isNaN(parteInteraInt) || parteInteraInt == 0) {
						formImporto.$invalid = true;
						form.$invalid = true;
						vm.output.errori.zeroError = true;
						vm.output.validationError = true;
						vm.interessiSimulati = {};
						$scope.$emit('validationError', vm.output);
						$log.log('Step0Controller checkAmount importo: uguale a 0');
					}
					//Importo inferiore al minimo consentito
					else if (parteInteraInt < sogliaMinima) {
						formImporto.$invalid = true;
						form.$invalid = true;
						vm.output.errori.minError = true;
						vm.output.validationError = true;
						vm.interessiSimulati = {};
						$scope.$emit('validationError', vm.output);
						$log.log('Step0Controller checkAmount importo inferiore al minimo consentito: ' + sogliaMinima);
					}
					//Importo maggiore del massimo consentito
					else if (parteInteraInt > sogliaMassima) {
						formImporto.$invalid = true;
						form.$invalid = true;
						vm.output.errori.maxError = true;
						vm.output.validationError = true;
						vm.interessiSimulati = {};
						$scope.$emit('validationError', vm.output);

						$log.log('Step0Controller checkAmount importo superiore al massimo consentito: ' + sogliaMassima);
					}
					//Importo non multiplo dello step
					else if (parteInteraInt % taglioStep != 0) {
						formImporto.$invalid = true;
						form.$invalid = true;
						vm.output.errori.noValidError = true;
						vm.output.validationError = true;
						vm.interessiSimulati = {};
						$scope.$emit('validationError', vm.output);
						$log.log("Step0Controller checkAmount importo non multiplo dello step: " + taglioStep);
					}
					//Importo OK
					else {
						if (!isNaN(parteInteraInt)) {
							formImporto.$invalid = false;
							vm.output.validationError = false;
							$log.log('Step0Controller checkAmount importo: OK');
						}
					}

				
				}

				function simulaAccantonamenti(form) {
					$log.log('vm.showSimula', vm.showSimula);
					if (!vm.showSimula) {
						show_CustomUIKit_PageLoader();

						vm.simulazioneAccantonamento = {};
						vm.showSimula = false;
						
						//Clean class errore sul campo importo
						var formImporto = form["campoEuro01"];
						formImporto.$invalid = false;
					
						//Webtrends
						WebtrendsService.WT_librettiSmart_simula_accantonamento();

						// satellite track
                		SatelliteService.track('Simula Accantonamento', null,
                				'offerta_supersmart-simula_accantonamento', '1');
						

						$log.log("SupersmartController simulaAccantonamenti numeroLibretto: ", vm.confs.numeroRapporto);

						//Per popolare la struttura dati del box di simulazione
						vm.confs.service.simula(
							{
								data:
								{
									numeroLibretto: vm.confs.numeroRapporto
								}
							},
							function (response) {
								if (response.data && response.data.listaDurata && response.data.listaDurata.length > 0) {
									$log.log('ManagerController simulaAccantonamenti AccantonamentoService.simula response: ', response);

									if (response && response.data && response.data.listaDurata) {
										response.data.listaDurata.forEach(function(el) {
											el.nomeOfferta = $sce.trustAsHtml(getNomeOfferta(el));
										});
									}

									vm.simulazioneAccantonamento = response.data;

									if (!vm.showSimula) vm.showSimula = true;

									$log.log('ManagerController simulaAccantonamenti AccantonamentoService.simula vm.simulazioneAccantonamento: ', vm.simulazioneAccantonamento);

									hide_CustomUIKit_PageLoader();
								}
								else {
									vm.showSimula = false;

									$log.log('ManagerController simulaAccantonamenti AccantonamentoService.simula vm.simulazioneAccantonamento: ', vm.simulazioneAccantonamento);

									hide_CustomUIKit_PageLoader();
								}
							},
							function (httpResponse) {
								$log.log('Simulatore simulaAccantonamenti AccantonamentoService.simula error-response:', httpResponse);

								vm.supersmartErrorHandler({ errorResponse: httpResponse });

								forceHide_CustomUIKit_PageLoader();

							}
						);
					}
				}


				function calcolaSimulazione(form) {
						//pulisco i campi per disabilitare gli attiva
						vm.interessiSimulati = {};
						$log.log(form.$invalid);
						$log.log(form.campoEuro01.$invalid);
	

					//pulisco i campi per disabilitare gli attiva
					vm.interessiSimulati = {};
				
				 	checkAmount(form, 'campoEuro01');  

					if (!form.$submitted) 
						form.$submitted = true;

					if (form.$invalid === true || form.campoEuro01.$invalid) {

						$log.log('SupersmartController calcolaSimulazione form.$invalid:', form.$invalid);
						$log.log('SupersmartController calcolaSimulazione form.campoEuro01.$invalid:', form.campoEuro01.$invalid);

						// CR simulatore prelogin, non scrollare verso l'alto
						if (!(typeof simulatore_pre_login !== 'undefined' && simulatore_pre_login)) {
							$window.scrollTo(0, 0);
						}

						//Webtrends - Messaggio di errore in step0.template.html
						$timeout(function () {
							var errorMsg = $('#simula-accantonamento-error-step0-template').text();
							WebtrendsService.WT_mess_errore('alias_off_ssmart_accantonamento', errorMsg.replace(/\s+/g, ' ').trim());
							
							SatelliteService.track_error(errorMsg.replace(/\s+/g, ' ').trim());
							
						});

					} else {

						$log.log('SupersmartController calcolaSimulazione TUTTO OK');
						//Se controlli sintattici okay
						simula();

					}
				}

				/** [INIZIO] CALCOLO SIMULAZIONE ACCANTONAMENTO (pvrOffertaSupersmart.js) **/
				function simula() {
					vm.interessiSimulati = {};
					var importoSimulatore = vm.importo.parteIntera;

					var dataOdierna = new Date();
					var annoDataOdierna = dataOdierna.getFullYear();
					$log.log('dataOdierna: ' + dataOdierna);
					$log.log('annoDataOdierna: ' + annoDataOdierna);
					if (annoDataOdierna != null && annoDataOdierna != "") {
						angular.forEach(vm.simulazioneAccantonamento.listaDurata, function (dettDurata, key) {

							$log.log('durata.codiceDurata: ' + dettDurata.codiceDurata);

							var minimoImporto = parseInt(dettDurata.importoMinimoDurata / 1000);
							var massimoImporto = parseInt(dettDurata.importoMassimoDurata / 1000);

							if (importoSimulatore < minimoImporto) {
								minimoImporto = ("" + minimoImporto).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
								$log.log('minore del minimoImporto: ' + minimoImporto);
								var msgCode = "Taglio minimo € " + minimoImporto;
								vm.interessiSimulati[dettDurata.codiceDurata] = { codice: null, attiva: false, codiceDisabled: msgCode };
							}
							else if (importoSimulatore > massimoImporto) {
								massimoImporto = ("" + massimoImporto).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
								$log.log('maggiore del massimoImporto: ' + massimoImporto);
								var msgCode = "Taglio massimo € " + massimoImporto;
								vm.interessiSimulati[dettDurata.codiceDurata] = { codice: null, attiva: false, codiceDisabled: msgCode };
							}
							else {
								var durata = parseInt(dettDurata.durata);
								var tasso = dettDurata.tassoDurata / 1000;

								var interesseLordo = calcolaInteresseLordo(dataOdierna, durata, tasso, importoSimulatore);

								var interesseNetto = interesseLordo * (1 - 0.26) / 100;
								interesseNetto = interesseNetto.toFixed(2);

								var interesseNettoWithComma = (interesseNetto + "").replace(".", ",");

								vm.interessiSimulati[dettDurata.codiceDurata] = { codice: interesseNettoWithComma, attiva: true, codiceDisabled: null };
							}
						});

						$log.log("vm.interessiSimulati: ", vm.interessiSimulati);

						//Webtrends - tahnkyoupage simula accantonamento
						WebtrendsService.WT_librettiSmart_simula_accantonamento_thankyoupage();
						
						// satellite track
                		SatelliteService.track('Simula Accantonamento', null,
                				'offerta_supersmart-simula_accantonamento', '2');

					}

				}

				/*
					Devo verifare se la data odierna piu' la durata corrisponde ad una data 
					appartenente allo stesso anno della data odierna o meno. In questo caso
					sono nella situazione "a cavallo tra due anni".
				*/
				function calcolaInteresseLordo(dataOdierna, durata, tasso, importo) {
					
					var annoDataOdierna = dataOdierna.getFullYear();
					
					var millisecondiDataOdierna = dataOdierna.getTime();
					var millisecondiGGaggiungere = 24 * 60 * 60 * 1000 * durata;
					var millisecondiTotali = millisecondiDataOdierna + millisecondiGGaggiungere;
					var dataFinale = new Date(millisecondiTotali);
					var annoDataFinale = dataFinale.getFullYear();
					
					var interesseLordo = 0;
					var dataInizioEsameAnno = dataOdierna;
					
					for(i=0; i<=annoDataFinale-annoDataOdierna;i++){
						var annoInEsame = annoDataOdierna+i;
						
						var dataInizialeAnnoSuccessivo = new Date((annoInEsame+1), 0, 1);
						
						var durataPartita = ((dataInizialeAnnoSuccessivo<=dataFinale?dataInizialeAnnoSuccessivo:dataFinale) - dataInizioEsameAnno) / (1000 * 60 * 60 * 24);
						
						var interesseLordoAnno = (durataPartita * tasso * importo) / getTotaleGGCompetenza(dataInizioEsameAnno);
						
						interesseLordo = interesseLordo + interesseLordoAnno;
						
						dataInizioEsameAnno = dataInizialeAnnoSuccessivo;
					}
					
					
					

					/*if (dataOdierna == null || durata == null || tasso == null || importo == null)
						return "0";

					var interesseLordo = 0;

					var annoDataOdierna = dataOdierna.getFullYear();
					var totaleGGcompetenzaPrimoAnno = getTotaleGGCompetenza(dataOdierna);

					var millisecondiDataOdierna = dataOdierna.getTime();
					var millisecondiGGaggiungere = 24 * 60 * 60 * 1000 * durata;
					var millisecondiTotali = millisecondiDataOdierna + millisecondiGGaggiungere;
					var dataFinale = new Date(millisecondiTotali);
					var annoDataFinale = dataFinale.getFullYear();

					if (annoDataOdierna == annoDataFinale) {
						interesseLordo = (durata * tasso * importo) / totaleGGcompetenzaPrimoAnno;
					}
					else {
						// NOTA var d = new Date(year, month, day); dove month va da 0 a 11
						var dataInizialeSecondoAnno = new Date(annoDataFinale, 0, 1);
						var durataPartitaPrimoAnno = (dataInizialeSecondoAnno - dataOdierna) / (1000 * 60 * 60 * 24);
						var durataPartitaSecondoAnno = (dataFinale - dataInizialeSecondoAnno) / (1000 * 60 * 60 * 24);
						var totaleGGcompetenzaSecondoAnno = getTotaleGGCompetenza(dataFinale);
						var giorniAnnoBisestile = 0;
						
						for(i=1; i<annoDataFinale-annoDataOdierna; i++){
							if(bisestile(annoDataOdierna+i))
								giorniAnnoBisestile=giorniAnnoBisestile+1;
						}
						var interesseLordoPrimoAnno = (durataPartitaPrimoAnno * tasso * importo) / totaleGGcompetenzaPrimoAnno;
						var interesseLordoSecondoAnno = (durataPartitaSecondoAnno * tasso * importo) / (totaleGGcompetenzaSecondoAnno+giorniAnnoBisestile);
						interesseLordo = interesseLordoPrimoAnno + interesseLordoSecondoAnno;
					}*/

					interesseLordo = interesseLordo.toFixed(2);
					return interesseLordo;
				}

				function getTotaleGGCompetenza(data) {
					if (data == null)
						return 0;
					var anno = data.getFullYear();
					var isBisestile = bisestile(anno);
					var totaleGGcompetenza = 0;
					if (isBisestile == true)
						totaleGGcompetenza = 366;
					else
						totaleGGcompetenza = 365;
					return totaleGGcompetenza;
				}

				function bisestile(anno) {
					if ((anno % 4 == 0 && anno % 100 != 0) || anno % 400 == 0)
						return true;
					else
						return false;
				}

				/** [FINE] CALCOLO SIMULAZIONE ACCANTONAMENTO (pvrOffertaSupersmart.js) **/


				function doCheck() {
					if(vm.confs.semaforoDR == 2){
						vm.semaforoDR = 2;
					}
					if (vm.confs.reset) {
						$log.log("RESET");

						vm.importo.parteIntera= '';
						/* 	vm.outputConfs.importo.parteDecimale = '00'; */
						vm.showSimula = false;

						vm.output.errori.zeroError = false;
						vm.output.errori.rangeError = false;
						vm.output.errori.noValidError = false;
						vm.output.isAmountValid = false;
						
						vm.confs.reset = false;

						// CR simulatore prelogin, apertura automatica
						$timeout(function() {
							if (typeof simulatore_pre_login !== 'undefined' && simulatore_pre_login) {
								$("#simulaBtn").click();
							}
						});
					}
				}

				function getNomeOfferta(el) {
					var nome = 'Deposito Supersmart<br class="hidden-xs"> ';
					switch (el.codiceDurata) {
						case "DRVIN101":
							nome += "Open";
							break;
						case "DRVIN103":
							nome += "Rinnova";
							break;
						case "DRVIN105":
							nome += "Premium";
							break;
						case "DRVIN106":
							nome += "Young";
							break;
						case "DRVIN107":
							nome += "Pensione";
							break;
						default:
							nome += parseInt(el.durata);
							break;
					}
					return nome;
				};

				function attivaAccantonamento(codiceDurata) {
					$log.log("SupersmartController attivaAccantonamento codiceDurata: " + codiceDurata);

					var importoToActivate = ('' + vm.importo.parteIntera) + '000';

					//Webtrends
					WebtrendsService.WT_librettiSmart_accantonamento_attiva_daSimulatore();

					UtenteService.inserisci(
						{
							data: {
								mappaVariabiliTemp: {
									numeroRapporto_attivaAccantonamenti: vm.confs.numeroRapporto,
									codiceDurata_attivaAccantonamenti: codiceDurata,
									importo_attivaAccantonamenti: importoToActivate
								}
							}
						},
						function (response) {
							hide_CustomUIKit_PageLoader();
							$log.log('ManagerController attivaAccantonamento UtenteService.inserisci response: ', response);
							$window.location.href = vm.confs.accantonamentiURL;
						},
						function (httpResponse) {
							$log.log('ManagerController attivaAccantonamento UtenteService.inserisci error-response:', httpResponse);
							var msg = vm.getErrorResponseMessage(httpResponse);

							WebtrendsService.WT_mess_errore(null, msg);  // attiva non ha url definita nel log webtrends
							
							SatelliteService.track_error(msg);

							configureEsitoPage(msg, 'reloadSupersmart');
							vm.showEsito = true;
							forceHide_CustomUIKit_PageLoader();
						});
				}


			}]
	}

);