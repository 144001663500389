window.geoMap = {};
window.geoProject = {};
window.markerClusterer = {};
window.googleMaps = {};
window.selectUp = {};
var distanceFunction = function(input) {
	input=Math.round(input);
	if (input >= 1000) {
	    return (input / 1000).toFixed(2) + 'km';
	} else {
	    return input + 'm';
	}

};
var mapApplication = angular.module('mapApplication', []);
mapApplication.
factory('retinaDensityCheck', function() {
    //service to check screen density/retina
    return {
        isHighDensity: function() {
            var isHD = false;
            isHD = (window.matchMedia && (window.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches)) || (window.devicePixelRatio && window.devicePixelRatio > 1.3);
            return isHD;
        },
        isRetina: function() {
            var isRet = false;
            isRet = (window.matchMedia && (window.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches)) || (window.devicePixelRatio && window.devicePixelRatio > 1.3);
            return isRet;
        }
    }
}).factory('amaconfig', function () {
	return {
		uffici : {
			geoUrl: (typeof(ama_geocms_script)!='undefined'?ama_geocms_script:"https://www.geocms.it/API/geocms/js")+"?v=3&license=geo-posteitalianespa&project=Uffici&modules=infobubble|markerclustererplus&callback=geoCallback",
			project: 'Uffici',
			maxResultFisici: '20',
			license: 'geo-posteitalianespa',
			minRaggio: '1000',
			maxRaggio: '1000',
			markerStyle : 'default',
			clusterMarker : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAAvCAYAAABzJ5OsAAAAAXNSR0IArs4c6QAABLtJREFUaAXVmsFrVEccx2feronrmq1NCsa2QiKU4KEq7KFS/4CiiD30Ug9F6UF6LNgKQimLFAqtQo+lBzH0YC49pC1K/4BCeghUeyhS0EBDVWhSTdxsTXff6/czmbe+1ee6Cevm7Q9+zHuz82Y+v3nz3pv5zlrTBYuiaFzVHJOX5RPyV+VD8m3yFfmyfF5+Qz4r/95ae0vp5piA98vPya/JN2Jcx/X7NxqBXe+Famyvrvlc/nZ8bRQuRQ+rP9l67RcT1m+bsHFH6V2lCybIjZggv1PpqNJdJl94wwwW34psUEq2Pa26zupu/B7X2UmarKBteUG/rAIV+fvyXBQ+iP5dmrKr1Svmv9rPymrIO7W82VJ40wwUj5itpXcVyHY4qOCivKIg/uqkpo7gBX5YlV2Wv2BMPardu2RXFr80UbjQSRtty9hgxGwb/tgUdpyMjMnDc19+XAFcbXuhfnwmvMBPq9wX8uDhgyum+venGhJzOu2uBfkxU3zpnBncfoSKQ/kZBXChXStPhRf0gC78Rn5CHlUXPrO1f75qV1dXfiu8+KEpjnyiu+A6dlLpKQWxmlZ5O/hLuuBEFFaj5bsfaGw/8y6m1b+hvIHiYTO082s9C0X4JgV/Mq2iVHg/VM4Dfm/+qG2s/pZ27XPNyw3uMzte+SEO4KO0IfQEvH84fxSZXbr9Xk97/PHe4A6Udn3LEMKPKoCW2x8kLxA4r8MpecAY7+VQSXLEx7QPBzzyy54v/tllNk90UJGXeKv04uFMNvy0Yzjgkek17fiaRZvDRlHx5dTgrgeLcwft83gdNltd5wGv0eGxGQ2dPK/Q1zV83Jc4OWz45Of4AGUJnDjhgQs+OZzOXM+r15kc/conf3GubLvx5fT1dy3hSzw8NhtPJQ6o96/FPf8OrTBXySI4bHDBx7HM8cbwbobIJCvLluBzvFZDZlzAN5nWLtx8TZGtZ3bY61BzZmTPHxo6bjq9h55nBWSYj2cbHMqG5+TYHAO+zFG9NkOSeUtwloGfgDis38k8+GOcE8DvdplauvWDscT0tht4Vvmm0Sc9n+AcAr4AfNRYJMm8JTgLwNcgtrnhzIMDiBrhbQX4ZU5y+VGfl+0EGcXbMvB/coKu0g+W4JwHHglOglCfwEu48nYDeLRDKVkHfV62kwTnbH/PbTQvvqW+vs5kZ0vhUKa7HT4/KbsON8MGQ+h02iFpVg1t05vjjeG/I3NN9Gy+R+OCmUhZScHnYRyvg2dJpcxp1FpEzywaXF5Nnva8j4TWvlYPFA1ygvTxvEWtzZKt8Tj5+6LndHjxmI9ZKzq4j8yMWpsFg8PL3uj2lSRTC7yiYkfiuDxEZkYr3EyjfS93Izax4dCyY9ICD6gKXFVyhkNkZtTazTDapX045Gw0wNViT8DzqwpeUDKJPo7M3Os7QHsJeXvS87SAc5IK70udUuoCQGbu1TNAO7QXbyyIAY5U45a0Nb1CT6tAf+1JJSNSADy5U/JSX+0GxkEogC7uw+a0D3uoN/uwcQCk/kuMzOz0QpfX3AGfcfoP8gSrfBbLrI1ZYq7tgI+6dUO3dsBpe0OmIPrvvwdpkSqQceWjeZblKHAIWUNyZJWanEU+a+Wu/uvjfxfcbYvuHv2UAAAAAElFTkSuQmCC',
			clusterColor : 'black'
		},
		cassette : {
			geoUrl: (typeof(ama_geocms_script)!='undefined'?ama_geocms_script:"https://www.geocms.it/API/geocms/js")+"?v=3&license=geo-posteitalianespa&project=CassettePostali&modules=infobubble|markerclustererplus&callback=geoCallback",
			project: 'CassettePostali',
			maxResultFisici: '50',
			license: 'geo-posteitalianespa',
			minRaggio: '1000',
			maxRaggio: '1000',
			markerStyle : 'cassette',
			clusterMarker : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAAvCAYAAABzJ5OsAAAAAXNSR0IArs4c6QAABNFJREFUaAXVmltsTEEYx2fOrtLqhaJKNMGDxgMV60F5cUkEcYl4kD4IkWhIPEgqTUQiG4lIGhIPEkLSWB7Kg0hFVCQuL9SDissD9YCEuLSU6g3t7vH/j5ntWY61Zbud/ZIvM2fOmZnfd3bOOTP/WSnSYK7rzkAz6+AheDl8GrwAngfvhXfBX8Nb4S3wS1LKF0hHxgBcAT8Afwj/F2M91q/41wjkUCuis9mocwi+3tR1u764X29dk/3374po21sRa3svYu3vROxzh3DGFQtnUqlwSiaLQMkUMWr+QjFmyQpXFhR6+25EW3vxazwxbaaSehtIej2gp+KCMHwbPOD29rh9Fxvkt+tXxPd7d4SIRlGcogWDIidUKUYvXy1yN1S5Mm8sOdhAPTyMIN6k0lJK8ABfhcYa4EViYMDtPX9adh8/LNxPH1PpI+k1cvwEkb9zj8jbtNUVwSB5OuFVCKApaUWc/Cs8wGtwXR3c+XqjSXTX7RfRVy9xmF4LlE0X+bUHxJhlvE8iBq9FAEeS9fJHeEDnoOJJ+Ba42330oOw5dTRZW2k5N3b7bpG/e5+LxsgWgVcjiO9+jSeDP40KWzi2O2t3yG83r/rVH5ay0UtXiqK6E+ZZiAB+q19HvvB6qBwmeMfmtXLg6WO/usNaFpw9VxSfuWQC2OM3hH6D1w/nZZDJz7s2Z/SO/3o3+AuMO3aWQ4i+BgEkPMSOtwLA+To8B3c4xjM5VLwcJs/+yUEeeIPmM6dVYfwAmTC8kG+VTDyc3o7/lCcHeWBF8DAzxuLDBlHxy/kY73Hnw5pKORyvQ9PpUFO+RidebuZ3gK/QORg+6kvsHTb85Af4AbIJnIGSh1zkg5NTmbrzuOucHD3g26V9RUim48up209bwi/xpGst5u0zD3f/obnzG9kL5yo2gpONXORjHqZ4DbyaIXKSZbN5+BSvxJCZAeDnnNa2LZ4lhzQ7zHSkgYAouf3MTKdn8s5zBSQ4H7canJCYditO5sFN+BBz/febmVhvHs4Q4ctJHG17Zz34L5zlhC9jIZdu2WAezjLCc5UP+Oy48x7OAsLnKvjODibWW2yQM5fwfSR2ioqtB1ecUCO09RK+SxWWlJpCq1PKKNq6CP+KB9RVssE8nK8JTwkOglA8IqtjoHClrZXw1A6hZFXqMruTURCrtLVk99wG8+IXiOQRtcOcBYtMVFam5NMa5yNyc9jQKHQq7ZCprUZtU5viNfAXWKhET6xYbDSupMin2RSvgueSCoWNVGspetpo5NJqcqPmHRRas1o9QDSUE+opM1OttckUz0/5u15zKjwz5g1rGJlOysxUa20wcmjZm7p92MuUAI+ouCNRBY9RZqZWOJLG/rXcTbGJGw4JOyYJ8ATFBU1IapmlzEy1diSM/bJ/csC50UCuBPsNnmdx4REkET7dlJkz/QuwP4+8HdE8CeA88IXXV1UjVQFQZs7UM8B+2J9+LUbAQA5fMwqU70kW4hVagyS79qS80SAA7nJRty/Mqt1AEwQC4MZDGL4N/n/7sFC+OMnKyD4sYOOmv8SUmZVeyBODO+DNSv9RO+BQI7hY5trYwULn5w54qVo3pGsHPA411AyCyL7/HvgFiUAo1nr/9UEhqwBOWaUPzkU+18qt8BZ4Wv718QM6/3zCr/7R0gAAAABJRU5ErkJggg==',
			clusterColor : 'white'
		}
	}
}).factory('togglePopover', function () {
    //service to manage Bootstrap popover show/hide
    return {
        showPopover: function(element,placement,text) {
            var popoverOptions={
                'placement': placement,
                'content': text,
                'trigger': 'manual'
            };
            //$(element).popover(popoverOptions).popover('show');
        },
        hidePopover: function(element) {           
//             $(element).popover('hide');                         
        }
    };

})
.filter('distance', function () {
	return distanceFunction;
})
.directive("searchMaps", [function(){
    return {
        restrict: 'E',
        scope: {
            idMap:'@',
            frazionario:'=',
            query:"="
        },
        templateUrl: '/rpol/public/common-modules/directives/cerca-up-directive/search.html',
        //templateUrl: '/app-resources/-google-maps/templates/search.html',
        controller: ['$scope', '$http', '$rootScope', '$interval', '$location', '$compile', function($scope, $http, $rootScope, $interval, $location, $compile) {
            //$scope.id;
            $scope.idMap=$scope.idMap || "map";

            var autocomplete = null;
            var mobiletest = false;
            
            var autocompleteOptions = {
                componentRestrictions: { country: 'IT' }, //filtra Italia
                types: ['geocode'] //solo indirizzi
            };

			$scope.locationValidate = new RegExp('^[a-zA-Z0-9 \-_,èéùàòì()]*[^%$@#*£§°<>^¿±{}~¥‹÷´`≠\\\\$\|\!\[\\]\{\}\/\=\?]$');
            
            mapCallback = function() { // Map callback
            	$rootScope.googleapisScriptLoaded = true;
                $rootScope.$broadcast('google');
                autocomplete = new google.maps.places.Autocomplete($('#ama-autocomplete').get(0), autocompleteOptions);
            };

            // addScriptToDom("https://maps.googleapis.com/maps/api/js?language=it&libraries=places,geometry&v=3&client=gme-postecomspa&channel=valore&callback=mapCallback");
            addScriptToDom("https://maps.googleapis.com/maps/api/js?language=it&libraries=places,geometry&v=3&key=AIzaSyDrNGk2tI91D83mJrvsHKk7b2SB-N_2lwY&channel=valore&callback=mapCallback");
            
            $scope.amaCercaUffici = function(idMap, location, frazionario, query) {
            	
                if ($('#'+location).val() == $('#'+location).attr('placeholder')) {
                    $scope.searchLoc = "";
                } else {
                    $scope.searchLoc = $('#'+location).val();
                }

                $rootScope.$broadcast('scanner-started', {idMap: idMap, searchLoc: $scope.searchLoc, frazionario: frazionario, query: query});
            };

            $scope.amaAvviaGeolocalizzazioneUffici = function (idMap, location, frazionario, query) {
                $rootScope.$broadcast('geolocation', {idMap: idMap, location: location, frazionario: frazionario, query: query});
            };
            
            $scope.amaCercaOrGeolocalizzazioneUffici = function (idMap, location, frazionario, query) {
            	if ($('#'+location).val() == $('#'+location).attr('placeholder') || $('#'+location).val() == "") {
            		$scope.amaAvviaGeolocalizzazioneUffici(idMap, location, frazionario, query);
            	}
            	else{
            		$scope.amaCercaUffici(idMap, location, frazionario, query);
            	}
            };

        }]
    };
}])
.config(['$locationProvider', function($locationProvider) {
    $locationProvider.html5Mode({
        //enabled: false,
        requireBase: false
    });
}]).directive("viewMaps", ['amaconfig', function(amaconfig){
    return {
        restrict: 'E',
        scope: {
            value: '=param',
            height:'@',
            idMap:'@',
            width:'@', 
            hideSwitch:'@',
            openPopup:'=',
            type:'@'
        },
        templateUrl: '/rpol/public/common-modules/directives/cerca-up-directive/maps.html',
        link: function(scope, element, attrs) {
            scope.idMap=attrs.idMap || "map";
            scope.width=attrs.width || '100%';
            scope.height=attrs.height || "500px";
            scope.type=attrs.type || "uffici";
            if(typeof attrs.hideSwitch != "undefined"){
            	scope.showSwitch=!attrs.hideSwitch;
            }
            else{
            	scope.showSwitch=true;
            }
            
        },
        controller: ['$scope', '$http', '$timeout', '$interval', '$rootScope', 'retinaDensityCheck', 'togglePopover', function($scope, $http, $timeout, $interval, $rootScope, retinaDensityCheck, togglePopover) {
            $scope.value;
            $scope.idMap=$scope.idMap || "map";
            $scope.searchCrit = ''; // variabile per altri criteri di ricerca 
            $scope.groups = [];
            $scope.merchantList = [];
            $scope.merchantList_online = [];
            $scope.showtab = false;
            $scope.activeTab = "merchants"; //tabs navigation
            $scope.mapInitialized = false;
            $scope.allVisible = true;
            $scope.selectedCategory = '';
            $scope.listUpSelected = null;
            
            $scope.selectUpFromList = function(up){
            	$scope.listUpSelected = up;
            }

            $scope.viewMap = true;

           var lastCenter = null,
            zoomThreshold = 12,
            offsetThreshold = 3000, //3km
            activateDragSearch = true,
            blockDragSearch = true,
            firstSearch = true,
            timeoutDragSearch = null,
            timeoutCluster = null,
            drag = false,
            timeoutBounds = null,
            mobiletest = false;
            
           $scope.geoProject = null;
           $scope.geoMap = null;
//           $scope.infoWindow = null;
            
            var geocoderOptions = {
                componentRestrictions: { country: 'IT' } //filtra Italia
            };
            
            $scope.$on('scanner-started', function(event, args) {
                if(args.idMap == $scope.idMap){
                	show_CustomUIKit_PageLoader();
                	var search = args.searchLoc;
                    $scope.frazionario = args.frazionario || null;
                    $scope.query = args.query || null;
                    $scope.distanza = args.distanza || null;
                	console.log('cerco sulla mappa '+$scope.idMap);
                	$scope.searchMerchantByLocation(search, $scope.frazionario, $scope.query, $scope.distanza);
                }
            });
            
            
            $scope.$on('google', function(event, args) {
            	console.log('setto $scope.geoProject a null su mappa ' + $scope.idMap);
                $scope.geoProject = null;
                
            	console.log('on google + '+$scope.idMap);
            	
            	//INIZIALIZZAZIONE DELLA MAPPA
                var mapOptions = {
                    center: new google.maps.LatLng(41.893056, 12.482778),
                    zoom: 5,
                    minZoom: 6,
                    maxZoom: 20,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    overviewMapControl: true,
                    overviewMapControlOptions: {
                        opened: mobiletest ? false : true
                    }
                };

                $scope.googleMap = new google.maps.Map($('#'+$scope.idMap)[0], mapOptions);
                window.googleMaps[$scope.idMap] = $scope.googleMap;
                
                $scope.geocoder = new google.maps.Geocoder(geocoderOptions);
                
                google.maps.event.addListener($scope.googleMap, 'click', function() {
                    $scope.geoProject.closeAllInfowindow();
                });     
                
                google.maps.event.addListener($scope.googleMap, 'zoom_changed', function() {
                    console.log('ZOOM');
                    if (!blockDragSearch) {
                        var zoom = $scope.googleMap.getZoom();
                        if (zoom >= zoomThreshold) activateDragSearch = true;
                        else activateDragSearch = false;
                    }
                });

                //listener cambio bounds (drag e anche zoom, cambio area visualizzata)
                google.maps.event.addListener($scope.googleMap, 'bounds_changed', function() {

                    if(timeoutBounds){
                        $timeout.cancel(timeoutBounds);
                    }
                    
                    lastCenter = $scope.valueLastCenter();

                    timeoutBounds = $timeout(function() {
                        console.log('bounds change');
                        google.maps.event.addListenerOnce($scope.googleMap, 'idle', function() {
                            if (activateDragSearch && !blockDragSearch) {
                                //lastcenter = punto settato dopo prima ricerca
                                if (lastCenter) {
                                    var offset = $scope.calculateOffset()-100;
                                    console.info("offset: "+offset);
                                    //se lo spostamento è > della soglia
                                    if (offset > offsetThreshold) {
                                    	$scope.dragSearch();
                                    }
                                } else {
                                	$scope.dragSearch();
                                }
                            }
                        });              
                    }, 500)

                });
                if(!document.geocmsScriptCalled){
                	document.geocmsScriptCalled = true;
                	var geocmsScript = amaconfig[$scope.type].geoUrl;
//   	            	var geocmsScript = typeof(ama_geocms_script)!='undefined'?ama_geocms_script:"https://www.geocms.it/API/geocms/js?v=3&license=geo-posteitalianespa&project=Uffici&modules=infobubble|markerclustererplus&callback=geoCallback";	
   	            	console.log(geocmsScript);
                	addScriptToDom(geocmsScript);
                }
                else if(document.geocmsScriptLoaded){
                	$scope.geoCmsLoadedCallback();
                }
                
            });

            $scope.$on('geolocation', function(event, args) {
            	if(args.idMap == $scope.idMap){
            		console.log('geolocalizzo sulla mappa '+$scope.idMap);
	                navigator.geolocation.getCurrentPosition(function(pos) {
	                	currentPos = new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude);
	                	
	                	$scope.geocoder.geocode({ location: currentPos }, function(results, status) {
	                        if (status == google.maps.GeocoderStatus.OK) {
	                            var result = results[0];
	                            console.info("RICERCA IN geolocalizzazione: "+result.formatted_address);
	                            
	                            if($('#'+args.location).val() != result.formatted_address){
	                            	$('#'+args.location).val(result.formatted_address);
	                            	$scope.frazionario = args.frazionario || null;
	                            	$scope.query = args.query || null;
	                            	$scope.distanza = args.distanza || null;
	                                $scope.searchMerchantByLocation(result.formatted_address, $scope.frazionario, $scope.query, $scope.distanza);
	                            }
	                        }
	                    });
	                    
	                	// $rootScope.$broadcast('scanner-started', {idMap: args.idMap, searchLoc: $scope.searchLoc, google: google, frazionario: frazionario, query: query});
	                }, errorGettingPosition, {
	                    'enableHighAccuracy': true,
	                    'timeout': 10000,
	                    'maximumAge': 0
	                });
            	}
            });

            function errorGettingPosition(err) {
                if(err.code == 1) {
                    console.log("L'utente non ha autorizzato la geolocalizzazione");
                } else if(err.code == 2) {
                	console.log("Posizione non disponibile");
                } else if(err.code == 3) {
                	console.log("Timeout");
                } else {
                	console.log("ERRORE:" + err.message);
                }
            }
            
            $scope.hideorshowmap = function(){
                $scope.viewMap = !$scope.viewMap;
            };

            mobiletest = $('#mobileTest').is(':visible');

            //calcola l'offset tra centro attuale e ultimo cercato
            $scope.calculateOffset = function() {
                var mapCenter = $scope.googleMap.getCenter();
                var distance = google.maps.geometry.spherical.computeDistanceBetween(mapCenter, lastCenter);
                //console.log(distance);
                return distance;
            };

            
            $scope.initializeTimeout = function(){
                return $timeout;
            };
            
            $scope.valueLastCenter = function(){
                return lastCenter;
            };
            

            //effettua una ricerca triggerando il drag della mappa
            $scope.dragSearch = function() {

//                if (timeoutDragSearch !== null) {
//                    console.log('reset');
//                    clearTimeout(timeoutDragSearch);
//                }
//
//                //evito che si accodino troppe ricerche
//                timeoutDragSearch = setTimeout(function() {
//                    console.log('dragsearch');
//                    var center = $scope.googleMap.getCenter();
//                    $scope.geocoder.geocode({ location: center }, function(results, status) {
//                        if (status == google.maps.GeocoderStatus.OK) {
//                            var result = results[0];
//                            console.info("RICERCA IN ZOOM: "+result.formatted_address);
//                            
//                            $scope.$apply();
//                            if($('#ama-autocomplete').val() != result.formatted_address){
//                                $('#ama-autocomplete').val(result.formatted_address);
//                                $scope.searchMerchantByLocation(result.formatted_address, null, null, null);
//                            }
//                            
//                        }
//                    });
//                }, 1000)
            }; //end dragSearch
            
            
            //******************************************//
            
            
            $scope.enableMap = function() {
                google.maps.event.addListenerOnce($scope.googleMap, 'idle', function() {
                	$scope.geoProject.show();
//                    $scope.$apply();
                    shakeMap();
                    google.maps.event.trigger($scope.googleMap,'resize');
                });
                $scope.googleMap.setOptions({
                    disableDefaultUI: false,
                    draggable: true,
                    scrollwheel: true,
                    styles: null,
                    overviewMapControlOptions: {
                        opened: true
                    }
                });
                shakeMap();
            };
            
            $scope.searchMerchantByLocation = function(search, frazionario, query, distanza) {

            	console.log('search: '+search + ' - frazionario: ' +frazionario);
//                    $scope.searchLoc = search != undefined ? search : $scope.searchLoc;
//
//                    if($scope.searchLoc != null && $scope.searchLoc != undefined){

//                        if (!drag){
                            
//                        }
                        if($scope.geoProject != null && $scope.geoProject != undefined){
                        	$scope.geoProject.clear();    
                        }else{
//                        	$scope.geoProject = 
                        	$scope.initializeCms();
                        	$scope.geoProject.clear();
                        }
                        
                        $scope.enableMap();
                        $scope.searchCrit = '';
                        $scope.allVisible = true;
                        $scope.selectedCategory = '';

                        $scope.merchantList = [];
                        $scope.merchantList_online = [];
                        $scope.geoCMSRequestParam = {};                   
                        $scope.groups = [];
                        
                        console.log('geoProject '+$scope.idMap);
                        console.log($scope.geoProject);

                        if(search != null && search != undefined){
                        	
	                        $scope.geocoder.geocode({ address: search, componentRestrictions: { country: 'IT' } }, function(results, status) {
	                            if (status == google.maps.GeocoderStatus.OK) {
	                            	var results = results.filter(function(obj){
	                					return !obj.partial_match;
	                				});
	                            	
	                            	if(results.length>0){
		                                var result = results[0];
		
		                                console.log(result);
		
		                                if (!drag) $scope.googleMap.setCenter(result.geometry.location); //per bind focus non sui risultati ma su area, con focus:false nella request 
		                                // $scope.googleMap.center = { latitude: result.geometry.location.lat(), longitude: result.geometry.location.lng() };
		                                //salvo il centro trovato per calcolare l'offset per la dragSearch
		                                lastCenter = result.geometry.location;
		                                $scope.googleMap.setZoom(15);
		
		                                var boundsToViewport = result.geometry.viewport;
		
		                                if(distanza){
		                                	var distance = distanza;
		                                }
		                                else{
		                                	var distance = 5000; // calcViewportRadius(boundsToViewport);
		                                }
		                                var point = result.geometry.location;
		
		                                var geoCMSRequestParam = {
		                                    point: {
		                                        lat: point.lat(),
		                                        lng: point.lng()
		                                    },
		                                    loadingOverlay: true, //mostra un overlay di attesa
		                                    maxResult:amaconfig[$scope.type].maxResultFisici, //massimo numero di risultati che saranno mostrati
		                                    distanza: distance, //saranno mostrati al massimo risultati entro 30 km dal punto 
		                                    focus: true, 
		                                    maxZoomLevel: 16
		                                };
		                                if(frazionario){
		                                	geoCMSRequestParam.idObject = frazionario;
		                                }
		                                if(query){
		                                	geoCMSRequestParam.query = query;
		                                }
		
		                                geoCMSSearch(geoCMSRequestParam);
	                            	}
	                            	else{
	                            		$rootScope.$broadcast('searchData', { idMap: $scope.idMap, response: [], distanza: distanza, point: point});
	                            	}
	
	                            } else {
	                            	$rootScope.$broadcast('searchData', { idMap: $scope.idMap, response: [], distanza: distanza, point: point});
	                                //errore geocodifica
	                                console.log('geocoder error');
	                                console.log(status);
	                            }
	                        });
                        
                        }
                        else{
                        	$scope.googleMap.setZoom(15);
                        	var geoCMSRequestParam = {
                                loadingOverlay: true, //mostra un overlay di attesa
                                focus: true,
                                maxZoomLevel: 16
                            };
                            if(frazionario){
                            	geoCMSRequestParam.idObject = frazionario;
                            }
                            if(query){
                            	geoCMSRequestParam.query = query;
                            }
                            
                            blockDragSearch = true;
                            google.maps.event.addListenerOnce($scope.googleMap, 'idle', function() {
                                //sblocco la ricerca a seguito del drag per evitare che scatti una ricerca full sulla posizione
                                blockDragSearch = false;
                                lastCenter = $scope.googleMap.getCenter();
                            });

                            geoCMSSearch(geoCMSRequestParam);
                        }

//                    }
            };
            
            
            //INIT cms SCUBE
            $scope.initializeCms = function() {
            	console.log('initialize cms: '+$scope.idMap);
                var htmlOverlayNode = document.getElementById("loadingOverlay");
                var geoMapOptions = {
                    overlayNode: $('#loadingOverlay').get(0)
                };
                $scope.geoMap = new scube.geocms.GeoMap($scope.googleMap, geoMapOptions);
                $scope.projectOptions = {
                    id: amaconfig[$scope.type].project,
                    name: amaconfig[$scope.type].project,
                    license: amaconfig[$scope.type].license,
                    frontend: 'apiv3',
                    markerCluster: window.markerClusterer[$scope.idMap],
                    styleProcess: function(obj2beProcess) {
                    	var obj = obj2beProcess.getAllUserdata();
                    	var style=amaconfig[$scope.type].markerStyle;
                    	if($scope.type=="uffici" && obj.uff_appoggio){
                    		style="closed";
                    	}
                    	return {
                    		'styleID': style
                    	};
                    }
                	

                };

                if($scope.showSwitch){
                	$scope.projectOptions['infoWindowProcess'] = infoWindowProcess;
                	$scope.projectOptions['infoWindowOptions'] = {
                        infoClass: InfoBubble,
                        appendToMapDiv: true,
                        infoClassOptions: {
                            backgroundColor: 'white',
                            padding: 5, //setto la grandezza del contorno
                			borderWidth: 0, //setto la grandezza del bordo
                			borderRadius: 0, //setto la grandezza del bordo arrotondato
                			hideCloseButton: true, //nascondo il pulsante di chiusura di default (verrà customizzato nella infowindowProcess)
                            arrowPosition: 30,
                            minWidth: $scope.width.replace('px','')-130,
                            maxWidth: $scope.width.replace('px','')-130,
                            minHeight: 180
//                            padding: 0
                        }
                    };
                	
//                	google.maps.event.addListener(infowindow, 'domready', function() {
//
//                 	   var iwOuter = $('.poste-info-window');
//
//                 	   iwOuter.prev().addClass('card').css({'background-color':'#ffffff'});
//
//                 	   iwOuter.prev().addClass('panel');
//
//                 	});
                	
                }
                
//                else{
//                	$scope.projectOptions['styleProcess'] = styleProcess;
//                }
                window.geoMap[$scope.idMap] = $scope.geoMap;
                $scope.geoProject = $scope.geoMap.addProject($scope.projectOptions);
                $scope.geoProject.loadMarkerStyle('default', {
                    defaultState: {
                        icon: { url: '/rpol/public/common-modules/directives/cerca-up-directive/up.png', scaledSize: { width: 32, height: 40 } }
                    }
                });
                $scope.geoProject.loadMarkerStyle('cassette', {
                    defaultState: {
                        icon: { url: '/rpol/public/common-modules/directives/cerca-up-directive/cassette.png', scaledSize: { width: 32, height: 40 } }
                    }
                });
                $scope.geoProject.loadMarkerStyle('closed', {
                    defaultState: {
                        icon: { url: '/rpol/public/common-modules/directives/cerca-up-directive/up-closed.png', scaledSize: { width: 32, height: 40 } }
                    }
                });
                $scope.geoProject.addInfowindowDOMReady(function() {
                	console.log('infowindow');
              	    var iwOuter = $('.poste-info-window');

               	    iwOuter.parent().addClass('card').css({'background-color':'#ffffff', 'border':'0'});

              	    iwOuter.parent().parent().addClass('panel').css({'margin-bottom':'0'});

              	});
                window.geoProject[$scope.idMap] = $scope.geoProject;
            };
            
            
            function infoWindowProcess(obj) {
            	console.log('infowindow2');
                var infoWindowHTML = [];
                
                var userdata = obj.getAllUserdata(); //dati dell'esercizio

                infoWindowHTML.push('<div class="row poste-info-window" style="font-family: \'Texta\', sans-serif;">');
                infoWindowHTML.push('<div class="content-tiket-details">');
                infoWindowHTML.push('<div class="col-md-12">');
                infoWindowHTML.push('<div class="row">');
                infoWindowHTML.push('<span class="office-name col-sm-9 spacer-xs-bottom-10 col-xs-9 innerspacer-xs-left-5 ng-binding" style="font-size:17px">'+userdata.descrizione);
                infoWindowHTML.push('<span class="spacer-xs-top-10 block result ng-binding" style="font-size:15px">'+userdata.indirizzo+', '+userdata.cap+' '+userdata.descr_comune+' ('+userdata.cod_provincia+')</span>'); 
                infoWindowHTML.push('</span>');
                if(userdata.GeoCMS_Distance){
                	infoWindowHTML.push('<span class="office-distance col-sm-2 hidden-xs text-sm-right spacer-sm-bottom-0">');
                	infoWindowHTML.push('<span class="counter ng-binding">'+distanceFunction(userdata.GeoCMS_Distance) + '</span> ');
                	infoWindowHTML.push('</span>');
                }
                infoWindowHTML.push('<span class="office-distance col-xs-3 text-md-right spacer-xs-bottom-10 spacer-md-bottom-0">');
                infoWindowHTML.push('<span class="ng-binding" style="font-size: 25px; margin-top: -26px; position: absolute; cursor:pointer;" {$CloseAction}>&times;');
                infoWindowHTML.push('</span> </span>');
                if(userdata.GeoCMS_Distance){
                	infoWindowHTML.push('<span class="office-distance col-xs-12 visible-xs spacer-xs-bottom-10 ">');
                	infoWindowHTML.push('<span class="counter ng-binding">'+distanceFunction(userdata.GeoCMS_Distance) + '</span> ');
                	infoWindowHTML.push('</span>');
                }
				infoWindowHTML.push('</div>');
				infoWindowHTML.push('</div>');
				infoWindowHTML.push('<div class="col-md-offset-0 col-md-12 col-xs-12">');
				infoWindowHTML.push('<span class="change-office block result text-sm-left">');
				infoWindowHTML.push('<a style="font-size:15px" href="javascript:window.selectUp[\''+$scope.idMap+'\']('+obj.id+');" title="Seleziona ufficio postale" class="cursor-pointer">Seleziona ufficio postale</a>');
				infoWindowHTML.push('</span>');
				infoWindowHTML.push('</div>');
				infoWindowHTML.push('</div>');
				infoWindowHTML.push('</div>');
			
			
//                infoWindowHTML.push('<div class="panel-wrap poste-info-window" style="padding:15px;">');
//                infoWindowHTML.push('<div class="panel-heading row" style="min-height:auto;">');
//                infoWindowHTML.push('<div class="col-sm-12 panel-heading-subtitle"><span style="font-size:18px;">'+userdata.descrizione+'</span>');
//                infoWindowHTML.push('<span class="spacer-xs-top-10 block" style="border-bottom:1px solid #ececec;margin-bottom:15px;padding-bottom:5px">'+userdata.indirizzo+', '+userdata.cap+' '+userdata.descr_comune+' ('+userdata.cod_provincia+')</span>');
//                infoWindowHTML.push('</div>');
//                if(userdata.GeoCMS_Distance){
//                	infoWindowHTML.push('<div class="panel-link panel-link-mt"><span class="distance ng-binding ng-scope">'+distanceFunction(userdata.GeoCMS_Distance) + '</span></div>');
//                }
//                infoWindowHTML.push('</div>');
//                infoWindowHTML.push('<div class="panel-link panel-link-pos1" style="bottom:5px;">');
//                infoWindowHTML.push('<a href="javascript:window.selectUp[\''+$scope.idMap+'\']('+obj.id+');" title="Seleziona ufficio postale" class="btn-card"><span>Seleziona ufficio postale</span></a>');
//                infoWindowHTML.push('</div>');
//                infoWindowHTML.push('</div>');

//                $rootScope.$broadcast(\'upSelected\', { loc: userdata});
//                infoWindowHTML.push('<div class="card">');
//                infoWindowHTML.push('<div class="row">');
//                infoWindowHTML.push('<div class="content-tiket-details">');
//                infoWindowHTML.push('<div class="col-md-12">');
//                infoWindowHTML.push('<div class="row"> <span class="office-name col-sm-7 spacer-xs-bottom-10 col-xs-12 innerspacer-xs-left-5">'+userdata.descrizione+'<span class="spacer-xs-top-10 block result">'+ userdata.indirizzo +'</span> </span> <span class="office-services col-sm-3 spacer-xs-bottom-10  col-xs-12"><span class="block result"></span></span> <span class="office-distance col-sm-2 col-xs-12"><span class="counter">'+distanceFunction(userdata.GeoCMS_Distance)+'</span> </span></div>');
//                infoWindowHTML.push('</div>');
//                infoWindowHTML.push('<div class="col-md-offset-8 col-md-4 col-xs-12"><span class="change-office spacer-xs-top-10 block result"><a href="#">Seleziona ufficio postale</a></span>');
//                infoWindowHTML.push('</div>');
//                infoWindowHTML.push('</div>');
//                infoWindowHTML.push('</div>');
//                infoWindowHTML.push('</div>');
            
                var compiled = infoWindowHTML.join('');
                
                
                
                return compiled;

            }
            
//            function styleProcess(obj){
//            	console.log(obj.getGeoElement());
//            	var marker = obj.getGeoElement().getOverlay();
//            	console.log(marker);
//            	google.maps.event.addListener(marker,'click',function(){
//            		console.log('popup');
//            	});
//            	return {title:obj.description};
//            }
            
            
            //Calcola raggio dalla viewport
            function calcViewportRadius(bounds) {
                var p1 = bounds.getCenter(); //centro viewport
                var p2 = bounds.getSouthWest(); //punto basso-sin viewport

                var distance = google.maps.geometry.spherical.computeDistanceBetween(p1, p2);

                if (distance < amaconfig[$scope.type].minRaggio) {
                    distance = amaconfig[$scope.type].minRaggio;
                } else if (distance > amaconfig[$scope.type].maxRaggio) {
                    distance = amaconfig[$scope.type].maxRaggio;
                }
                console.log('Raggio: ' + distance);
                return Math.floor(distance);
            }
            
            
            var geoCMSSearch = function(searchParams) {
//                if (firstSearch) {
                	console.log('firstSearch');
                    //idle è un evento che si verifica quando la mappa è 'ferma' 
                    google.maps.event.addListenerOnce($scope.googleMap, 'idle', function() {
                        //sblocco la ricerca a seguito del drag (prima della prima ricerca è bloccata)
                        blockDragSearch = false;
                        google.maps.event.trigger($scope.googleMap, 'zoom_changed');
                    });
                    firstSearch = false;
//                }

                console.log('prima di geoProject.searchData');
                
                console.log($scope.geoProject);

                $scope.geoProject.searchData(searchParams, function(geoCMSResponse, success) {
                    $scope.activeTab = "merchants";
                    console.log('geoProject.searchData');
                    
                    console.log(geoCMSResponse);
                    //window.geoProject.mappaCerca.getObjectsLoaded().map['02126'].getGeoElements()[0].getOverlay()
                    
                    
                    //merchants fisici
                    populateMerchantList(geoCMSResponse.results, $scope.merchantList, geoCMSResponse.request.param.distanza, geoCMSResponse.request.param.point);

                    google.maps.event.addListenerOnce($scope.googleMap, 'idle', function() {
                        var zoom = $scope.googleMap.getZoom();
                        //setto lo zoom massimo a quello iniziale (se minore di 20)
                        // if(zoom < 17){
                        //     googleMap.setOptions({minZoom:zoom})
                        // }else{
                        //     googleMap.setOptions({minZoom:17})
                        // }

                        //setto la soglia di offset al raggio della viewport
                        var mapBounds = $scope.googleMap.getBounds();
                        var offset = /*$scope.*/ calcViewportRadius(mapBounds)-100;
                        offsetThreshold = offset;
                        //zoomThreshold = googleMap.getZoom();                  
                    });
                });
            };
            
            
            var populateMerchantList = function(geoCMSResponseResults, merchantList, distanza, point) {
            	var listaRisultati = [];
                for (var i = 0; i < geoCMSResponseResults.size(); i++) {
                    var merchant = geoCMSResponseResults.getAt(i);
                    if($scope.openPopup){
	                    var marker = merchant.getGeoElements()[0].getOverlay();
	                    google.maps.event.addListener(marker,'click',function(e){
	                    	$scope.openPopup(e.getAllUserdata());
	                  	}.bind(null, merchant));
                    }
                    console.log(merchant.getAllUserdata());
                    
                    listaRisultati.push(merchant);
                    var merc = merchant.getAllUserdata();
                    if(merc.G && merc.G.prodotti){
        				for(var j in merc.G.prodotti){
        					var prod = merc.G.prodotti[j];
        					if(prod.id_prodserv == "117"){
        						merc.wifi = true;
        					}
        					if(prod.id_prodserv == "-10"){
        						merc.ticket = true;
        					}
        				}
                    }
                    merchantList.push(merc);
//                    var category = merchant.getUserdata('ind_desc');
//
//                    // if ($scope.categories[category] === false) {
//                    //     $scope.categories[category] = true;
//                    // }
//                    if (!$scope.groups[category]) {
//                        $scope.groups[category] = [];
//                    }
//                    $scope.groups[category].push(merchant);
                }
                $timeout(function(){
                    $scope.$apply();
                    shakeMap();
                    if(typeof customRadio != undefined) customRadio();
                },0);
                
                $rootScope.$broadcast('searchData', { idMap: $scope.idMap, response: listaRisultati, distanza: distanza, point: point});
            };
            
            
            geoCallback = function() { // Geo callback
            	console.log('geoCallback '+$scope.idMap);
            	document.geocmsScriptLoaded = true;
            	$rootScope.$broadcast('geoCmsLoaded');
            }
            
            $scope.$on('geoCmsLoaded', function(){
            	$scope.geoCmsLoadedCallback();
            }); //geo_callback end
            
            $scope.geoCmsLoadedCallback = function(){
            	console.log('geoCmsLoadedCallback '+$scope.idMap);
            	//opzioni per la classe di gestione dei cluster
                var clustererOptions = {
                    maxZoom: 17, //livello massimo di zoom in cui viene eseguita la clusterizzazione
                    gridSize: 30, // grandezza(in pixel) dell'area che viene clusterizzata
                    averageCenter: true, //posizionamento dell'icona del cluster sul suo baricentro
                    ignoreHidden: true, //ignora i punti nascosti
                    minimumClusterSize: 5,
                        //title: 'clicca per '
                    styles:[
                        {
                            url: amaconfig[$scope.type].clusterMarker,
                            width: 47,
                            height: 47,
                            textColor: amaconfig[$scope.type].clusterColor,
                            textSize: 17
                        }
                    ]
                };

                window.markerClusterer[$scope.idMap] = new MarkerClusterer($scope.googleMap, null, clustererOptions);

//                function initAll() {
//                    // show the map and place some markers
//                    // geoProject = 
//                    $scope.initializeCms();
//                    //loadStyles();
//                }
//
//                initAll();

                $scope.disableMap = function() {

                    google.maps.event.addListenerOnce($scope.googleMap, 'idle', function() {
                    	$scope.geoProject.hide();
                    });


                    $scope.googleMap.setOptions({
                        disableDefaultUI: true,
                        draggable: false,
                        scrollwheel: false,
                        overviewMapControlOptions: {
                            opened: false
                        },
                        styles: [{
                            "featureType": "road",
                            "stylers": [
                                { "visibility": "off" }
                            ]
                        }, {
                            "featureType": "poi",
                            "stylers": [
                                { "visibility": "off" }
                            ]
                        }, {
                            "featureType": "landscape",
                            "stylers": [
                                { "visibility": "off" }
                            ]
                        }, {
                            "featureType": "transit",
                            "stylers": [
                                { "visibility": "off" }
                            ]
                        }]
                    });
                    $timeout(function(){
                        shakeMap();                 
                    }, 0);               
                };

//                $scope.showSelectedCategory = function(category){
//                    $scope.allVisible = false;
//                    $scope.categories[category] = true;
//                    $scope.selectedCategory = category;
//                    $timeout(function(){
//                        $scope.$apply();
//                        shakeMap();
//                        if($('#categoriesSelect .holder').text() !== $scope.selectedCategory){
//                            $('#categoriesSelect .holder').text($scope.selectedCategory);
//                        }
//                    }, 300);
//
//
//                };

                $scope.checkCluster = function() {
                    if (timeoutCluster) {
                        clearTimeout(timeoutCluster);
                    }

                    timeoutCluster = setTimeout(function() {
                        //markerClusterer.repaint();
                        shakeMap();
                        //$scope.clusterChecked = true;
                    }, 500);               
                };

//                window.onresize = function(event) {
//                    console.log('resize');
//                    mobiletest = $('#mobileTest').is(':visible');
//                    try{
//                        if(mobileTest){
//                            $scope.searchCrit = '';
//                        }
//                    }catch (e){
//
//                    }
//                };
                
                console.log("amaLoaded "+ $scope.idMap);
                $rootScope.$broadcast("amaLoaded", {idMap: $scope.idMap});
            }

            function shakeMap(event){
                window.markerClusterer[$scope.idMap].repaint();           
                $scope.googleMap.panBy(0, 1);
                $scope.googleMap.panBy(0, -1);
            }
            
            $scope.selectUp = function(selection, offices){
            	angular.forEach(offices, function(pos, index) {
            	    if (selection != index){ 
            	    	pos.checked = false;	
            	    }
            	    else{
            	    	$rootScope.$broadcast('upSelected', { loc: pos});
            	    }
            	});
            	
            }
            
            $scope.closeSelectUp = function(){
            	$rootScope.$broadcast('upSelected', {});
            }
            
            window.selectUp[$scope.idMap] = function(id){
            	angular.forEach($scope.merchantList, function(pos, index) {
            	    if (pos.frazionario_ext == id){ 
            	    	$rootScope.$broadcast('upSelected', { loc: pos});
            	    }
            	});
            	
            }

        }]
    };
}]).config(['$locationProvider', function($locationProvider) {
    $locationProvider.html5Mode({
	  //enabled: false,
	  requireBase: false
	});
}]);

function addScriptToDom(url) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    $('body').append(script);
}
