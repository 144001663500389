angular.module('services.libretto').factory('LibrettoService',
	['$resource', '$filter', 'AppConfs',
		function ($resource, $filter, AppConfs) {

			var requestMilliseconds = new Date().getTime();
			var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
			var nomeServizio = '/rpolGestioneLibretto';

			return $resource(
				AppConfs.general.restServices.baseContext + nomeServizio + '/:prefix/:package/:action',
				{}, {
					listaMovimenti: {
						method: 'POST',
						headers: {
							//Authorization: BPOLService.authorizationHeaderVal,
							//Username: BPOLService.usernameHeaderVal,
							//Canale: BPOLService.canaleHeaderVal,
							//requestID : 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "gestioneRapporto",
							action: "listaMovimenti"
						}
					},
					listaOperazioniRapporto: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "gestioneRapporto",
							action: "listaOperazioniRapporto"
						}
					},
					precompila: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "gestioneRapporto",
							action: "precompila"
						}
					},
					downloadMovimenti: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "gestioneRapporto",
							action: "downloadMovimenti"
						}
					},
					dettaglio: {
						method: 'POST',
						headers: {
							//Authorization: BPOLService.authorizationHeaderVal,
							//Username: BPOLService.usernameHeaderVal,
							//Canale: BPOLService.canaleHeaderVal,
							//requestID : 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "gestioneRapporto",
							action: "dettaglio"
						}
					},
					pinStatus: {
						method: 'POST',
						headers: {
							//Authorization: BPOLService.authorizationHeaderVal,
							//Username: BPOLService.usernameHeaderVal,
							//Canale: BPOLService.canaleHeaderVal,
							//requestID : 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "gestionePinDematerializzato",
							action: "getPinStatus"
						}
					},
					sendOTP: {
						method: 'POST',
						headers: {
							//Authorization: BPOLService.authorizationHeaderVal,
							//Username: BPOLService.usernameHeaderVal,
							//Canale: BPOLService.canaleHeaderVal,
							//requestID : 'RPOL.WEB.'+BPOLService.usernameHeaderVal()+requestMilliseconds,
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							package: "gestionePinDematerializzato",
							action: "sendOTP"
						}
					}
				}
			);
		}]);