angular.module('services.scaUtils').factory('ScaUtilsService', function () {

    var factory = {};

    factory.checkFase = function (url) {
        if (url) {
            var urlJson = parseUrl(url);
            if (urlJson.flusso && (urlJson.statoOpaco || urlJson.errore)) {
                return urlJson;
            }
            return null;
        } else {
            return null;
        }

    }

    factory.checkAuthFlow = function (url) {
        if (url) {
            var urlJson = parseUrl(url);
            if (urlJson.movementId || urlJson.error) {
                return urlJson;
            }
            return null;
        } else {
            return null;
        }

    }

    factory.checkOrigin = function (url) {
        if (url) {
            var urlJson = parseUrl(url);
            if (urlJson.origin) {
                return urlJson;
            }
            return null;
        } else {
            return null;
        }

    }


    function parseUrl(url) {
        var result = {};
        url.split("&").forEach(function (part) {
            var item = part.split("=");
            if (item[0].toLowerCase() === "errore")
                result[item[0]] = decodeURIComponent(JSON.parse('"' + item[1].replace(/\"/g, '\\"') + '"'));
            else
                result[item[0]] = decodeURIComponent(item[1]);
        });
        return result;
    }

    return factory;
});