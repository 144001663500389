angular.module('authorizationBox').component('authorizationBox', {
    bindings: {
        confs: '<',
        onCompleteSecureCode: '&',
        onRichiediOtpError: '&',
        webtrendsData: '<'
    },
    templateUrl: '/rpol/private/pages/common-templates/authorization-box.template.html',
    controller: ['$log', 'UikitProxy', 'WebtrendsService', 'SatelliteService', '$timeout',
        function AuthorizationBoxController($log, UikitProxy, WebtrendsService, SatelliteService, $timeout) {

            var vm = this;

            //AuthBox var
            vm.showInputCode = false;
            vm.showLink = false; //visualizzazione link richiedi nuovo SMS
            vm.authConfs = {};
            vm.authConfs.currTry;
            vm.authConfs.maxTry;
            vm.authConfs.secureCode = new Array(8);
            vm.authConfs.numeroCellulare = '';
            vm.authConfs.satelliteParameters;


            //AuthBox Funs
            vm.getCodeSubmit_Handler = getCodeSubmit_Handler;
            vm.checkCode = checkCode;
            vm.selectAll = selectAll;

            vm.$doCheck = doCheck;
            vm.reset = reset;
            vm.$postLink = postLink;

            function getCodeSubmit_Handler() {

                show_CustomUIKit_PageLoader(1);

                vm.reset();
                //Webtrends click su Richiedi Codice
                if (typeof vm.webtrendsData !== 'undefined')
                    WebtrendsService.WT_richiedi_codice_certificazione(vm.webtrendsData.dcsuri, vm.webtrendsData.dl, vm.webtrendsData.si_n, vm.webtrendsData.si_x);
                    //chiamata satellite prima del click su richiedi otp
          if (vm.confs.satelliteParameters && vm.confs.satelliteParameters !== null &&
            vm.confs.satelliteParameters !== undefined && vm.confs.satelliteParameters.length > 0) {
            $log.log('Satellite Working OTP');
            var alias_app = vm.confs.satelliteParameters[0];
            var userid = vm.confs.satelliteParameters[1];
            var nome_scenario = vm.confs.satelliteParameters[2];
            var numero_step = vm.confs.satelliteParameters[3];
            var custom_data = vm.confs.satelliteParameters[4];
            
            SatelliteService.track(alias_app,userid,nome_scenario,numero_step,custom_data);

          }

                //vm.authConfs.numeroCellulare = vm.confs.numeroCellulare;

                //$log.log('AuthorizationBoxController getCodeSubmit_Handler vm.authConfs.numeroCellulare :' + vm.authConfs.numeroCellulare);

                $log.log('AuthorizationBoxController getCodeSubmit_Handler vm.confs.movementId : ', vm.confs.movementId);
              
                vm.confs.service.richiediOtp(undefined,
                    {
                        data: {
                            movementId: vm.confs.movementId
                        }
                    },
                    function (response) {//200
                        $log.log('AuthorizationBoxController GirofondoLCService.richiediOtp responseOK:', response);

                        if (response.data) {
                            vm.authConfs.currTry = response.data.tentativoWebSessionId;
                            vm.authConfs.maxTry = response.data.massimoNumeroTentativi;
                            vm.authConfs.numeroCellulare = response.data.numeroCellulareAsteriscato;
                            vm.showInputCode = true;
                        }

                        hide_CustomUIKit_PageLoader();

                        //Link per richiedere un nuovo SMS compare dopo 90 secondi
                        $timeout(function () {
                            $log.log('AuthorizationBoxController .richiediOtp responseOK timeout dhow link new SMS ');
                            vm.showLink = true;
                        }, 90000);

                    },
                    function (httpResponse) {//!200
                        $log.log('AuthorizationBoxController GirofondoLCService.richiediOtp responseKO:', httpResponse);

                        vm.onRichiediOtpError({ errorResponse: httpResponse });

                    }
                );
            }

            function checkCode(indice, event) {
                $log.log('AuthorizationBoxController checkCode secureCode: ', vm.authConfs.secureCode);

                vm.onCompleteSecureCode({ secureCode: vm.authConfs.secureCode.join('') });

                //SETTO IL FOCUS ALL'ELEMENTO SUCCESSIVO
                $log.log("AuthorizationBoxController doCheck event: ", event);
                if ((event >= 48 && event <= 57) || (event >= 96 && event <= 105)
                		|| (event == 229)) {
                    var nextElement = "campoPincode00" + (indice + 1);
                    jQuery('input[id="' + nextElement + '"]').focus();
                    jQuery('input[id="' + nextElement + '"]').select();
                    /* 	vm.authConfs.secureCode[indice] = null; */
                }
            }

            function selectAll(indice) {
                $log.log("AuthorizationBoxController click event: ", indice);
                var element = "campoPincode00" + (indice);
                jQuery('input[id="' + element + '"]').focus();
                jQuery('input[id="' + element + '"]').select();
            }


            function doCheck() {
                if (vm.confs.reset) {
                    $log.log("AuthorizationBoxController doCheck reset: ", vm.confs.reset);
                    vm.reset();
                    vm.confs.reset = false;
                }
            }

            function reset() {
                jQuery('input[id="campoPincode001"]').focus();

                $log.log("AuthorizationBoxController doCheck reset");
                vm.showInputCode = false;
                vm.showLink = false;
                vm.authConfs.secureCode = new Array(8);
                checkCode();
            }

            function postLink() {
                UikitProxy.applyPopover();
            }

        }]
}
);