angular.module('services.satellite').service('SatelliteService', [
	'$window', '$log', function($window, $log) {

		// track
		this.track = function(scenario_name, userid,
				id_direct_call, scenario_step, custom_data) {

			dataLayer = {
				'funnel' : {
					'scenario_name' : '' + scenario_name,
					'scenario_step' : '' + scenario_step
				}
			}

			if (custom_data) {
				for (var item in custom_data) {
					dataLayer.funnel[item] = custom_data[item];
				}
			}

			$log.log(dataLayer)
			if (typeof _satellite == 'object'
					&& typeof _satellite !== 'undefined') {
				_satellite.track(id_direct_call);
			}

		}

		// track
		this.trackPageView = function(id_direct_call, custom_data) {

			dataLayer = {};

			if (custom_data) {
				for (var item in custom_data) {
					dataLayer[item] = custom_data[item];
				}

			}

			$log.log(dataLayer)
			if (typeof _satellite == 'object'
					&& typeof _satellite !== 'undefined') {
				_satellite.track(id_direct_call);
			}

		}

		// track error
		this.track_error = function(error_track) {

			if (null != error_track) {
				
				if (Array.isArray(error_track)) {
					error_track = error_track[0];
				} else if (typeof (error_track) === 'object') {
					error_track = error_track[Object.keys(error_track)[0]];
				}

				dataLayer = {
					'text_err_alert' : '' + error_track
				};

				$log.log(dataLayer);
				if (typeof _satellite == 'object'
						&& typeof _satellite !== 'undefined') {
					_satellite.track('id_err_alert');
				}
			}

		}

	} ]);