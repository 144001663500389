angular.module('accordionPanel').component('accordionPanel', {
    bindings: {
        locked: "&",
        groupId: "@",
        multiOpen: "@",
        idHeading: "@",
        titleAccordion: "@",
        panelId: "@",
        sectionNumber: "@",
        isCollapsedByDefault: "=",
        accordionContainerId: "@",
        showGreenCheck: "<?",
        isDisabled: "<?"
    },
    templateUrl: '/rpol/public/views/common-templates/accordion-panel.template.html',
    transclude: true,
    controller: ['$log', '$element', '$scope', 'UikitProxy',
        function AccordionPanelController($log, $element, $scope, UikitProxy) {

            var _thisAccordionScopeOnEvent = this;

            // var myGroup = $('#' + _thisAccordionScopeOnEvent.accordionContainerId);
            // myGroup.on('show.bs.collapse', '.collapse', function () {
            //     myGroup.find('.collapse.in').collapse('hide');
            // });

            
            _thisAccordionScopeOnEvent.$postLink = function(){
                UikitProxy.applyPopover();
                // UikitProxy.applyDatePicker();
                UikitProxy.applyCustomCheckbox();
                UikitProxy.applyCustomSelect();
                UikitProxy.applyCustomRadio();
                $scope.$emit('accordion.postLink.done');
            }
            // _thisAccordionScopeOnEvent.$onInit = function(){
            //     _thisAccordionScopeOnEvent.collapsing = false;
            // };

            // _thisAccordionScopeOnEvent.collapse = function() {
            //     if(!_thisAccordionScopeOnEvent.locked() && !_thisAccordionScopeOnEvent.collapsing){
            //         var accordion = $element.find('#'+ _thisAccordionScopeOnEvent.idAccordion);
            //         accordion.off('shown.bs.collapse').on('shown.bs.collapse', function(){
            //             _thisAccordionScopeOnEvent.collapsing = false;
            //         }).off('hidden.bs.collapse').on('hidden.bs.collapse', function(){
            //             _thisAccordionScopeOnEvent.collapsing = false;
            //         });
            //         _thisAccordionScopeOnEvent.collapsing = true;
            //         _thisAccordionScopeOnEvent.isCollapsed = !_thisAccordionScopeOnEvent.isCollapsed;
            //         accordion.collapse('toggle');
            //         if (_thisAccordionScopeOnEvent.groupId && !_thisAccordionScopeOnEvent.multiOpen && !this.isCollapsed){
            //             $scope.$emit('accordion.group.ImOpen', this.groupId, _thisAccordionScopeOnEvent.idAccordion);
            //         }
            //     }
            // };


            // /* Possibili valori per action: show, hide, toggle */
            // $scope.$on("accordion.event", function (event, action, id) {
            //     if (id == _thisAccordionScopeOnEvent.idAccordion){
            //         switch (action){
            //             case "show":
            //                 _thisAccordionScopeOnEvent.isCollapsed = false;
            //                 break;
            //             case "hide":
            //                 _thisAccordionScopeOnEvent.isCollapsed = true;
            //                 break;
            //             case "toggle":
            //                 _thisAccordionScopeOnEvent.isCollapsed = !_thisAccordionScopeOnEvent.isCollapsed;
            //                 break;
            //         }

            //         $element.find('#'+ _thisAccordionScopeOnEvent.idAccordion).collapse(action);
            //     }
            // });

            // $scope.$on("accordion.closeAllOthers",function (event, groupId, id) {
            //     if (groupId == _thisAccordionScopeOnEvent.groupId && id != _thisAccordionScopeOnEvent.idAccordion){
            //         _thisAccordionScopeOnEvent.isCollapsed = true;
            //         $element.find('#'+ _thisAccordionScopeOnEvent.idAccordion).collapse('hide');
            //     }
            // });
        }
    ]
});