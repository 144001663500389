(function() {
    'use strict';

    angular
        .module('services.authenticationIdpService')
        .factory('AuthenticationIdpService', ['$resource', '$filter', 'AppConfs', AuthenticationIdpService]);

    function AuthenticationIdpService($resource, $filter, AppConfs) {

        var requestMilliseconds = new Date().getTime();
        var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');


        return {
            call : function(url){
                return  $resource(
                    url , {}, {
                        openidToken: {
                            method: 'POST',
                            headers: {
                                requestID: 'RPOL.WEB.' + requestMilliseconds,
                                requestTimestamp: dt,
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            withCredentials: true
                        }
                    });
            }
        }  
    }
})();