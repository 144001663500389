angular.module('services.librettoMinori').factory('LibrettoMinoriService',
	['$resource', '$filter', 'AppConfs',
		function ($resource, $filter, AppConfs) {

			var requestMilliseconds = new Date().getTime();
			var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
			var nomeServizio = '/rpolLibrettoMinoriService';

			return $resource(
				AppConfs.general.restServices.baseContext + nomeServizio + '/:prefix/:action',
				{}, {
					verifica: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							prefix: "librettoMinori",
							action: "verifica"
						}
					},
					infoEmissione: {
						method: 'GET',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							prefix: "librettoMinori",
							action: "infoEmissione"
						}
					},
					info: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							prefix: "librettoMinori",
							action: "info"
						}
					},
					alias: {
						method: 'POST',
						headers: {
							requestID: 'RPOL.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
						params: {
							prefix: "aliasLibrettoMinori",
							action: "alias"
						}
					}
				});
		}]);
