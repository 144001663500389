angular.module('sezioneBuoniPossedutiValoreNominaleRimborsoLordo').component('sezioneBuoniPossedutiValoreNominaleRimborsoLordo', {
    bindings: {
		inputParams: '<'
    },
    templateUrl: '/rpol/private/pages/common-templates/sezione-buoni-posseduti-valore-nominale-rimborso-lordo.template.html',
    controller: ['$log', 'UikitProxy', 'WebtrendsService', '$timeout',
        function SezioneBuoniPossedutiValoreNominaleRimborsoLordoController($log, UikitProxy, WebtrendsService, $timeout) {

            var vm = this;
			
			//SezioneBuoniPossedutiValoreNominaleRimborsoLordo vars
			vm.confs = {};
			
			//SezioneBuoniPossedutiValoreNominaleRimborsoLordo funcs
			vm.$doCheck = doCheck;
            vm.$postLink = postLink;
	
			
			function doCheck() {
				//$log.log('SezioneBuoniPossedutiValoreNominaleRimborsoLordoController vm.inputParams.confs:', vm.inputParams);
				//$log.log('SezioneBuoniPossedutiValoreNominaleRimborsoLordoController vm.confs:', vm.confs);
				
				UikitProxy.applyPopover();
				
				if (vm.inputParams && vm.inputParams.entrato !== true ){
					vm.confs = vm.inputParams;
					//$log.log('SezioneBuoniPossedutiValoreNominaleRimborsoLordoController doCheck vm.inputParams.invocato=' + vm.inputParams.listaMovimenti);
					vm.inputParams.entrato = true;
					vm.inputParams = undefined;


				} else {

					//$log.log('SezioneBuoniPossedutiValoreNominaleRimborsoLordoController doCheck ');
					//vm.inputParams = undefined;

				}


			};
			
			
			
            function postLink() {
                UikitProxy.applyPopover();
            }



        }]
}
);