/*
Questa direttiva consente l'inserimento di spazi.
Per evitare questo comportamento utilizzare la direttiva angular: 
        ng-trim="false"
sull'elemento in cui si utilizza only-digits
*/
angular.module('directives.digitsWithPoints').directive('format', ['$filter', function ($filter) {
		'use strict';

        return {
			restrict: 'A',
            require: '?ngModel',
			ngBlur: '&',
            /*scope: {
				formatOnBlur: 'formatOnBlur@'
			},*/
            link: function (scope, elem, attrs, ctrl,ngModel) {
                if (!ctrl) {
                    return;
                }
				/*
                ctrl.$formatters.unshift(function () {
                    return $filter('number')(ctrl.$modelValue);
                });
				*/
				//angular.element('#'+ctrl.formatOnBlur).on('focusOut', function			
				
				scope.formattaValuta = function(data) {
					elem.val($filter('number')(ctrl.$modelValue));
				   //return $filter('number')(ctrl.$modelValue);

				}
				
				scope.$on('formattaValutaEvent', function(event, data){
				   //call directive function here
				   scope.formattaValuta(data);
				   
				})
				
                ctrl.$parsers.unshift(function (viewValue) {
					var plainNumber = viewValue.replace(/[\,\.]/g, ''),
					
                    b = $filter('number')(plainNumber).toString().trim().replace(",",".");
					var ret=(b)?b.toString().trim().replace(",","."):"0";
					elem.val(ret.replace(',', '.'));
                    
					return plainNumber;
                });
            }
        };
    }]);