angular.module('filters.filterDate').filter('filterDate', function () {
    return function (objs, fromDate, toDate, date) {
        var filtered = [];
        //here you will have your desired input
    
     
        angular.forEach(objs, function (obj) {
            if(date > obj[fromDate] && date < obj[toDate])
                filtered.push(obj); 
        });
        return filtered;
    };
});